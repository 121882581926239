/* .product_banner {
  background: url("../../img/product-banner.png");
  background-position: center;
  background-size: cover;
  height: 720px;
}

.product-list-container {
  background: #f4f4f4f5;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 13px;
  height: 100%;
  margin: auto;
  margin-top: 140px;
  margin-left: 80px;
  margin-right: 80px;
  padding-bottom: 50px;
}

.product-list-container-header {
  color: #434db8;
  font-size: 25px;
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.product-list-header {
  color: #000000;
  font-size: 14px;
  font-family: Poppins, SemiBold;
  margin-top: 10px;
  text-align: center;
}

.product-list-border {
  border-top: 4px solid #db7221;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  border-radius: 5px;
  margin-top: 10px;
}

.product-list {
  display: flex;
  gap: 92px;
}
.product-conatiner {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 15px;
  height: 100%;
  color: #000000;
  cursor: pointer;
  margin-left: 55px;
  margin-right: 55px;
  transition: transform 0.3s ease;
 
}
.product-gap-50 {
  margin-top: 50px;
}
.product-gap-20 {
  margin-top: 20px;
}

.product-conatiner > div {
  height: 100%;
}
.product-title {
  font-size: 12px;
  font-family: Poppins, Medium;
  text-align: center;
  line-height: normal;
  padding-left: 30px;
  padding-right: 30px;
  
}
.product-conatiner:hover {
  background: #434db8d6;
  box-shadow: 0px 0px 35px #434db8d6;
  border-radius: 15px;
  color: #ffffff;
    margin-left: 55px;
  margin-right: 55px;
  transform: translateY(-5px); 
 
}

.product_user_avatar {
  margin-left: auto;
}
.product-logo{
  display: flex;
  justify-content: center;
  position: relative;
  height: 73px;
  top: 5px;
}
.betaProducts{
  display: flex;
  justify-content: center;
  height: 73px;
  position: relative;
  top: 5px;   
}
.product-logohover{
  display: flex;
  justify-content: center;
  height: 73px;
  position: relative;
  top: 2px;
  transition: top ease 0.5s;
  
 
} 
.betaProductshover{
  display: flex;
  justify-content: center;
  height: 73px;
  position: relative;
  top: 2px;
  transition: top ease 0.5s;
  
}   */
/* .product-logohover > img{
  height: 84px;
  width:242px
}
.betaProductshover > img{
  height: 84px;
  width:242px
} */
.p15 {
  padding: 15px;
}
.productTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.productTitle h1 {
  color: #434db8;
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  margin: 0px;
}
.productTitle p {
  font-size: 13px;
  font-family: Poppins;
  margin: 0px;
  color: #323232;
}
.product_First_row {
  background-color: #f0f1ff;
}
.product_sec_row {
  background-image: url("../../img/product/productBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.product_sec_row .product_1,
.product_sec_row .product_2 {
  /* width: 451px; */
  height: 191px;
  box-shadow: 0px 0px 66px #6e7af642;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}
.product_sec_row_con {
  display: flex;
}

.product_sec_row .product_1 p,.product_sec_row .product_2 p
{
  margin: 0px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
}

.product_sec_row_con .col_1,.product_sec_row_con .col_2
{
  flex-direction: column;
  gap: 20px;
}
.tick
{
  list-style-image: url("../../img/product/tick.svg");
}
.productList
{
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
  color: #434DB8;
  /* width: 451px; */
}

.product_1_col ,.product_2_col
{
  width: 451px;
}

.col_1 .product_1 ,.col_2 .product_2
{
  margin-top: 30px;
}
.mt_10
{
 margin-top: 10px;
}
.productNavbar
{
  justify-content: end;
}
.product_user_avatar
{
  margin-left: 45px !important;
}
.product_1:hover
{
  background-color: #434DB8;
  color: white;
  box-shadow: 0px 0px 66px #434DB8CF;
  transform: translateY(-5px); 
  cursor: pointer;
}
.product_1
{
  transition: all 0.5s;
}
.product_2:hover
{
  background-color: #434DB8;
  color: white;
  box-shadow: 0px 0px 66px #434DB8CF;
  transform: translateY(-5px); 
  cursor: pointer;
}
.product_2
{
  transition: all 0.5s;
}
.product_sec_row_con
{
  margin-bottom: 30px;
}
.ml30
{
  margin-left: 30px;
}
.res_menu
{
  justify-content: flex-end;
}