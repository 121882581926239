@media (min-width: 2000px) {
}
@media (max-width: 1650px) {
}
@media (max-width: 1550px) {
  /* changed here from auto to 90px*/
  .menu > .nav-item:nth-last-child(-n + 2).submenu .dropdown-menu {
    left: 90px;
    right: 0;
  }

  .menu_one .tracking_btn .er_btn {
    padding: 8px 20px;
  }
}

@media (max-width: 1450px) {
}

@media (max-width: 1350px) {
}

@media (max-width: 1199px) {
  .footer_nine_top .pl_100 {
    padding-left: 50px;
  }
  .footer_nine_top {
    padding-bottom: 100px;
    padding-top: 125px;
  }

  .menu_four .btn_get_radious {
    margin-left: 5px;
  }

  .navbar .pl_100 {
    padding-left: 48px;
  }

  .case_study_item .text {
    padding: 20px 30px;
  }

  .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu {
    max-width: 960px;
  }

  .header_footer p {
    margin-bottom: 0;
  }

  .menu_one .container-fluid {
    padding-left: 35px;
    padding-right: 72px;
  }
}
@media (max-width: 991px) {
  .navbar_fixed .header_area .menu_one {
    padding: 15px 0px;
  }
  .menu_scroll .alter_nav {
    position: relative;
    right: 0;
    top: 0;
  }
  .menu_scroll.menu_poss .w_menu.search_cart a.nav-link {
    color: #051441;
  }
  .menu_scroll .menu > .nav-item:nth-last-child(-n + 2).submenu .dropdown-menu {
    left: 0;
    right: auto;
  }
  .s_service_section {
    margin-top: 280px;
  }
  .s_service_item {
    padding: 50px 40px 40px;
    margin-top: 30px;
  }
  .s_service_info {
    margin-top: 40px;
  }
  .s_features_section {
    margin-top: 100px;
    padding-bottom: 100px;
  }
  .s_features_item .ml_25 {
    margin-left: 0;
  }
  .service_details_area .service_details_img {
    margin-left: 0;
    margin-bottom: 60px;
  }
  .service_promo_area .shape.shape_four,
  .service_promo_area .shape.shape_three {
    bottom: -2%;
  }
  .banner_top br,
  .s_service_section h2 br {
    display: none;
  }
  .footer_top .f_widget {
    padding-left: 0px;
    margin-bottom: 40px;
  }
  .footer_top {
    padding-bottom: 60px;
  }
  .footer_bottom,
  .f_social_icon_two a {
    font-size: 13px;
  }
  .slider_area {
    height: auto;
  }
  .mobile_img .mobile {
    left: 0;
    top: -143px;
  }
  .mobile_img .women_img {
    top: 70px;
    right: 280px;
  }
  .container.custom_container {
    max-width: 100%;
    padding: 0px 15px;
  }
  .price_content .price_item {
    height: 100%;
  }
  .price_content .col-sm-6 {
    margin-bottom: 30px;
  }
  .pricing_area {
    padding-bottom: 80px;
  }
  .feature_info .feature_img {
    margin-left: 0;
    margin-bottom: 30px;
  }
  .feature_info .feature_img.f_img_two {
    max-width: 570px;
    position: relative;
  }
  .feature_info .feature_img.f_img_two .one {
    right: 40%;
  }
  .features_area .mt_130 {
    margin-top: 80px;
  }
  .service_item {
    padding: 47px 25px 0px;
  }
  .service_item .icon {
    right: 20px;
  }
  .feature_info .feature_img.f_img_one {
    margin-left: 0px;
  }
  .service_carousel:before {
    display: none;
  }
  .protype_img {
    margin-top: 50px;
  }
  .partner_logo .p_logo_item {
    width: calc(100% / 4);
    margin-bottom: 20px;
  }

  .footer_area_four .footer_top {
    padding-bottom: 40px;
  }
  .company_widget .f_subscribe {
    max-width: 300px;
  }
  /* .software_banner_area{
        padding-top: 150px;
    } */

  .software_featured_img {
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .footer_top_six .social-widget {
    margin-left: 0;
  }
  .footer_top_six .f_widget {
    margin-bottom: 40px;
  }
  .footer_area_six.sec_pad {
    padding-bottom: 80px;
  }
  .app_banner_area .app_banner_contentmt h2 br {
    display: none;
  }

  .faq_tab {
    margin-bottom: 60px;
  }
  .faq_content h3.mt_100 {
    margin-top: 50px;
  }

  .menu > .nav-item + .nav-item {
    margin-left: 0px;
  }
  .menu > .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .menu {
    padding: 10px 30px;
    background: #fff;
    margin-top: 0px;
  }
  .menu_one .navbar-collapse {
    margin-left: -20px;
    margin-right: -20px;
  }
  .navbar-collapse,
  .menu_four .navbar-collapse {
    /* max-height: 100%; */
    overflow-y: scroll;
    margin-left: 0px;
    margin-right: 0px;
    background: #fff;
  }
  .menu > .nav-item {
    margin-right: 0;
  }
  .header_area .navbar-brand,
  .menu_toggle {
    transition: all 0.2s linear;
  }
  .navbar .pl_100 {
    padding-left: 20px;
    padding-right: 15px;
  }
  .header_area.navbar_fixed .navbar-brand,
  .navbar_fixed .menu_toggle {
    margin-bottom: 0px;
  }
  .menu > .nav-item > .nav-link {
    display: inline-block;
  }
  .menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu {
    box-shadow: none;
  }
  .menu
    > .nav-item.submenu.mega_menu
    .dropdown-menu
    .nav-item
    > .dropdown-menu {
    display: block;
  }
  .menu > .nav-item.submenu .dropdown-menu .nav-item {
    padding: 0px 25px;
  }
  .menu > .nav-item.submenu .dropdown-menu {
    box-shadow: none;
    padding: 0px;
    margin: 0px;
  }
  .burger_menu {
    display: none;
  }
  .menu > .nav-item.submenu.mega_menu .mega_menu_inner {
    box-shadow: none;
    position: static;
  }
  .menu
    > .nav-item.submenu
    .dropdown-menu
    .nav-item.submenu
    .dropdown-menu
    .nav-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu
    > .nav-item.submenu
    .dropdown-menu
    .nav-item.submenu
    .dropdown-menu
    .nav-item:first-child {
    padding-top: 0;
  }
  .menu
    > .nav-item.submenu
    .dropdown-menu
    .nav-item.submenu
    .dropdown-menu
    .nav-item:last-child {
    padding-bottom: 0;
  }
  .menu
    > .nav-item.submenu
    .dropdown-menu
    .nav-item.submenu
    .dropdown-menu
    .nav-item
    .nav-link:before {
    content: "\e649";
    display: inline-block;
    font-family: "themify";
    margin-right: 8px;
    width: auto;
    font-size: 9px;
    float: none;
  }
  .menu > .nav-item.submenu.mega_menu > .dropdown-menu.show {
    margin-top: 10px;
    box-shadow: none;
    min-height: 350px;
  }
  .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item + .nav-item {
    padding-top: 0px;
  }
  .menu
    > .nav-item.submenu.mega_menu
    > .dropdown-menu
    > .nav-item
    .dropdown-menu,
  .menu
    > .nav-item.submenu.mega_menu
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item
    .dropdown-menu {
    padding-left: 15px;
    opacity: 1;
    visibility: visible;
    min-height: max-content;
  }
  .menu
    > .nav-item.submenu.mega_menu
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item {
    padding: 0px 25px;
  }
  .menu > .nav-item.submenu .dropdown-menu .nav-item:first-child,
  .menu
    > .nav-item.submenu.mega_menu
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item:first-child {
    padding-top: 12px;
  }
  .menu > .nav-item.submenu .dropdown-menu .nav-item:last-child,
  .menu
    > .nav-item.submenu.mega_menu
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item:last-child {
    padding-bottom: 0px;
  }
  .menu
    > .nav-item.submenu.mega_menu
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item
    > .dropdown-menu
    > .nav-item:last-child {
    padding-bottom: 20px;
  }
  .menu_one .mobile_btn {
    margin-left: 15px;
    display: none;
  }
  .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu {
    display: flex;
    flex-wrap: wrap;
  }
  .menu > .nav-item.submenu.mega_menu > .dropdown-menu > .nav-item {
    width: 100%;
  }
  .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu .nav-item {
    width: calc(100% / 3);
    padding: 15px 20px;
  }
  .menu_one .btn_get {
    margin-left: 29px;
  }
  .menu_four .btn_get {
    border-color: #ccc5fa;
    box-shadow: none;
    margin-left: 20px;
  }
  .header_area .menu_six .cus_dark {
    margin-left: 30px;
    border-color: #3d64f4;
    color: #3d64f4;
    display: inline-block;
    margin-bottom: 15px;
  }
  .header_area a.menu_cus,
  .header_area .btn_get.ml-0 {
    margin-left: 30px !important;
  }
  .header_area .menu_five .menu_custfive {
    border-color: #3d64f4;
    color: #3d64f4;
    display: inline-block;
  }
  .header_area .menu_four .menu_custfive {
    border-color: #563bd1;
    color: #563bd1;
    display: inline-block;
  }
  .menu_nine .btn_get {
    display: inline-block;
    border-color: #5f51fb;
    color: #5f51fb;
    margin-left: 28px;
    margin-bottom: 10px;
  }
  .header_area_three .menu_four .menu_toggle .hamburger span {
    background: #6754e2;
  }
  .header_area_three {
    padding: 28px 0px;
  }
  .header_area_six .container,
  .header_area_seven .container {
    position: relative;
  }
  .footer_top_two .company_widget {
    padding-right: 0px;
  }
  .footer_top_two .f_widget {
    margin-bottom: 40px;
  }
  .footer_area_two .footer_top_two .row {
    margin-bottom: -40px;
  }
  .header_area .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .menu_one,
  .header_area.navbar_fixed .menu_one,
  .menu_two,
  .menu_six,
  .menu_seven {
    padding: 15px 0px;
  }
  .w_menu .nav-item .nav-link {
    color: #051441;
  }
  .w_menu .nav-item:hover .nav-link,
  .w_menu .nav-item.active .nav-link {
    color: #00aff0;
  }
  .prototype_banner_area {
    padding-top: 140px;
  }
  .menu_four .menu > .nav-item .nav-link,
  .menu_five .menu > .nav-item .nav-link,
  .menu_six .menu > .nav-item .nav-link,
  .menu_seven .menu .nav-item .nav-link {
    color: #051441;
  }
  .menu_five .menu > .nav-item:hover .nav-link,
  .menu_five .menu > .nav-item.active .nav-link {
    color: #3d64f4;
  }
  .menu_five > .menu > .nav-item:hover .nav-link:before,
  .menu_five .menu > .nav-item.active .nav-link:before {
    background: #3d64f4;
  }
  .menu_seven .menu > .nav-item:hover .nav-link,
  .menu_seven .menu > .nav-item.active .nav-link {
    color: #4069eb;
  }
  .menu_seven > .menu > .nav-item:hover .nav-link:before,
  .menu_seven .menu > .nav-item.active .nav-link:before {
    background: #4069eb;
  }
  .menu_four > .menu > .nav-item:hover .nav-link,
  .menu_four .menu > .nav-item.active .nav-link {
    color: #6754e2;
  }
  .menu_four > .menu > .nav-item:hover > .nav-link:before,
  .menu_four .menu > .nav-item.active > .nav-link:before {
    background: #6754e2;
  }
  .security_btn {
    color: #0e79de;
  }

  .footer_nine_top .company_widget.pr_100 {
    padding-right: 0px;
  }
  .footer_nine_top .pl_100 {
    padding-left: 15px;
  }
  .footer_nine_top .f_widget {
    margin-bottom: 40px;
  }
  .footer_nine_top {
    padding-bottom: 80px;
  }

  .header_area_six + section {
    margin-top: 122px;
  }
  .header_contact_info li {
    font-size: 13px;
  }
  .header_contact_info li:before {
    margin: 0px 5px 0px 0px;
  }
  .navbar .search_cart .search a.nav-link:before {
    display: none;
  }
  .software_promo_area .round_shape {
    max-width: 690px;
    min-height: 690px;
  }
  .payment_features_item {
    margin-bottom: 30px;
  }

  .new_footer_top .pl_70 {
    padding-left: 0;
  }
  .new_footer_top .f_widget {
    margin-bottom: 50px;
  }
  .header_area_six .alter_nav {
    top: 0px;
  }
  /* .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner > .dropdown-menu > .nav-item .dropdown-menu{
        display: block !important;
    } */
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    .mega_menu_inner
    > .dropdown-menu
    > .nav-item
    .dropdown-menu
    .nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header_area .menu_seven a.Cus_seven {
    color: #3d64f4;
    margin-left: 30px;
    display: inline-block;
    margin-bottom: 20px;
  }
  .domain_form_inner input {
    width: 50%;
  }
  .domain_form_inner .domain_select {
    width: 23%;
  }
  .domain_form_inner button {
    width: 27%;
  }
  .hosting_menu.menu_four .btn_get {
    color: #333f64;
    line-height: 42px;
    margin-left: 30px;
  }

  .security_list_area .macbook_a {
    display: block;
    position: relative;
    right: -130px;
    bottom: 0;
  }
  .security_list_area .lock {
    right: auto;
    left: 80px;
    top: 380px;
    bottom: auto;
  }
  .security_list_area {
    padding: 20px 0px 100px;
  }
  .security_list_area .analytices_title {
    margin-top: 50px;
  }
  .security_inner .row {
    justify-content: center;
  }
  .security_action_inner,
  .security_action_inner .media {
    display: block;
    text-align: center;
  }
  .security_action_inner .media img {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .security_action_inner .media h3 {
    margin-bottom: 25px;
  }
  .security_action_inner .media h3 {
    line-height: 45px;
  }
  .price_info_two .price_head .p_head.time {
    padding-top: 0;
  }
  .instagram_widget ul {
    max-width: 290px;
  }

  .menu_one .tracking_btn {
    padding-left: 62px;
    padding-right: 62px;
    margin-bottom: 10px;
  }

  .h_footer_track {
    padding-bottom: 80px;
  }
  .menu_one .container-fluid {
    padding-left: 35px;
    padding-right: 35px;
  }
  .navbar_fixed .header_area .menu_eight {
    padding: 15px 0px;
  }
}

@media (min-width: 400px) and (max-width: 768px) {
  .arrowCustomChanges .slick-prev {
    left: 113px;
    top: 97%;
  }
  .arrowCustomChanges .slick-next {
    right: 223px;
    top: 97%;
  }

  .arrowCustomChanges .slick-dots {
    right: 192px;
    bottom: 2px;
    display: inline-flex !important;
  }

  .res_get_demo {
    padding-bottom: 20px;
  }

  .custom-arrow .slick-prev {
    left: 124px;
    top: 298px;
  }
  .custom-arrow .slick-next {
    left: 155px;
    top: 298px;
  }
  .custom-arrow .SliderBanner .slick-dots {
    bottom: 10px;
  }
}

@media (min-width: 360px) and (max-width: 399px) {
  .arrowCustomChanges .slick-prev {
    left: 113px;
    top: 97%;
  }
  .arrowCustomChanges .slick-next {
    right: 170px;
    top: 97%;
  }
  .arrowCustomChanges .slick-dots {
    right: 141px;
    bottom: 3px;
    display: inline-flex !important;
  }

  .res_get_demo {
    padding-bottom: 20px;
  }
  .custom-arrow .slick-prev {
    left: 103px;
    top: 288px;
  }
  .custom-arrow .slick-next {
    left: 135px;
    top: 288px;
  }
  .custom-arrow .SliderBanner .slick-dots {
    bottom: 20px;
  }

  .fireflink_offering_second_title {
    margin: 0px;
  }
  .audit_hover,
  .traditional_hover,
  .train_hover,
  .express_hover,
  .automation_hover {
    background-size: cover;
  }
  .res_menu_btn .get_demo_nav_btn {
    padding: 8px 20px 8px 20px !important;
  }
  .res_menu_btn .sign_in_orange {
    padding: 10px 30px 10px 30px !important;
  }
}
@media (max-width: 768px) {
  .pageNum {
    display: none;
  }
  .countCard {
    margin: 20px 0px;
  }
  .academySkillsImage,
  .acadamySkillPara,
  .partnerContent,
  .partnerBanner {
    width: 100%;
  }
  .academySkillsMobile {
    text-align: center;
    flex-direction: column;
  }
  .training_bg_banner h3 {
    font-size: 18px;
  }
  .documentation,
  .certification,
  .expertTrainer {
    margin: 30px 0px;
  }
  .whitePartition,
  .lineCount {
    display: none;
  }
  .contextbg,
  .acadamyCountDiv {
    flex-direction: column;
    height: 100%;
  }
  .whyAcadamyImg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .whyAcadamyDiv1 p,
  .whyAcadamyDiv2 p,
  .whyAcadamyDiv3 p,
  .whyAcadamyDiv4 p,
  .whyAcadamyDiv5 p,
  .whyAcadamyDiv6 p {
    width: 95%;
  }
  .whyAcadamyDiv1,
  .whyAcadamyDiv2,
  .whyAcadamyDiv3,
  .whyAcadamyDiv4,
  .whyAcadamyDiv5,
  .whyAcadamyDiv6 {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .whyAcademyAnimation {
    display: none;
  }
  .accelaratingMobile,
  .acadamyCountDiv {
    text-align: center;
  }
  .accelaratingImgDiv img {
    width: 100%;
  }
  .accelaratingMobile {
    flex-direction: column-reverse;
  }
  .mainBannerLottie {
    margin-top: 20px;
    width: 100%;
    height: 100%;
  }
  .enhance_para {
    width: 100%;
  }
  .bg_Acadamy {
    height: 100%;
  }
  .bg_Acadamy {
    flex-direction: column;
    text-align: center;
  }
  .academyMobileResponsive {
    flex-direction: column-reverse;
    text-align: center;
  }
  .academyMainBannerLottie + div {
    width: 350px !important;
    height: 300px !important;
  }
  .orangeHeadLine {
    border: 2px solid #db7221;
  }
  .custom-arrow {
    margin-top: 15px;
  }
  .carousel_enhanced_unified_test_management_banner,
  .carousel_enhanced_test_automation_banner,
  .carousel_dedicated_section_banner,
  .carousel_plug_and_play_banner,
  .carousel_use_nlp_banner {
    width: 98% !important;
  }

  .mobileViewFloating h5 {
    font-size: 15px;
    padding: 0px 22px 0px 20px;
  }
  .line-with-circle,
  .line-with-circle-start {
    width: 40px;
  }
  .demo_row_height {
    flex-direction: column;
    height: 100%;
    margin-top: 20px;
    gap: 21px;
  }
  .mobileViewFloating {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .accMobileView {
    display: none !important;
  }
  .f-logo,
  .about-widget {
    display: none;
  }
  .company_widget {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .company_widget p {
    text-align: center;
  }
  .sec_title br,
  .prototype_service_info h2 br,
  .hosting_title h2 br {
    display: none;
  }
  .footer_bottom p {
    text-align: center;
    margin-bottom: 12px !important;
  }
  .footer_bottom .f_social_icon_two {
    text-align: right !important;
    margin-bottom: 12px;
  }
  .footer_bottom .f_menu {
    text-align: center !important;
  }
  .banner_top h2 {
    font-size: 32px;
  }
  .seo_banner_content h2 {
    line-height: 48px;
  }
  .l_height60 {
    line-height: 45px;
  }
  .footer_top_two .f_width_24,
  .f_width_12,
  .f_width_20 {
    width: 100%;
  }
  .footer_area_two .footer_bottom p {
    text-align: left;
    margin-bottom: 0 !important;
  }
  .partner_logo .p_logo_item {
    width: calc(100% / 3);
  }
  .prototype_featured_area h2 br {
    display: none;
  }
  .service_carousel {
    padding-left: 0;
  }
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-nav .owl-prev {
    opacity: 0;
  }
  .service_carousel:hover .owl-nav .owl-next,
  .service_carousel:hover .owl-nav .owl-prev {
    opacity: 1;
  }
  .feedback_slider_two .owl-nav .owl-next,
  .feedback_slider_two .owl-nav .owl-prev {
    opacity: 1;
  }
  .prototype_service_area {
    padding-bottom: 70px;
  }
  .prototype_service_info .mb_90 {
    margin-bottom: 70px;
  }

  .blog_area .shop_page_number {
    text-align: center !important;
  }
  .blog_single .blockquote {
    padding-left: 15px;
  }
  .post_share a i {
    padding-right: 4px;
  }
  .post_share a + a {
    margin-left: 5px;
  }
  .post_tag .post-nam {
    padding-right: 0;
  }
  .post_tag a {
    margin-left: 3px;
  }
  .portfolio_filter .work_portfolio_item + .work_portfolio_item {
    margin-left: 35px;
  }
  .price_content .price_item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .price_info_two .price_head .p_head {
    display: none;
  }
  .price_info_two .price_head .p_head.time {
    display: block;
    width: 100%;
  }
  .price_info_two .pr_list {
    display: block;
    width: 100%;
  }
  .price_info_two .price_item {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0px;
  }
  .price_info_two .price_item:first-child {
    text-align: center;
    padding: 5px 0px;
  }
  .price_info_two .price_item:first-child h5 {
    width: initial;
    border: 0px;
    float: none;
  }
  .price_info_two .price_item:before {
    content: attr(data-title);
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
  }
  .price_info_two .price_item h5 {
    border-left: 1px dashed #ededed;
    display: block;
    float: right;
    font-size: 1.1rem;
    height: 100%;
    line-height: 3.1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
  }
  .price_info_two .pr_list:last-child {
    display: none;
  }
  .blog_breadcrumb_area .breadcrumb_content_two h1 br {
    display: none;
  }
  .blog_breadcrumb_area .breadcrumb_content_two h1 {
    font-size: 7vw;
    font-weight: 700;
    line-height: 1.5em;
  }
  .header_social_icon li {
    margin: 0px 0px 0px 13px;
  }
  .software_promo_area .round_shape {
    width: 600px;
    height: 600px;
    max-width: 100%;
    min-height: 100%;
    margin-top: 100px;
  }
  .software_promo_area .round_shape .r_shape.r_shape_two {
    width: 400px;
    height: 400px;
  }
  .software_promo_area .round_shape .r_shape.r_shape_three {
    width: 230px;
    height: 230px;
  }

  .new_footer_area .footer_bottom p {
    text-align: left;
    font-size: 14px;
  }
  /*    new css*/

  .navbar .offcanfas_menu > .nav-item:not(:last-child) {
    margin-bottom: 5px;
  }
  .header_footer {
    padding-bottom: 0;
    padding-top: 20px;
    display: block;
  }
  .header_footer ul {
    margin-bottom: 0;
  }
  .navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu {
    padding-top: 0;
  }
  .security_features_inner {
    display: block;
    margin-left: -10px;
    margin-right: -10px;
  }
  .security_features_inner .s_features_item:nth-child(3n + 1),
  .security_features_inner .s_features_item:nth-child(3n-1) {
    margin-top: 0;
  }
  .security_features_inner .s_features_item {
    width: calc(50% - 22px);
    margin: 0px 10px 30px;
    display: inline-block;
  }
  .security_features_inner .s_features_item .item {
    width: 100%;
  }
  .event_banner_content h2 {
    font-size: 58px;
    line-height: 65px;
  }
}

@media (max-width: 650px) {
  .menu > .nav-item.submenu.mega_menu > .dropdown-menu.show {
    padding: 0px 10px;
  }
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    > .dropdown-menu
    > .nav-item {
    padding: 15px 10px;
  }
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    > .dropdown-menu
    > .nav-item
    .item
    .text {
    font-size: 12px;
  }
  .feedback_area_two {
    height: auto;
    padding-bottom: 50px;
  }

  /*    new css*/
  .domain_form_inner .domain_select {
    width: 17%;
  }
  .domain_form_inner .domain_select .selectpickers {
    text-align: left !important;
    padding-left: 12px;
  }
  .domain_form_inner .domain_select .selectpickers:after {
    right: 15px;
  }
  .domain_form_inner button {
    width: 33%;
  }
  .domain_price li + li {
    margin-left: 20px;
  }
  .hosting_title p br {
    display: none;
  }
  .h_features_item {
    padding-right: 0;
  }
  .h_price_body .h_p_list .h_price_item {
    position: relative;
  }
  .h_price_body .h_p_list .h_price_item:before {
    content: attr(data-title);
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    text-transform: uppercase;
  }
  .h_price_body .h_p_list {
    display: block;
    padding: 0px;
    margin-bottom: 10px;
  }
  .h_price_body .h_p_list:last-child {
    margin-bottom: 0;
  }
  .h_price_body .h_p_list:last-child .h_price_item.c_width {
    border-bottom: 0px;
  }
  .h_price_body .h_p_list .h_price_item {
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 10px 0px;
    border-bottom: 1px solid #f7f0f0;
  }
  .h_price_body .h_p_list .h_price_item.c_width,
  .h_price_body .price_head .p_head.c_width {
    width: 100%;
  }
  .h_price_body .h_p_list .h_price_item h5 {
    width: 50%;
    display: block;
    float: right;
  }
  .h_price_body .price_head {
    display: none;
  }
  .h_map ul li:nth-child(2) {
    left: 60px;
  }
  .pos_footer_area .leaf_left,
  .pos_footer_area .leaf_right {
    max-width: 20%;
  }
  .pos_action_area .pos_action_content h2 {
    font-size: 35px;
  }
  .pos_footer_bottom img {
    max-width: 40%;
  }
  .pos_action_area .pos_action_content p {
    font-size: 18px;
    line-height: 30px;
  }
  .animation_inner > div:nth-child(20),
  .animation_inner > div:nth-child(12) {
    display: none;
  }
  .erp_banner_area_two .section_container .intro_content h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .erp_banner_area_two .section_container .intro_content {
    padding-top: 100px;
  }
  .erp_banner_area_two .animation_img {
    margin-top: -388px;
  }
  .erp_banner_area_two .erp_shap {
    top: calc(100% - 86px);
  }
  .erp_service_item {
    margin-bottom: 50px;
  }
  .erp_service_area .row {
    margin-bottom: -50px;
  }
  .intro {
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 576px) {
  .footer_bottom .f_social_icon_two,
  .footer_bottom .f_menu,
  .footer_area_two .footer_bottom p {
    text-align: center !important;
  }
  .footer_bottom .f_social_icon_two {
    margin-bottom: 6px;
  }
}

@media (max-height: 420px) {
  .navbar-collapse {
    max-height: 310px;
  }
}

@media (max-width: 480px) {
  .tqCalendarBtn + .dropdown-menu {
    top: 96%;
    left: 71px !important;
  }
  .tqCalendarBtn {
    padding: 10px 10px;
    font-size: 12px;
  }
  .header_area .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 450px) {
  .price_tab {
    max-width: 100%;
    border-radius: 3px;
  }
  .price_tab .nav-item .nav-link {
    display: block;
    position: relative;
  }
  .price_tab,
  .price_tab .nav-item {
    display: block;
  }
  .price_tab .nav-item .nav-link.active {
    background: #00aff0;
    border-radius: 3px;
  }

  .menu > .nav-item.submenu.mega_menu.mega_menu_two > .dropdown-menu .nav-item {
    width: 250px;
  }
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    > .dropdown-menu
    > .nav-item
    .item
    .text {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .header_area_six .header_top {
    display: none;
  }
  .header_area_six + section {
    margin-top: 68px;
  }
  .prototype_service_info h2 {
    font-size: 20px !important;
  }
  .exploretext p {
    margin-left: 0;
    font-size: 11px;
  }
}

/*======================Yogi Responsive================*/

@media (max-width: 425px) {
  .outer_d_flex {
    display: block;
  }
  .home_content_res {
    margin-left: 0;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .activeusers h2 {
    font-size: 25px;
  }
  .activeusers h3 {
    font-size: 50px;
  }
  .exploretext p {
    margin-left: 0;
    font-size: 11px;
  }
  .formbox .col {
    flex-basis: auto;
  }
  .video_layout_back {
    width: -webkit-fill-available;
  }
  .formbox .react-tel-input .country-list {
    width: 290px !important;
  }
  .getstarted {
    padding-top: 200px;
    padding-bottom: 40px;
  }
  .home_video_icon {
    margin-top: -100px;
  }
  .homebanner {
    height: 930px !important;
  }
}

@media (max-width: 450px) {
  .outer_d_flex {
    display: block;
  }
  .home_content_res {
    margin-left: 0;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .outer_d_flex {
    display: block;
  }
  .home_content_res {
    margin-left: 0;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .outer_d_flex {
    display: block;
  }
  .home_content_res {
    margin-left: 0;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}
@media (max-width: 650px) {
  .outer_d_flex {
    display: block !important;
  }
  .home_content_res {
    margin-left: 0;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .footeBottomeContent {
    display: none;
  }
  .calendarBtn,
  .regisForm {
    margin-top: 10px;
  }
  .displyMobile {
    display: block !important;
  }
  .carousel_content ul.tick_icon {
    margin-top: 5px;
  }
  .productTitle p {
    text-align: center;
  }
  .product_sec_row_con {
    flex-direction: column;
  }
  .product_1_col,
  .product_2_col {
    width: 380px;
  }
  .homebanner {
    height: 900px;
  }
  .outer_d_flex {
    display: block !important;
  }
  .home_content_res {
    margin-left: 0;
  }
  .productoffer {
    margin-top: 0px;
  }
  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .fast {
    margin-right: -17px;
    padding-left: 80px;
  }
  .save {
    padding-left: 80px;
  }
  .roi {
    margin-left: -17px;
  }
  .latency {
    margin-right: -17px;
    padding-left: 80px;
  }
  .response {
    padding-left: 80px;
  }
  .coverage {
    margin-left: -60px;
    margin-right: -60px;
    padding-left: 80px;
  }
  .whychoose h2 {
    font-size: 24px;
  }
  .prototype_service_info h2 {
    font-size: 25px;
  }
  .activeusers h2 {
    font-size: 25px;
  }
  .getstarted h2 {
    font-size: 30px;
  }
  .getstartedform .subcribes .btn_submit {
    right: 95px;
  }
  .exploretext h2 {
    font-size: 27px;
  }
  .exploretext p {
    margin-left: 0;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    font-weight: 500;
  }
  .home_banner_btn_hover {
    font-size: 17px;
    width: auto;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .about-widget {
    margin-left: 0px;
  }
  .platformbanner {
    height: 900px;
  }
  .software_featured_content {
    margin-left: 20px;
  }
  .headtwo {
    font-size: 27px;
  }
  .comprehendive_border {
    display: none;
  }
  .res_exe {
    margin-top: 30px;
  }
  .img_none {
    display: none;
  }
  .single_thread {
    background: none;
    padding-left: 0px;
    padding-top: 0px;
    margin-top: -20px;
  }
  .single_thread_left_res {
    background-color: #f0f2ff;
    margin-top: -100px;
  }
  .single_thread_left {
    background: none;
    padding-top: 0;
    padding-left: 0;
    margin-left: 0;
    height: 100%;
  }
  .nlpux {
    margin-left: 0;
  }
  .automation_list {
    margin-left: -40px;
  }
  .ap_res {
    margin-top: -50px;
    margin-left: -30px;
  }
  .client_ux {
    margin-top: 50px;
    margin-left: 0px;
  }
  .how_ux_works h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .how_ux_works h3 {
    font-size: 23px;
    font-weight: 600;
  }
  .ux_single_thread_left {
    background-color: #f0f2ff;
    height: 100%;
    padding-top: 1px;
    padding-left: 50px;
    margin-left: -47px;
  }
  .ux_single_thread {
    background: none;
    padding-top: 0;
    padding-left: 0;
    margin-left: 0;
    height: 100%;
  }
  .ux_single_thread br {
    display: none;
  }
  .ux_single_thread_left br {
    display: none;
  }
  .manual_test-case_exe {
    height: 100%;
  }
  .fireflink_finder_background_banner {
    height: 100%;
  }
  .image_center_position {
    width: 100%;
  }
  .downloadfinder h2 {
    margin-top: 100px;
  }
  .dasheddborder {
    display: none;
  }
  .finder_res_img {
    margin-top: 60px;
  }
  .download_locator {
    margin-top: 0px;
  }
  .comprehensive h3 {
    margin-top: 40px;
  }
  .emulator img {
    margin-top: 20px;
  }
  .firecrowd_banner {
    height: 900px;
  }
  .test_service_right_box {
    padding: 0px;
    width: 100%;
    margin-left: 0px;
    position: static;
    margin-top: -270px;
  }
  .test_service_left_box {
    padding: 0px;
    width: 100%;
    margin-left: 0px;
    position: static;
    margin-top: -50px;
  }
  .res_mt_140 {
    margin-top: -140px;
  }
  .whychoose_finder {
    margin-top: 0px;
  }
  .live_report_crowd h2 {
    margin-top: 30px;
    margin-left: 10px;
  }
  .live_report_crowd h5 {
    margin-left: 0;
  }
  .live_report_crowd p {
    margin-top: 50px;
  }
  .firecloud_banner {
    height: 900px;
  }
  .cloud_res_img {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
  .assetsbanner {
    height: 840px;
  }
  .need_help {
    padding-top: 1000px;
  }
  .contact_form {
    padding-left: 15px;
    padding-right: 15px;
  }
  .formbox {
    border-radius: 16px 16px 16px 16px;
    margin-top: -60px;
    margin-bottom: 30px;
  }
  .contactinfo {
    border-radius: 16px 16px 16px 16px;
  }
  .service_desk {
    margin-right: -50px;
  }
  .phone_support {
    margin-left: -50px;
  }
  .demo_include_box {
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 16px 16px 16px 16px;
    margin-bottom: 30px;
    height: 100%;
  }
  .schedule_demo_banner {
    margin-bottom: 1250px;
  }
  .schedule_demo_box {
    border-radius: 16px 16px 16px 16px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .platformfaq {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .sol_res {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start;
    overflow: auto;
  }
  .mar_top {
    margin-top: 0 !important;
  }
  .sol_res_content {
    margin-left: 20px;
    margin-top: -30px;
  }
  .grey_line img {
    display: none;
  }
  .send_btn {
    float: none;
    margin: auto;
    margin-top: 50px;
  }
  .docs_home_txt {
    margin-top: -25px;
    margin-left: 50px;
    margin-right: 37px;
  }
  .docs_home_txt h3 {
    font-size: 10px;
  }
  .docs_home_img {
    position: relative;
    padding: 10px;
    width: 100%;
    padding-bottom: 50px;
  }
  .doc_home .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .img_res_w {
    width: 100%;
  }
  .video_left_layout {
    margin-left: 0;
    margin-right: 0;
  }
  .video_layout h3 {
    margin-top: 80px;
    margin-left: 0px;
  }
  .video_line {
    margin-left: 0px;
  }
  .video_layout p {
    margin-left: 0px;
  }
  .video_layout {
    margin-left: 1px;
  }
  .video_breadcrumb {
    margin-left: 0px;
  }
  .release_res {
    margin-left: 0px;
  }
  .blog_list_item {
    margin-left: 20px;
    margin-right: 20px;
  }
  .blog_content h2 {
    margin-left: 20px;
  }
  .blog_content p {
    margin-left: 20px;
  }
  .blog_readmore_res {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .pricingbanner {
    background-repeat: unset;
  }
  .integration_testing_bg {
    height: 100%;
    overflow: auto;
  }
  .bg_none {
    margin-top: 50px;
  }
  .integration_testing_bg h3 br {
    display: none;
  }
  .solution_box h3 {
    padding: 0px;
  }
  .integration_testing {
    margin-top: 50px;
    padding: 20px;
  }
  .backbg_css .related_blog_border_line {
    margin-left: 180px;
  }
  .relatedBlogMob {
    margin-bottom: 15px;
  }
  .inner-sidebar {
    padding: 20px;
  }
  .navbar-expand-lg > .container {
    padding: 10px 15px;
  }
  .accordianNavBtn {
    margin: 20px 0px;
  }
  .accordianNavBtn button {
    border: none;
    background-color: transparent;
    color: #db7221;
    font-family: Poppins, SemiBold;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .prodOne .prodContent .prodOneContent,
  .prodTwo .prodContent .prodTwoContent,
  .prodThree .prodContent .prodThreeContent,
  .prodFour .prodContent .prodFourContent,
  .solutionContent .solOneContent,
  .resourcesContent .resOneContent {
    width: 100%;
    border-bottom: 2px solid #f1f1fc;
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  .b_none {
    border-bottom: none !important;
  }

  .prodOne .prodContent,
  .prodTwo .prodContent,
  .prodThree .prodContent,
  .prodFour .prodContent,
  .solutionContent,
  .resourcesContent {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin: 20px;
  }

  .prodOne h3,
  .prodTwo h3,
  .prodThree h3,
  .prodFour h3,
  .solOneContent h3,
  .resOneContent h3 {
    color: #434db8;
    font-size: 15px;
    margin: 0px;
    font-weight: 600;
  }
  .prodOne p,
  .prodTwo p,
  .prodThree p,
  .prodFour p,
  .solOneContent p,
  .resOneContent p {
    font-size: 11px;
    color: #000966;
    margin: 0px;
    font-family: Poppins, Regular;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
  }
  /* .prodOne,
  .prodTwo,
  .prodThree,
  .prodFour {
    margin-left: 20px;
  } */
  .prodOne .prodOneHeading button a,
  .prodTwo .prodTwoHeading button a,
  .prodThree .prodThreeHeading button a,
  .prodFour .prodFourHeading button a {
    color: #434db8 !important;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 600;
  }
  .prodOneHeading,
  .prodTwoHeading,
  .prodThreeHeading,
  .prodFourHeading {
    margin: 15px;
  }
  .whiteNav span {
    background-color: #db7221 !important;
  }
  .accPricing a,
  .contactHead a {
    color: #434db8;
  }
  .res_menu_body {
    background: #d9dcff33;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .security_btn {
    margin: 0px 0px 10px 15px;
    border-radius: 5px;
    color: white;
    padding: 0px 15px;
  }
  .Mview {
    display: block;
    padding: 15px;
  }
  .display_none,
  .blogs_breadcrumb,
  .w_menu {
    display: none !important;
  }
  .backbg_css {
    padding: 130px 0px 125px;
  }
  .concrnt_head_txt h1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sec_pad_80 {
    padding: 60px 0px;
  }
  .res_mr_0 {
    margin-right: 0px;
  }
  .concrcnt_head_two {
    font-size: 15px;
  }
  .concurrent_final_value_width {
    margin-left: 130px;
    margin-top: 15px;
  }
  .fireflink_con {
    width: 130px;
  }
  .con_crnt_head {
    font-size: 14px;
  }
  .concrnt_head_bar {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
  }
  .res_mt_20 {
    margin-top: 20px;
  }
  .concrnt_form_label br {
    display: none;
  }
  .concrnt_text_field {
    width: 40%;
  }
  .grey_line {
    display: none;
  }
  .solution_box p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    line-height: normal;
    margin-bottom: 50px;
  }
  .testimonials .slick-prev {
    display: none !important;
  }
  .testimonials .slick-next {
    display: none !important;
  }
  .home_video_icon {
    margin-top: -120px;
  }
  .our_valued_customer_res {
    display: block;
    text-align: center;
  }
  .free_trial_btn_res {
    background: #db7221;
    border-radius: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: #ffffff;
  }
  .motilal {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
  }
  .ims {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .sticky_logo img {
    width: 130px;
  }
  .my-prod img {
    padding: 6px;
    width: 27px !important;
    height: 27px !important;
  }
  .my_prod_res {
    display: block !important;
  }
  .my_prod_res .dropdown-menu {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    top: 85%;
  }
  .my_prod_res .submenuhead {
    padding-left: 20px;
  }
  .my_prod_res .dashed_border {
    width: 95%;
  }
  .profile_icon_sign {
    font-size: 16px !important;
    width: 27px !important;
    height: 27px !important;
  }
  .res_menu {
    height: 30px;
    width: 144px;
  }
  .profile_popup_res {
    position: absolute;
    top: 60px;
    right: 40px;
    background: #ffffff;
    border-radius: 5px;
    padding-right: 30px;
  }
  .product-conatiner {
    margin-left: 20px;
    margin-right: 20px;
  }
  .product-list-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 80px;
  }
  .product-list-container-header {
    padding: 5px;
  }
  .menu_toggle_res {
    top: 0 !important;
  }
  .uptri5::before {
    bottom: 100%;
    content: "";
    border: 7px solid transparent;
    border-bottom-color: #fff;
    font-size: 0;
    /* line-height: 0; */
    display: inline-block;
    position: absolute;
    background-color: transparent;
    z-index: 9999;
    right: 28%;
    width: 0;
    height: 0;
  }
  .uptri6::before {
    bottom: 100%;
    content: "";
    border: 7px solid transparent;
    border-bottom-color: #fff;
    font-size: 0;
    /* line-height: 0; */
    display: inline-block;
    position: absolute;
    background-color: transparent;
    z-index: 9999;
    right: 19%;
    width: 0;
    height: 0;
  }
  .sign_up_content {
    margin-top: 30px !important;
    margin-right: 0 !important;
  }
  .sign_up h1 {
    font-size: 20px;
  }
  .sign_up p {
    font-size: 14px;
    line-height: normal;
    margin-top: 20px;
  }
  .sign_up h2 {
    font-size: 16px;
    margin-top: 20px;
  }
  .signing_in {
    width: 170px;
  }
  .already_acc p {
    font-size: 10px;
    margin-top: 0;
  }
  .already_acc a {
    font-size: 12px;
    margin-top: -6px;
  }
  .logo_beta {
    font-size: 9px;
    margin-left: 70px;
  }
  .navbar_fixed .header_area .sticky_logo .logo_beta {
    font-size: 9px;
    margin-left: 70px;
  }

  .copy_right_txt {
    padding-bottom: 20px;
  }
  .sign_up_content {
    margin-bottom: -50px;
  }
  /*===New home page */
  .home h1 {
    font-size: 18px;
  }
  .home p {
    font-size: 14px;
  }
  .get_demo {
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
  }
  .custom-arrow .slick-slider {
    left: 3px;
    top: -50px;
  }
  .carousel_bg h2 {
    font-size: 14px;
    padding-top: 90px;
  }
  .carousel_content .carouselTitle {
    height: auto;
  }
  .carousel_bg h2 br {
    display: none;
  }
  .carousel_bg li {
    font-size: 11px;
    line-height: normal;
  }
  .carousel_bg {
    padding: 0;
  }
  .res_get_demo {
    text-align: center;
    margin-top: 20px;
  }
  /* .custom-arrow .slick-prev {
    left: 80px;
    top: 270px;
  }
  .custom-arrow .slick-next {
    left: 110px;
    top: 270px;
  }
  .SliderBanner .slick-dots {
    bottom: 37px;
  } */
  .empower_your_testing_title {
    font-size: 18px;
  }
  .orange_dots {
    display: none;
  }
  .clients_title {
    font-size: 16px;
  }
  .happy-clients {
    margin-top: -50px;
  }
  .clients_second_title {
    font-size: 12px;
  }
  .video_width {
    width: 100%;
  }
  .begin_your_journey_bg_banner h3 {
    font-size: 18px;
  }
  .begin_your_journey_bg_banner {
    width: 90%;
  }
  .content_acc h2 {
    font-size: 18px;
  }
  .mainFireFlinkAcc_bg {
    width: 90%;
  }
  .arrow_design_box_shadow .slider-container {
    margin-left: -10px;
    margin-right: -10px;
  }
  .testimonial_title {
    font-size: 18px;
  }
  .testimonial_second_title {
    font-size: 15px;
  }
  .home_acc_res {
    display: block;
    padding: 15px;
  }
  .displayProps {
    display: none;
  }
  .content_acc {
    gap: 30px;
    padding: 30px 15px 0px 0px;
    margin-bottom: 30px;
    margin-left: -15px;
  }
  .home_acc_res h3 {
    font-size: 14px;
    font-family: Poppins, Regular;
    font-weight: normal;
    line-height: normal;
    margin: 5px 0px 15px 0px;
  }
  .accordinDiv .accordion .accordion-item .accordianNavBtn button span {
    font-size: 17px;
    margin-left: -5px;
  }
  .accordinDiv .accordion .accordion-item .accordianNavBtn button {
    padding: 5px 15px;
  }
  .textimonial_box {
    height: 490px;
  }
  .textimonial_box p {
    height: 250px;
  }
  .begin_your_journey {
    margin-top: 40px;
  }

  /* Offering responsive */
  .offeringMainDiv {
    width: 200px;
    height: 200px;
  }
  .training {
    top: -82px;
    left: 70px;
  }

  .ff_offering {
    flex-direction: column;
  }
  .offeringRowMobileView1 {
    margin-top: 60px;
  }
  .offeringMobileView {
    margin-top: 100px;
  }
  .audit h3,
  .traditional h3 {
    width: 60px;
  }
  .training h3,
  .audit h3,
  .traditional h3,
  .e2e h3,
  .express h3 {
    font-size: 8px;
  }
  .traditional {
    margin-right: -20px;
  }
  .audit {
    margin-left: -16px;
    left: 215px;
    top: 3px;
  }
  .express {
    top: 175px;
    left: 103px;
  }
  .e2e {
    top: 175px;
    right: 115px;
  }
  .traditional {
    top: 0px;
    right: 215px;
  }

  /* Menu */
  .res_menu_btn {
    margin-top: 10px;
    display: flex;
    gap: 25px;
    justify-content: center;
    margin-bottom: 40px;
  }
  .res_menu_btn .get_demo_nav_btn {
    padding: 8px 40px 8px 40px;
  }
  .res_menu_btn .sign_in_orange {
    padding: 10px 60px 10px 60px;
  }
  .d-flex-space-center {
    gap: 20px;
  }
  .res_ai {
    margin-top: 450px;
  }
}

@media (max-width: 1024px) {
  .outer_d_flex {
    display: contents;
  }
  .home_content_res {
    margin-left: 0 !important;
  }

  .prodimg {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1199px) {
}
@media (max-width: 1279px) {
  .uptri3::before {
    left: 80%;
  }
  .uptri::before {
    left: 37%;
  }
  .uptri1::before {
    left: 45%;
  }
  .uptri4::before {
    left: 80%;
  }
  .uptri_signin::before {
    left: 31%;
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1450px) {
}
@media (max-width: 1550px) {
}
@media (max-width: 1650px) {
}
@media (min-width: 2000px) {
  .uptri::before {
    left: 48%;
  }
  .uptri1::before {
    left: 54%;
  }
  #my-prod-menu > .nav-item.submenu .dropdown-menu {
    width: auto;
    right: auto;
  }
  .uptri3::before {
    left: 4%;
  }
}
@media (min-width: 3000px) {
  .uptri::before {
    left: 52%;
  }
  .uptri1::before {
    left: 55%;
  }
}
/*===================Nalina Resonsive================*/
@media (max-width: 320px) {
  .schedule_demo_banner {
    margin-bottom: 1407px;
  }
  .getstarted h2 {
    font-size: 25px;
  }
  .schedule_demo_box {
    border-radius: 16px 16px 16px 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .schedule_demo_box .btn_none {
    font-size: 17px;
  }
}
/* @media (min-width: 320px )and (max-width: 920px) {
  .home_banner_res{
    margin-top: 45px !important;
  }
} */
