#recaptcha-container{
    visibility: hidden;
}

body {
    counter-reset: section;
  }
  
  .counter::before {
    counter-increment: section;
    content: " " counter(section) ": ";
  }