/*=== fonts ====*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
/*===== color =====*/

/*====================================================*/
.h2 {
  font: normal normal 600 32px/48px Poppins;
}

a {
  text-decoration: none !important;
}

/* a:hover, a:focus {
  text-decoration: none;
  color: #FFFFFF;
  
 
} */

.row.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

i:before {
  margin-left: 0px !important;
}

body {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  font-family: "Poppins", medium;
  letter-spacing: 0px;
  color: #677294;
  padding: 0px;
  overflow-x: hidden;
  z-index: 0;
}

.body_wrapper {
  z-index: 20;
  overflow: hidden;
}

a,
.btn,
button {
  text-decoration: none;
  outline: none;
}

.b_none {
  border: none;
  background: none;
  box-shadow: none;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus,
.get_demo:focus {
  text-decoration: none;
  outline: none;
}

.a:hover {
  color: #ffffff !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.seo_sec_title h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: #263b5e;
  margin-bottom: 15px;
}

.seo_sec_title p {
  font-size: 16px;
  font-weight: 300;
  color: #6a7695;
  margin-bottom: 0;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.seo_sec_title p {
  white-space: pre-line;
}

@media (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
.listStyleDisc {
  list-style-type: disc !important;
}
.lineHeight_15 {
  line-height: 1.5;
}

.container.custom_container {
  max-width: 1520px;
}

.textCenter {
  text-align: center !important;
}

.f_size_25 {
  font-size: 25px !important;
}

.f_size_50 {
  font-size: 50px;
}

.f_size_40 {
  font-size: 40px;
}
.f_size_42 {
  font-size: 42px;
}
.f_size_36 {
  font-size: 36px;
}

.f_size_30 {
  font-size: 30px;
}

.f_size_28 {
  font-size: 28px;
}

.f_size_22 {
  font-size: 22px;
}

.f_size_24 {
  font-size: 24px;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_23 {
  font-size: 23px;
}

.f_size_18 {
  font-size: 18px !important;
}

.f_size_16 {
  font-size: 16px;
}

.f_size_17 {
  font-size: 17px;
}

.f_size_15 {
  font-size: 15px;
}

.f_size_14 {
  font-size: 14px;
}

.f_size_12 {
  font-size: 12px !important;
}

.f_size_10 {
  font-size: 10px;
}
.l_height_normal {
  line-height: normal;
}
.l_height60 {
  line-height: 60px;
}

.l_height50 {
  line-height: 50px;
}

.l_height45 {
  line-height: 45px;
}

.l_height40 {
  line-height: 40px;
}

.l_height30 {
  line-height: 30px;
}

.l_height34 {
  line-height: 34px;
}

.l_height28 {
  line-height: 28px;
}

.f_p {
  font-family: "Poppins", SemiBold;
}
.f_b {
  font-family: "Poppins", Bold;
}
.f_r {
  font-family: "Poppins", Regular;
}

.f_m {
  font-family: "Poppins", Medium;
}

.upper_case {
  text-transform: uppercase;
}
.gap10 {
  gap: 10px !important;
}
/*=========== font-weight ==============*/
.f_normal {
  font-weight: normal;
}
.f_w_b {
  font-weight: bold;
}
.f_300 {
  font-weight: 300;
}

.f_400 {
  font-weight: 400 !important;
}

.f_500 {
  font-weight: 500 !important;
}

.f_600 {
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}

.f_900 {
  font-weight: 900;
}

.t_color {
  color: #000000;
}
.t_grey {
  color: #454545;
}
.o_color {
  color: #db7221;
}

.w_color {
  color: #434db8;
}

.t_color2 {
  color: #3f4451;
}

.t_color3 {
  color: #222d39;
}

.d_p_color {
  color: #bdbed6;
}

.bg_color {
  background: #fbfbfd;
}

.dk_bg_one {
  background: #090a21;
}

.dk_bg_two {
  background: #0f1029;
}

.white_color {
  color: #ffffff;
}
.seo_title_color {
  color: #263b5e;
}

.m_auto {
  margin: auto;
}

.mt_60 {
  margin-top: 60px;
}

.mt-35 {
  margin-top: -35px;
}
.mt-60 {
  margin-top: -60px;
}

.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-160 {
  margin-top: -160px;
}

.mt_160 {
  margin-top: 160px;
}

.mt_180 {
  margin-top: 180px;
}

.mt_220 {
  margin-top: 220px;
}

.mt_240 {
  margin-top: 240px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_6 {
  margin-top: 6px;
}

.mt_7 {
  margin-top: -7px;
}

.mt_8 {
  margin-top: 8px;
}

.mt-8 {
  margin-top: -8px;
}

.mt-5 {
  margin-top: -5px !important;
}

.mt-4 {
  margin-top: -4px !important;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 20px;
}

.mr-20 {
  margin-right: -20px;
}

.mr_30 {
  margin-right: 30px;
}
.mr-30 {
  margin-right: -30px;
}
.mt-18 {
  margin-top: -18px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_50 {
  margin-right: 50px;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr_5 {
  margin-right: -5px;
}

.mt_20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: -50px;
}

.mt0 {
  margin-top: 0px !important;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_45 {
  margin-top: 45px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_80 {
  margin-top: 80px;
}
.mt-80 {
  margin-top: -80px;
}
.mt_50 {
  margin-top: 50px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_90 {
  margin-top: 90px;
}

.mt-90 {
  margin-top: -90px;
}

.mt_300 {
  margin-top: -300px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_104 {
  margin-top: 104px;
}

.mt_200 {
  margin-top: 200px;
}

.mt-200 {
  margin-top: -200px;
}

.mt_83 {
  margin-top: 83px;
}

.mt_102 {
  margin-top: 102px;
}

.mt-21 {
  margin-top: -21px;
}

.mt_250 {
  margin-top: 250px;
}

.mt_315 {
  margin-top: 315px !important;
}

.mt_300 {
  margin-top: 300px;
}

.mt_500 {
  margin-top: 500px;
}

.mt_130 {
  margin-top: 130px;
}

.mt_140 {
  margin-top: 140px;
}

.mt_120 {
  margin-top: 120px;
}

.mt_215 {
  margin-top: 215px !important;
}

.mt_205 {
  margin-top: 205px !important;
}

.mt_13 {
  margin-top: -13px;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: -10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_15 {
  margin-bottom: 15px !important;
}

.mb_30 {
  margin-bottom: -30px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb-50 {
  margin-bottom: -50px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_100 {
  margin-bottom: 100px;
}

.mb1000 {
  margin-bottom: 1000px !important;
}

.ml-15 {
  margin-left: -15px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_16 {
  margin-left: 16px;
}

.ml_28 {
  margin-left: 28px;
}

.ml_32 {
  margin-left: 32px !important;
}

.mb_90 {
  margin-bottom: 90px;
}

.ml-10 {
  margin-left: 10px;
}

.ml_10 {
  margin-left: -10px;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-190 {
  margin-left: 190px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-350 {
  margin-left: 350px;
}

.ml_100 {
  margin-left: -100px;
}

.ml_175 {
  margin-left: -175px;
}

.ml_90 {
  margin-left: -90px;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-93 {
  margin-left: 93px;
}

.ml-80 {
  margin-left: 80px;
}

.ml_130 {
  margin-left: -130px;
}

.ml_170 {
  margin-left: -170px;
}

.ml_150 {
  margin-left: -150px;
}

.ml_30 {
  margin-left: -30px;
}

.ml_20 {
  margin-left: -20px;
}

.ml_25 {
  margin-left: -25px;
}

.ml_120 {
  margin-left: -120px;
}

.ml_5 {
  margin-left: -5px;
}

.ml_8 {
  margin-left: -8px !important;
}

.ml_40 {
  margin-left: -40px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml_32 {
  margin-left: -32px;
}

.ml-27 {
  margin-left: 27px !important;
}

.ml-50 {
  margin-left: 50px;
}

.ml-270 {
  margin-left: 270px;
}

.ml-247 {
  margin-left: 247px !important;
}

.ml-220 {
  margin-left: 220px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml_5 {
  margin-left: -5px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.pl_20 {
  padding-left: 20px;
}

.pl_15 {
  padding-left: 15px;
}

.pl_16 {
  padding-left: 16px;
}

/* .pl_17 {
  padding-left: 17px;
} */
.prl_35 {
  padding: 0px 35px;
}

.pl_0 {
  padding-left: 0px;
}

.pl-30 {
  padding-left: 30px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_100 {
  padding-left: 100px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_120 {
  padding-left: 120px;
}

.pr_100 {
  padding-right: 100px;
}

.pr_120 {
  padding-right: 120px;
}

.pr_70 {
  padding-right: 70px;
}

.pr_50 {
  padding-right: 50px;
}

.pr_40 {
  padding-right: 40px;
}

.pr_30 {
  padding-right: 30px;
}

.pr_20 {
  padding-right: 20px;
}

/* .pr_17 {
  padding-right: 17px;
} */
.pr_3 {
  padding-right: 3px;
}

.pt_300 {
  padding-top: 300px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_0 {
  padding-top: 0px !important;
}

.pt_10 {
  padding-top: 10px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_120 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-54 {
  padding-top: 54px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_20 {
  padding-bottom: 20px;
}

.pb_24 {
  padding-bottom: 24px;
}

.pb_30 {
  padding-bottom: 30px;
}

.pb_40 {
  padding-bottom: 40px;
}

.pb_80 {
  padding-bottom: 80px;
}

.pb_60 {
  padding-bottom: 60px;
}

.p42lr {
  padding: 0px 42px;
}

.sec_pad {
  padding: 120px 0px;
}

.sec_pad_80 {
  padding: 80px 0px;
}

.defect_management_sec_pad {
  padding: 65px 0px;
}

.prodcolor {
  color: #484db8 !important;
}

.seccolor {
  color: #db7221;
}

.third_color {
  color: #3c3838;
}
.fourth_color {
  color: #282828;
}
.black {
  color: #000000 !important;
}

.white {
  color: #fff;
}

.w100 {
  width: 100% !important;
}

.w50 {
  width: 47% !important;
}

.w90 {
  width: 90%;
}

.w75 {
  width: 75%;
}

.w85 {
  width: 85%;
}
.w35 {
  width: 35px;
}

.h-105 {
  height: 105px;
}

.h-150 {
  height: 150px;
}

.h-102 {
  height: 102px;
}

.h-127 {
  height: 127px;
}

.h-70 {
  height: 70px;
}

.h-81 {
  height: 81px;
}

.h_50 {
  height: 50px;
}

.h-30 {
  height: 30px;
}

.h-82 {
  height: 82px;
}

.outer_d_flex {
  display: flex;
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.d-flex-space-center {
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  width: 100%;
  gap: 50px;
}
.fl {
  float: left;
}

.fr {
  float: right;
}

.br_16 {
  border-radius: 16px 16px 16px 16px !important;
}

.opacity07 {
  opacity: 0.7 !important;
}

.fade-in-header {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out-header {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.list-none ul li {
  list-style: none;
}

.headtwo {
  text-align: center;
  font-size: 35px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.compatible {
  text-align: center;
  font-size: 35px;
  font-family: Poppins, semiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.headthree {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.weblocator_challenges_h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.weblocator_challenges_h2 {
  text-align: center;
  font-size: 35px;
  font-family: Poppins, semiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.headpara {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 15px;

  font-weight: 500;
}

.headpara_home {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 30px;
}

/*====================================================*/
/*====================================================*/
.btn_hover {
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn_hover:hover {
  color: #fff;
}

.btn_get {
  font: 500 15px/47px "Poppins", SemiBold;
  color: #6754e2;

  border-radius: 4px;
  -webkit-box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  padding: 0px 23px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 120px;
  text-align: center;
}

.btn_get.new_btn {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn_get:hover {
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn_get.new_btn:hover {
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.price_btn {
  font: 500 15px/53px "Poppins", sans-serif;
  color: #6754e2;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 0px 25px;
  display: inline-block;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.price_btn:hover {
  color: #fff;
}

.slider_btn {
  font: 500 14px/20px "Poppins", sans-serif;
  border-radius: 45px;
  padding: 15px 35px;
  background: #fff;
}

.slider_btn:hover {
  color: #fff;
}

.btn_three {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #7444fd;
  border-radius: 3px;
  padding: 15px 30px;
  border: 1px solid #7444fd;
  margin-top: 50px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  display: inline-block;
}

.btn_three:hover {
  color: #7444fd;
  background: transparent;
}

.agency_banner_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #5e2ced;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  border-radius: 3px;
  padding: 16px 38px;
  border: 1px solid #5e2ced;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.agency_banner_btn:hover {
  color: #5e2ced;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.agency_banner_btn_two {
  font: 500 14px "Poppins", sans-serif;
  color: #222d39;
  position: relative;
  margin-left: 50px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.agency_banner_btn_two:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #60656d;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.agency_banner_btn_two:hover {
  color: #5e2ced;
}

.agency_banner_btn_two:hover:before {
  background: #5e2ced;
}

.software_banner_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #fff;
  background: #3d64f4;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  display: inline-block;
  padding: 16px 42px;
  border-radius: 45px;
  border: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.software_banner_btn:hover {
  color: #3d64f4;
  background: #fff;
}

.video_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 14px #0000003b;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  padding: 7px;
  width: 280px;
}

.vicon {
  padding: 5px;
}

.video_btn .icon {
  border-radius: 50%;
  background: #db7221;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 18px;
  margin-right: 18px;
  vertical-align: middle;
  /* box-shadow: 0px 0px 36px #434db8b3; */
  border: 3px solid #ffffff;
  width: 35px;
  height: 35px;
  padding-left: 1px;
  margin-left: 8px;
  margin-right: 15px;
  margin-top: -6px;
}

.video_btn span {
  display: inline-block;
  position: relative;
  text-align: left;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  font-size: 22px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: -5px;
  padding-top: 3px;
}

.video_play_icon img {
  margin-top: -200px;
  margin-left: 125px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.video_btn:hover {
  color: #434db8;
}

.btn_four {
  font: 500 14px "Poppins", sans-serif;
  padding: 16px 40px;
  border-radius: 45px;
  color: #fff;
  border: 1px solid #3d64f4;
  background: #3d64f4;
  cursor: pointer;
}

.btn_four:hover {
  color: #3d64f4;
  background: transparent;
}

.saas_banner_btn {
  padding: 16px 40px;
  background: #3d57f4;
  border-radius: 4px;
  color: #fff;
}

.saas_banner_btn:hover {
  color: #3d57f4;
  background: #fff;
}

.btn_five {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
  padding: 12px 28px;
}

.btn_five:hover {
  background: #3d57f4;
  border-color: #3d57f4;
}

.app_btn {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  background-color: #4069eb;
  display: inline-block;
  padding: 14px 40px;
  color: #fff;
  border-radius: 45px;
  border: 1px solid #4069eb;
}

.app_btn:hover {
  color: #4069eb;
  background: #fbfbfd;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.gr_btn {
  font: 500 14px "Poppins", sans-serif;
  color: #23b1fe;
  display: inline-block;
  padding: 15px 31px;
  position: relative;
  min-width: 140px;
  border-radius: 4px;
  background-image: -moz-linear-gradient(
    -48deg,
    #237efd 0%,
    #24b0fe 46%,
    #24e2ff 100%
  );
  background-image: -webkit-linear-gradient(
    -48deg,
    #237efd 0%,
    #24b0fe 46%,
    #24e2ff 100%
  );
  background-image: -ms-linear-gradient(
    -48deg,
    #237efd 0%,
    #24b0fe 46%,
    #24e2ff 100%
  );
  z-index: 1;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.gr_btn:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 50%;
  width: calc(100% - 2px);
  bottom: 1px;
  border-radius: 4px;
  background: #fff;
  z-index: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
}

.gr_btn .text {
  position: relative;
  z-index: 1;
}

.gr_btn:hover {
  color: #fff;
}

.gr_btn:hover:before {
  opacity: 0;
}

.btn_six {
  background: #17c7bd !important;
  font-size: 15px;
  border: 1px solid #17c7bd !important;
  padding: 13px 35px;
  color: #fff !important;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.btn_six:hover {
  background: #ebfaf9 !important;
  color: #17c7bd !important;
}

.btn_six + .btn_six {
  background: #ebfaf9 !important;
  color: #17c7bd !important;
  margin-left: 20px;
}

.btn_six + .btn_six i {
  padding-right: 5px;
}

.btn_six + .btn_six:hover {
  background: #17c7bd !important;
  color: #fff !important;
}

.seo_btn {
  display: inline-block;
  padding: 11px 40px;
  border-radius: 4px;
}

.seo_btn_one {
  color: #5e2ced;
  background: #e8e1fc;
}

.seo_btn_one:hover {
  background: #5e2ced;
  color: #fff;
}

.seo_btn_two {
  color: #00c99c;
  background: #d4f6ee;
}

.seo_btn_two:hover {
  background: #00c99c;
  color: #fff;
}

/*====================================================*/
/*====================================================*/

/* .ctn-preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.ctn-preloader .loader .row {
  height: 100%;
}

.ctn-preloader .loader .loader-section {
  padding: 0px;
}

.ctn-preloader .loader .loader-section .bg {
  background-color: #ffffff;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.ctn-preloader .loader.dark_bg .loader-section .bg {
  background: #111339;
}

.ctn-preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.ctn-preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  -o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
} */

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }

  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 3.5em "Poppins", sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 2em "Poppins", sans-serif;
  }
}

/*====================================================*/
/*====================================================*/
/*============== header_area css ==============*/
.header_area {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 20;
  top: 0;
}

.position {
  position: static;
  width: 100%;
  left: 0;
  z-index: 20;
  top: 0;
  background-color: white;
}

.menu_one {
  padding: 0px;
}

.menu_one .btn_get {
  margin-left: 20px;
}

.navbar-brand img {
  display: none;
}

.navbar-brand img + img {
  display: block;
}

.platform_logo {
  margin-left: -25px;
  margin-top: 1px;
}

.sticky_logo img {
  display: block;

  width: 200px;
}

.blue_arrow img {
  display: block;
}

.blue_home_icon img {
  display: block;
}

.fireflink {
  display: block;
  width: 200px;
}

.sticky_logo img + img {
  display: none;
}

.blue_arrow img + img {
  display: none;
}

.blue_home_icon img + img {
  display: none;
}

@media (min-width: 992px) {
  .mobile_btn {
    visibility: hidden;
    display: none;
  }
}

.menu > .nav-item {
  position: static;
  padding-bottom: 35px;
  padding-top: 35px;
}

.menu > .nav-item > .nav-link {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0px;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  position: relative;
  cursor: pointer;
}

.position .w_menu .nav-item .nav-link {
  color: #434db8;
}

.navbar_fixed .sticky-inner-wrapper {
  z-index: 999 !important;
}

.menu > .nav-item > .nav-link:hover {
  color: #db7221;
}

.active_line {
  border-bottom: 2px solid #db7221;
  margin-top: 8px;
}

.menu > .nav-item > .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #484db8;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu > .nav-item > .nav-link:after {
  display: none;
}

.menu > .nav-item.submenu .dropdown-menu {
  margin: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 3px;
  min-width: 200px;
  padding: 0px;
  top: 80px;
  left: 0;
  width: 85%;
  height: 330px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #434db829;
  opacity: 1;
}

#my-prod-menu > .nav-item.submenu .dropdown-menu {
  margin: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 3px;
  min-width: 200px;
  padding: 0px;
  top: 85px;
  left: 0;
  width: 30%;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000091;
  opacity: 1;
  right: 0;
  left: auto;
  margin-right: 110px;
}

#my-profile-menu > .nav-item.submenu .dropdown-menu {
  margin: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 10px;
  min-width: 200px;
  padding: 0px;
  top: 85px;
  left: 0;
  width: 26%;
  height: 135px;

  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  opacity: 1;
  right: 0;
  left: auto;
  margin-right: 50px;
}

.submenutab a {
  text-align: left;
  font: normal normal 600 15px/23px Poppins;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu .dropdown-menu {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu > .nav-item.submenu .dropdown-menu .nav-item {
  display: block;
  width: 100%;
  margin-right: 0px;
  padding: 0px 40px;
  position: relative;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link {
  padding: 0px;
  white-space: nowrap;

  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;

  text-align: center;

  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #5d5d5d;
  margin-top: -10px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link:after {
  display: none;
}

.menu
  > .nav-item.submenu
  .dropdown-menu
  .nav-item
  .nav-link
  span.arrow_carrot-right {
  right: 20px;
  position: absolute;
  line-height: 30px;
  font-size: 18px;
}

/* .menu > .nav-item.submenu .dropdown-menu .nav-item:first-child {
  padding-top: 25px;
} */

@media (min-width: 992px) {
  .menu
    > .nav-item.submenu
    .dropdown-menu
    .nav-item:first-child
    > .dropdown-menu {
    top: 0;
  }
}

.menu > .nav-item.submenu .dropdown-menu .nav-item {
  padding-bottom: 10px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item:last-child {
  /* padding-bottom: 30px; */
  /* margin-bottom: -8px; */
}

.menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu {
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  -o-transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  padding: 0px;
}

.menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu:before {
  display: none;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu .dropdown-menu .nav-item > .dropdown-menu {
    position: absolute;
    left: 100%;
    top: -25px;
    opacity: 0;
    display: block;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
}

.menu > .nav-item.submenu .dropdown-menu.mega_menu_three > .nav-item {
  padding: 0px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item {
  padding: 20px 45px;
  border: 1px solid transparent;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item
  .nav-link
  .navdropdown_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item
  .nav-link
  .navdropdown_link
  .navdropdown_icon {
  margin-right: 25px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item
  .nav-link
  .navdropdown_link
  .navdropdown_icon
  img {
  max-width: 30px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item
  .nav-link
  .navdropdown_link
  .navdropdown_content
  h5 {
  font: 500 16px "Poppins", sans-serif;
  color: #13112d;
  margin-bottom: 0px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item
  .nav-link
  .navdropdown_link
  .navdropdown_content
  p {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 15px;
  line-height: 20px;
  color: #677294;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item.nav_download_btn {
  border-top-color: #f2f0f7;
  padding: 15px 45px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item.nav_download_btn
  .nav-link
  .navdropdown_link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item.nav_download_btn
  .nav-link
  .navdropdown_link
  .navdropdown_icon {
  margin-right: 10px;
}

.menu
  > .nav-item.submenu
  .dropdown-menu.mega_menu_three
  > .nav-item
  > .dropdown-menu
  .nav-item:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  border-color: #f2f0f7;
  background-color: white;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1),
    0px -1px 0px 0px rgba(243, 241, 248, 0.004);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1),
    0px -1px 0px 0px rgba(243, 241, 248, 0.004);
}

.menu > .nav-item.submenu.mega_menu {
  position: static;
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner {
  min-width: 200px;
  list-style: none;
  position: absolute;
  -webkit-box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  left: 0px;
  right: 0px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  background: #fff;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu .mega_menu_inner {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  left: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
}

@media (min-width: 992px) {
  .menu > .nav-item.submenu.mega_menu .mega_menu_inner > .dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 0px;
  width: 25%;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  font: 500 18px "Poppins", sans-serif;
  color: #13112d;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item
  .dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
  background: transparent;
  top: 0;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item
  .dropdown-menu
  > .nav-item {
  padding-left: 0px;
  padding-right: 0px;
  white-space: normal;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item
  .dropdown-menu
  > .nav-item:first-child {
  padding-top: 20px;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item
  .dropdown-menu
  > .nav-item:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.menu
  > .nav-item.submenu.mega_menu
  .mega_menu_inner
  > .dropdown-menu
  > .nav-item:hover
  > .nav-link {
  color: inherit !important;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu {
  left: 0;
}

@media (min-width: 992px) {
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    .mega_menu_inner
    .dropdown-menu {
    padding: 20px 0px;
  }
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .mCSB_scrollTools {
  width: 0px;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .mCSB_container {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .mCustomScrollBox {
  width: 100%;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item {
  padding: 0px;
  margin-bottom: 0;
  width: 100%;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .dropdown-menu {
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-height: 980px) and (min-width: 992px) {
  .menu
    > .nav-item.submenu.mega_menu.mega_menu_two
    .mega_menu_inner
    .dropdown-menu
    .nav-item
    .dropdown-menu {
    max-height: 720px;
    overflow-y: scroll;
  }

  .dropdown-menu::-webkit-scrollbar {
    display: none;
  }
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .dropdown-menu
  .nav-item {
  width: 25%;
  padding: 15px 20px;
  margin-bottom: 0;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item {
  text-align: center;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .rebon_tap {
  font: 500 12px "Poppins", sans-serif;
  color: #fff;
  background: #f91f43;
  display: block;
  position: absolute;
  top: -7px;
  left: -25px;
  padding: 15px 22px 5px;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .img {
  -webkit-box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  position: relative;
  margin-top: 0;
  display: block;
  overflow: hidden;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #13112d;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .img
  img {
  width: 100%;
  max-width: 100%;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .img.box_none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item
  .text {
  font: 500 16px/22px "Poppins", sans-serif;
  color: #13112d;
  display: block;
  margin-top: 20px;
}

.menu
  > .nav-item.submenu.mega_menu.mega_menu_two
  .mega_menu_inner
  .dropdown-menu
  .nav-item
  .item:hover
  .img:before {
  opacity: 0.5;
}

@media (min-width: 992px) {
  .menu
    > .nav-item.submenu.mega_menu:hover
    .dropdown-menu
    > .nav-item
    > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .menu > .nav-item.submenu.mega_menu:hover .mega_menu_inner {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

/* 
.menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu {
  left: auto;
  right: 0;
}

.menu > .nav-item:nth-last-child(-n+2).submenu .dropdown-menu .nav-item > .dropdown-menu {
  left: auto;
  right: 100%;
}

.menu > .nav-item:nth-last-child(-n+2).submenu.mega_menu .dropdown-menu {
  left: 0;
  right: auto;
}

.menu > .nav-item:nth-last-child(-n+2).submenu.mega_menu .dropdown-menu .nav-item > .dropdown-menu {
  left: 0;
  right: auto;
} */

.menu > .nav-item + .nav-item {
  margin-left: 45px;
}

.menu > .nav-item .nav-link.active:before {
  width: 100%;
  left: 0;
  right: auto;
}

.menu > .nav-item:hover .nav-link:before {
  width: 100%;
  left: 0;
  right: auto;
}

@media (min-width: 992px) {
  .menu > .nav-item:hover .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }

  .menu
    > .nav-item:hover
    .dropdown-menu.mega_menu_three
    .nav-item
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .menu > .nav-item:hover .dropdown-menu > .nav-item:hover .dropdown-menu {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.navbar .search_cart .search a,
.navbar .search_cart .shpping-cart a {
  font-size: 16px;
  line-height: 18px;
  color: #282835;
  margin-left: 16px;
}

.navbar .search_cart.w_menu .search a,
.navbar .search_cart.w_menu .shpping-cart a {
  color: #fff;
}

.navbar .search_cart.w_menu .search a.nav-link {
  margin-left: 0;
}

.navbar .search_cart.w_menu .search a.nav-link:before {
  background: #fff;
}

.navbar .search_cart .search {
  display: inline-block;
}

.navbar .search_cart .search a.nav-link {
  font-size: 13px;
  line-height: 19px;
  position: relative;
  margin-left: 32px;
}

.navbar .search_cart .search a.nav-link:before {
  width: 1px !important;
  height: 12px;
  left: -14px;
  top: 3px;
  background: #13112d;
}

.navbar .search_cart .shpping-cart {
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
}

.navbar .search_cart .shpping-cart .num {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #6754e2;
  color: #fff;
  font-size: 11px;
  display: block;
  position: absolute;
  top: -8px;
  right: -9px;
  line-height: 16px;
  text-align: center;
}

.navbar .search_cart .shpping-cart .nav-link:before {
  display: none;
}

.navbar .search_cart .shpping-cart .dropdown-menu {
  padding: 0px;
  min-width: 350px;
  width: 100%;
  position: absolute;
}

.navbar .search_cart .shpping-cart .dropdown-menu li {
  background: #fff;
}

.navbar .search_cart .shpping-cart .dropdown-menu li:first-child {
  padding-bottom: 0px;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item a {
  color: #333;
  margin-left: 0;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart-single-item .cart-img {
  display: block;
  width: 30%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-content {
  width: 60%;
  padding-left: 15px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-content
  .cart-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-content
  p {
  font-size: 14px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-weight: 300;
  margin-bottom: 0px;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-content
  p
  del {
  color: #919191;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-remove {
  width: 10%;
  text-align: right;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-remove
  a {
  font-size: 14px;
  color: #333;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item
  .cart-remove
  a:hover {
  color: #6754e2;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item:hover
  .cart-img {
  opacity: 0.5;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart-single-item:hover
  .cart-title
  a {
  color: #6754e2;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f {
  padding: 25px 30px 30px;
  background: #f9f9f9;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .total {
  font-size: 14px;
  color: #676b75;
}

.navbar .search_cart .shpping-cart .dropdown-menu .cart_f .total .p-total {
  float: right;
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart_f
  .cart-button
  .get_btn {
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 4px;
  margin: 0px;
  line-height: 40px;
  padding: 0px 32px;
  background: #6754e2;
  border: 1px solid #6754e2;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart_f
  .cart-button
  .get_btn:hover {
  background: transparent;
  color: #6754e2;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart_f
  .cart-button
  .get_btn
  + .get_btn {
  margin-left: 10px;
  background: transparent;
  color: #6754e2;
}

.navbar
  .search_cart
  .shpping-cart
  .dropdown-menu
  .cart_f
  .cart-button
  .get_btn
  + .get_btn:hover {
  background: #6754e2;
  color: #fff;
}

.menu_two {
  padding: 0px;
}

.menu_two .w_menu > .nav-item .nav-link:before {
  background: #a5d5f5;
}

.menu_two
  .w_menu
  > .nav-item.submenu.mega_menu
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #13112d;
}

.menu_two .menu_toggle .hamburger-cross span,
.menu_two .menu_toggle .hamburger span {
  background: #fff;
}

/* .w_menu .nav-item .nav-link {
  color: #434DB8;
} */

.w_menu .nav-item .nav-link:before {
  background: #fff;
}

.nav_right_btn .login_btn {
  font: 500 15px "Poppins", sans-serif;
  padding: 13px 32px;
  min-width: 120px;
  display: inline-block;
  text-align: center;
  color: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  border-top-right-radius: 45px;
  border: 1px solid transparent;
}

.nav_right_btn .login_btn:hover,
.nav_right_btn .login_btn.active {
  background: #fff;
  -webkit-box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
}

@media (max-width: 991px) {
  .nav_right_btn {
    display: none;
  }
}

.menu_four .btn_get {
  border-color: #fff;
  padding: 0px 23px;

  min-width: 120px;
  text-align: center;
}

.menu_four .btn_get:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.menu_four .btn_get_radious {
  background: #db7221;
}

.header_area .menu_four .btn_get.btn_get_radious:hover {
  background: #fff;
}

.menu_four .menu_toggle .hamburger span,
.menu_four .menu_toggle .hamburger-cross span {
  background: #fff;
}

.menu_five {
  border-radius: 0px;
}

.menu_five .menu > .nav-item .nav-link {
  color: #fff;
}

.menu_five .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_five .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_five
  .menu
  > .nav-item.submenu
  .dropdown-menu
  .nav-item
  > .nav-link.active {
  color: #3d64f4;
}

.menu_five
  .menu
  > .nav-item.submenu.mega_menu
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #051441;
}

.menu_five .btn_get {
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  line-height: 44px;
  padding: 0px 28px;
  min-width: 120px;
  text-align: center;
}

.menu_five .btn_get:hover {
  background: #fff;
  color: #3d64f4;
  border-color: #fff;
}

.menu_five .menu_toggle .hamburger span,
.menu_five .menu_toggle .hamburger-cross span {
  background: #fff;
}

.menu_six {
  padding: 0px;
}

.menu_six .menu > .nav-item .nav-link {
  color: #fff;
}

.menu_six .menu > .nav-item:hover .nav-link,
.menu_six .menu > .nav-item.active .nav-link {
  color: #3d57f4;
}

.menu_six .menu > .nav-item:hover .nav-link:before,
.menu_six .menu > .nav-item.active .nav-link:before {
  background: #3d57f4;
}

.menu_six .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_six .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_six
  .menu
  > .nav-item.submenu
  .dropdown-menu
  .nav-item
  > .nav-link.active {
  color: #3d57f4;
}

.menu_six
  .menu
  > .nav-item.submenu.mega_menu
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #051441;
}

.menu_six .menu > .nav-item:nth-last-child(-n + 2).submenu .dropdown-menu {
  left: -40px;
  right: auto;
}

.menu_six .menu_toggle .hamburger-cross span,
.menu_six .menu_toggle .hamburger span {
  background: #fff;
}

.menu_six .btn_get {
  border: 1px solid #fff;
  line-height: 43px;
  color: #fff;
}

.menu_six .btn_get:hover {
  background: #3d57f4;
  border-color: #3d57f4;
}

.menu_seven {
  padding: 0px;
}

.menu_seven .menu .nav-item .nav-link {
  color: #fff;
}

.menu_seven .menu .nav-item.active .nav-link:before,
.menu_seven .menu .nav-item:hover .nav-link:before {
  background: #fff;
}

.menu_seven .menu .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_seven .menu .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_seven
  .menu
  .nav-item.submenu
  .dropdown-menu
  .nav-item.active
  > .nav-link {
  color: #4069eb;
}

.menu_seven
  .menu
  .nav-item.submenu.mega_menu
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #051441;
}

.menu_seven .menu_toggle .hamburger-cross span,
.menu_seven .menu_toggle .hamburger span {
  background: #fff;
}

.menu_seven .btn_get {
  background: transparent;
}

.menu_seven .btn_get:hover {
  background: #fff;
  color: #4069eb;
}

.menu_eight .menu .nav-item.active .nav-link:before,
.menu_eight .menu .nav-item:hover .nav-link:before {
  background: #fff;
}

.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-link {
  padding-bottom: 10px;
}

.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_eight .menu .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_eight
  .menu
  .nav-item.submenu
  .dropdown-menu
  .nav-item.active
  > .nav-link {
  color: #484db8;
  border-bottom: 2px solid #db7221;
  /* margin-top: 21px; */
  padding-bottom: 8px;
}

.active-menu {
  color: #484db8 !important;
  border-bottom: 2px solid #db7221 !important;
  /* margin-top: 21px; */
  padding-bottom: 8px !important;
}

.menu_eight
  .menu
  .nav-item.submenu.mega_menu
  > .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #051441;
}

.menu_eight .saas_btn {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #23b1fe;
}

.menu_eight .menu_toggle .hamburger-cross span,
.menu_eight .menu_toggle .hamburger span {
  background: #fff;
}

.navbar_fixed .menu_eight .menu_toggle .hamburger-cross span,
.navbar_fixed .menu_eight .menu_toggle .hamburger span {
  background: #23b1fe;
}

.menu_nine .menu > .nav-item.submenu .dropdown-menu .nav-item:hover > .nav-link,
.menu_nine .menu > .nav-item.submenu .dropdown-menu .nav-item:focus > .nav-link,
.menu_nine
  .menu
  > .nav-item.submenu
  .dropdown-menu
  .nav-item.active
  > .nav-link {
  color: #5f51fb;
}

.menu_nine .menu_toggle .hamburger-cross span,
.menu_nine .menu_toggle .hamburger span {
  background: #fff;
}

.menu_nine .btn_get {
  border-radius: 45px;
  line-height: 40px;
  font-size: 15px;
  margin-left: 35px;
}

.btn_get_radious {
  border-radius: 45px;
  border-width: 1px;
  color: #fff;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 28px;
  line-height: 45px;
}

.btn_get_radious:before {
  background: #5846ed;
}

.btn_get_radious:hover {
  color: #5846ed;
}

.menu_tracking .container {
  width: 100%;
  max-width: 100%;
  padding-left: 65px;
  padding-right: 65px;
}

.menu_one .tracking_btn {
  font-size: 16px;
  color: #fff;
  background: #6754e2;
  border: 0px;
  padding: 0px 50px;
  line-height: 42px;
  margin-top: 0;
  margin-left: 0px;
  border-radius: 45px;
  display: block;
  position: relative;
  overflow: visible;
}

.menu_four.hosting_menu .event_btn.btn_get {
  border-color: #af8a90;
  background: transparent;
  padding: 0px 41px;
  margin-left: 0;
}

.navbar_fixed .menu_four.hosting_menu .event_btn.btn_get {
  color: #2c2c51;
  border-color: #2c2c51;
  background: transparent;
}

.navbar_fixed .menu_four.hosting_menu .event_btn.btn_get:hover {
  color: #fff;
}

.submenutab {
  display: flex;
  justify-content: space-between;
}

.inline-li {
  display: inline-block !important;
  width: auto !important;
}

.submenutext {
  margin-left: 5px;
  margin-top: -5px;
}

.submenutext h3 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 5px;
  cursor: pointer;
}

.submenutext h3 a {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 5px;
  cursor: pointer;
}

.submenutext p {
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: #5d5d5d;
  margin-top: -7px;
  line-height: normal;
}

.submenuhead {
  margin-top: 10px;
  padding-left: 60px;
}

.submenuhead a {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: #5d5d5d;
}

.submenuhead a.active {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: #434db8;
}

.submenuhead a:hover {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: #434db8;
}

.bottomline {
  border: 1px solid #434db829;
  margin-top: -11px;
  width: 91%;
  margin-left: 42px;
}

.borbot {
  border-bottom: 2px solid #484db8;
}

.rightline {
  border-right: 1px solid #484db8;
  height: 20px;
  margin-left: 0px;
  margin-top: -25px;
  margin-right: -40px;
}

.submenuicon {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0px -5px 15px;
  font-size: 24px;
  color: white;
  text-align: center;
  line-height: 64px;
  text-overflow: clip;
  z-index: 1;
  /* background: transparent linear-gradient(144deg, #4C57CD 0%, #0B1582 100%) 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 0px 15px #00000061; */
  opacity: 1;
  background: #434db8 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 25px;
  padding: 5px;
}

.submenuicon i {
  display: block;
  margin-top: 9px;
}

.dropdown.submenu.nav-item {
  display: flex;
}

.arrow {
  margin-left: 5px;
  /* margin-top: 12px; */
  margin-right: -3px;
}

/*============== header_area css ==============*/
/*========= hamburger menu css ========*/
.navbar-toggler {
  padding: 0px;
  margin-left: 20px;
}

.navbar-toggler:focus {
  outline: none;
}

.menu_toggle {
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  display: block;
  top: 5px;
}

.menu_toggle .hamburger {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}

.menu_toggle .hamburger span {
  width: 0%;
  height: 2px;
  position: relative;
  top: 0;
  left: 0;
  margin: 4px 0;
  display: block;
  background: #5e2ced;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.125s;
  -o-transition-delay: 0.125s;
  transition-delay: 0.125s;
}

.menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menu_toggle .hamburger-cross {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
}

.menu_toggle .hamburger-cross span {
  display: block;
  background: #5e2ced;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.menu_toggle .hamburger-cross span:nth-child(1) {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 10px;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.menu_toggle .hamburger-cross span:nth-child(2) {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span {
  width: 100%;
}

.collapsed .menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.collapsed .menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(1) {
  height: 0%;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(2) {
  width: 0%;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.header_area_two + section,
.header_area_three + section {
  margin-top: 93px;
}

.h_dark {
  background: #13112d;
}

.header_area_three {
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.1);
}

.header_area_three .menu_four .pl_30 {
  padding-left: 30px;
}

.header_area_three .menu_four .btn_get {
  border-color: #5e2ced;
  background: #5e2ced;
  color: #fff;
}

.header_area_three .menu_four .btn_get:hover {
  background: transparent;
  color: #5e2ced;
}

.header_area_three.navbar_fixed .menu_four .btn_get {
  border-color: #5e2ced !important;
}

.header_area_four .header_top {
  background: #13112d;
}

.header_area_four .header_top a {
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.header_area_four .header_top a:hover {
  color: #5e2ced;
}

.header_area_four + section {
  margin-top: 144px;
}

.header_top {
  padding: 12px 0px;
}

.header_contact_info {
  margin-bottom: 0;
}

.header_contact_info li {
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
}

.header_contact_info li:before {
  content: "";
  width: 1px;
  height: 12px;
  top: 1px;
  background: #5e5d76;
  display: inline-block;
  position: relative;
  margin: 0px 22px 0px 20px;
}

.header_contact_info li:first-child:before {
  display: none;
}

.header_contact_info li a {
  color: #c3c1da;
  font-weight: 300;
  padding-left: 5px;
}

.header_social_icon {
  text-align: right;
  margin-bottom: 0;
}

.header_social_icon li {
  display: inline-block;
  margin: 0px 0px 0px 15px;
}

.header_social_icon li a {
  font-size: 12px;
  color: #fff;
}

.header_area_five .menu_one {
  padding: 0px 50px;
}

.header_area_six .header_top {
  border-bottom: 1px solid #e5ddfc;
}

.header_area_six .header_top a:hover {
  color: #5e2ced !important;
}

.header_area_six .header_top .header_contact_info li {
  color: #282835;
}

.header_area_six .header_top .header_contact_info li a {
  color: #677294;
}

.header_area_six .header_top .header_social_icon li a {
  color: #282835;
}

.header_area_six + section {
  margin-top: 144px;
}

.header_area_seven .header_top {
  background: #5e2ced;
  padding: 0px;
}

.header_area_seven .header_top a {
  line-height: 50px;
  opacity: 0.7;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.header_area_seven .header_top a:hover {
  opacity: 1;
}

.header_area_seven .header_top .header_contact_info li i {
  vertical-align: middle;
}

.header_area_seven .header_top .header_contact_info li a {
  color: #fff;
}

.header_area_seven .header_top .header_social_icon {
  border-left: 1px solid #7444ff;
  padding-left: 22px;
  margin-left: 40px;
}

.header_area_seven .header_top .header_top_menu {
  text-align: right;
  margin-bottom: 0px;
}

.header_area_seven .header_top .header_top_menu li {
  display: inline-block;
}

.header_area_seven .header_top .header_top_menu li:before {
  content: "";
  width: 1px;
  height: 11px;
  background: #8e6bf7;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 17px 0px 15px;
}

.header_area_seven .header_top .header_top_menu li a {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #fff;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.header_area_seven .header_top .header_top_menu li:first-child:before {
  display: none;
}

.header_area_seven + section {
  margin-top: 118px;
}

.menu_poss .w_menu.search_cart .search a.nav-link {
  margin-left: 32px;
}

/*============ sticky menu css ==============*/
.header_area,
.position {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area,
.navbar_fixed .position {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.navbar_fixed .header_area .menu_one,
.navbar_fixed .position .menu_one {
  padding: 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area .menu_one .btn_get,
.navbar_fixed .position .menu_one .btn_get {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar_fixed .header_area .menu_one .btn_get.new_btn {
  color: #6754e2;
  border: 2px solid #ccc5fa;
  background: transparent;
}

.navbar_fixed .header_area .menu_one .btn_get.new_btn:hover {
  color: #fff;
  background: #5e2ced;
  border-color: #5e2ced;
}

.navbar_fixed .header_area .sticky_logo img,
.position .sticky_logo img {
  display: none;
}

.navbar_fixed .blue_arrow img,
.position .blue_arrow img {
  display: none;
}

.navbar_fixed .blue_home_icon img,
.position .blue_home_icon img {
  display: none;
}

.navbar_fixed .header_area .sticky_logo img + img,
.position .sticky_logo img + img {
  display: block;
}

/* .navbar_fixed .blue_arrow img + img { */

.navbar_fixed .blue_arrow img + img,
.position .blue_arrow img + img {
  display: block;
}

.navbar_fixed .blue_home_icon img + img,
.position .blue_home_icon img + img {
  display: block;
}

.navbar_fixed .header_area .w_menu .nav-item .nav-link,
.position .w_menu .nav-item .nav-link {
  color: #434db8;
}
.prodtexthover {
  color: #db7221 !important;
}
.navbar_fixed .header_area .w_menu .nav-item .nav-link:before,
.position .w_menu .nav-item .nav-link:before {
  background: #051441;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link:hover,
.position .w_menu .nav-item .nav-link:hover {
  color: #db7221;
}

/* .navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link:hover:before,
.position .menu_poss .w_menu .nav-item .nav-link:hover:before {
  background: #434db8;
} */

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link.active,
.position .menu_poss .w_menu .nav-item .nav-link.active {
  color: #434db8;
}

.navbar_fixed .header_area .menu_poss .w_menu .nav-item .nav-link.active:before,
.position .menu_poss .w_menu .nav-item .nav-link.active:before {
  background: #5e53fb;
}

.navbar_fixed
  .header_area
  .menu_poss
  .w_menu.search_cart
  .search
  a.nav-link:before,
.position .menu_poss .w_menu.search_cart .search a.nav-link:before {
  background: #5e53fb;
}

.navbar_fixed .header_area .menu_two .menu_toggle .hamburger-cross span,
.navbar_fixed .header_area .menu_two .menu_toggle .hamburger span,
.position .menu_two .menu_toggle .hamburger-cross span,
.position .menu_two .menu_toggle .hamburger span {
  background: #051441;
}

.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn.active,
.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn:hover,
.position .menu_two .nav_right_btn .login_btn.active,
.position .menu_two .nav_right_btn .login_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar_fixed .header_area .menu_two .nav_right_btn .login_btn.active:hover,
.position .menu_two .nav_right_btn .login_btn.active:hover {
  color: #fff;
}

.menu_four .btn_get {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar_fixed .menu_four .btn_get {
  border-color: #ccc5fa;
}

.menu_four .btn_get:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.navbar_fixed .header_area .menu_four .btn_get:hover,
.position .menu_four .btn_get:hover {
  border-color: #6754e2;
}

.menu_one.erp_menu .er_btn {
  box-shadow: none;
}

.navbar_fixed .header_area .menu_four .btn_get_radious,
.position .menu_four .btn_get_radious {
  border-color: #563bd1;
  color: #fff;
}

.navbar_fixed .header_area .menu_four .btn_get_radious:hover,
.position .menu_four .btn_get_radious:hover {
  background: #563bd1;
  color: #fff;
}

.navbar_fixed .header_area .menu_four .menu_toggle .hamburger span,
.navbar_fixed .header_area .menu_four .menu_toggle .hamburger-cross span,
.position .menu_four .menu_toggle .hamburger span,
.position .menu_four .menu_toggle .hamburger-cross span {
  background: #6754e2;
}

.navbar_fixed .header_area .nav_right_btn .login_btn,
.position .nav_right_btn .login_btn {
  color: #051441;
}

.navbar_fixed .header_area .menu_five .btn_get,
.position .menu_five .btn_get {
  border-color: #3d64f4;
  color: #3d64f4;
  display: inline-block;
}

.navbar_fixed .header_area .menu_five .btn_get:hover,
.position .menu_five .btn_get:hover {
  background: #3d64f4;
  color: #fff;
}

.navbar_fixed .header_area .menu_five .menu_toggle .hamburger-cross span,
.navbar_fixed .header_area .menu_five .menu_toggle .hamburger span,
.position .menu_five .menu_toggle .hamburger-cross span,
.position .menu_five .menu_toggle .hamburger span {
  background: #3d64f4;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item > .nav-link {
  color: #051441;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item:hover > .nav-link,
.navbar_fixed .header_area .menu_six .menu > .nav-item.active > .nav-link {
  color: #3d57f4;
}

.navbar_fixed .header_area .menu_six .menu > .nav-item:hover > .nav-link:before,
.navbar_fixed
  .header_area
  .menu_six
  .menu
  > .nav-item.active
  > .nav-link:before {
  background: #3d57f4;
}

.navbar_fixed .header_area .menu_six .menu_toggle .hamburger-cross span,
.navbar_fixed .header_area .menu_six .menu_toggle .hamburger span {
  background: #3d57f4;
}

.navbar_fixed .header_area .menu_six .btn_get {
  border-color: #3d57f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #3d57f4;
}

.navbar_fixed .header_area .menu_six .btn_get:hover {
  color: #fff;
}

.navbar_fixed .header_area .menu_seven .menu > .nav-item > .nav-link {
  color: #051441;
}

.navbar_fixed .header_area .menu_seven .menu > .nav-item:hover > .nav-link,
.navbar_fixed .header_area .menu_seven .menu > .nav-item.active > .nav-link {
  color: #4069eb;
}

.navbar_fixed
  .header_area
  .menu_seven
  .menu
  > .nav-item:hover
  > .nav-link:before,
.navbar_fixed
  .header_area
  .menu_seven
  .menu
  > .nav-item.active
  > .nav-link:before {
  background: #4069eb;
}

.header_area.navbar_fixed .menu_seven .btn_get {
  border-color: #4069eb;
  color: #4069eb;
}

.header_area.navbar_fixed .menu_seven .btn_get:hover {
  background: #4069eb;
  color: #fff;
}

.header_area.navbar_fixed .menu_seven .menu_toggle .hamburger-cross span,
.header_area.navbar_fixed .menu_seven .menu_toggle .hamburger span {
  background: #4069eb;
}

.header_area.navbar_fixed .menu_eight .saas_btn {
  border-color: #23b1fe;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header_area.navbar_fixed .menu_eight .saas_btn:hover {
  background: #23b1fe;
  color: #fff;
}

.header_area.navbar_fixed .menu_eight .menu_toggle .hamburger span,
.header_area.navbar_fixed .menu_eight .menu_toggle .hamburger-cross span {
  background: #23b1fe;
}

.header_area.navbar_fixed .menu_nine .menu > .nav-item:hover > .nav-link,
.header_area.navbar_fixed .menu_nine .menu > .nav-item.active > .nav-link {
  color: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .menu > .nav-item:hover > .nav-link:before,
.header_area.navbar_fixed
  .menu_nine
  .menu
  > .nav-item.active
  > .nav-link:before {
  background: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .btn_get {
  border-color: #5f51fb;
  color: #5f51fb;
}

.header_area.navbar_fixed .menu_nine .btn_get:hover {
  background: #5f51fb;
  color: #fff;
}

.header_area.navbar_fixed .menu_nine .menu_toggle .hamburger-cross span,
.header_area.navbar_fixed .menu_nine .menu_toggle .hamburger span {
  background: #5f51fb;
}

.header_area.navbar_fixed.header_area_five .menu_one {
  padding: 0px 50px;
}

.header_area.navbar_fixed.header_area_six .header_top {
  display: none;
}

.full_header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
}

.full_header .logo img {
  display: none;
}

.full_header .logo img + img {
  display: inline-block;
}

.full_header .bar_menu {
  cursor: pointer;
}

.full_header .bar_menu span {
  width: 36px;
  height: 2px;
  background: #fff;
  display: block;
}

.full_header .bar_menu span:not(:last-child) {
  margin-bottom: 7px;
}

.full_header .bar_menu span:nth-child(2) {
  width: 24px;
}

.full_header .bar_menu span:nth-child(3) {
  width: 30px;
}

.full_header.content-white .logo img {
  display: inline-block;
}

.full_header.content-white .logo img + img {
  display: none;
}

.hamburger-menu-wrepper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 108;
  padding: 55px 80px;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: -webkit-transform 0.8s ease 0.4s;
  transition: -webkit-transform 0.8s ease 0.4s;
  -o-transition: transform 0.8s ease 0.4s;
  transition: transform 0.8s ease 0.4s;
  transition: transform 0.8s ease 0.4s, -webkit-transform 0.8s ease 0.4s;
  -webkit-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  background: rgba(5, 20, 65, 0.98);
}

.hamburger-menu-wrepper .nav {
  position: relative;
  z-index: 108;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  opacity: 0;
  visibility: hidden;
  -o-transition: all ease 0.3s 0s;
  -webkit-transition: all ease 0.3s 0s;
  transition: all ease 0.3s 0s;
}

.hamburger-menu-wrepper .nav .nav_logo img {
  opacity: 1 !important;
}

.hamburger-menu-wrepper .nav .close-menu {
  color: #fff;
}

.hamburger-menu-wrepper .animation-box {
  position: absolute;
  top: 5%;
  height: 100%;
  width: 100%;
  left: 0;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hamburger-menu-wrepper .animation-box .menu-box {
  display: table;
  height: 100%;
  width: 62%;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hamburger-menu-wrepper.show-menu {
  z-index: 108;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  transition: -webkit-transform 0.7s ease 0.2s;
  -webkit-transition: -webkit-transform 0.7s ease 0.2s;
  -o-transition: transform 0.7s ease 0.2s;
  transition: transform 0.7s ease 0.2s;
  transition: transform 0.7s ease 0.2s, -webkit-transform 0.7s ease 0.2s;
}

.hamburger-menu-wrepper.show-menu .nav {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
  transition: opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
  -o-transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s;
  transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s;
  transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s,
    -webkit-transform ease 1.4s 0.7s;
  opacity: 1;
  visibility: visible;
}

.hamburger-menu-wrepper.show-menu .animation-box {
  top: 0;
  opacity: 1;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

.hamburger-menu-wrepper.show-menu .animation-box .menu-box {
  opacity: 1;
}

.hamburger-menu-wrepper.show-menu
  .animation-box
  .menu-box
  .offcanfas_menu
  > li
  a {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
  transition: opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
  -o-transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s;
  transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s;
  transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s,
    -webkit-transform ease 1.4s 0.6s;
  opacity: 1;
  visibility: visible;
}

.navbar .offcanfas_menu {
  margin: 0px;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

.navbar .offcanfas_menu > .nav-item {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

.navbar .offcanfas_menu > .nav-item:not(:last-child) {
  margin-bottom: 18px;
}

.navbar .offcanfas_menu > .nav-item .nav-link {
  font-size: 2.1rem;
  line-height: 46px;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  opacity: 0;
  visibility: hidden;
  -o-transition: all ease 0.3s 0s;
  -webkit-transition: all ease 0.3s 0s;
  transition: all ease 0.3s 0s;
  display: inline-block;
}

.navbar .offcanfas_menu > .nav-item .nav-link:before {
  background: #fff;
}

.navbar .offcanfas_menu > .nav-item.submenu .nav-link {
  padding-right: 20px;
}

.navbar .offcanfas_menu > .nav-item.submenu .nav-link:after {
  content: "\33";
  font-family: eleganticons;
  position: absolute;
  right: 13px;
  top: 0;
  display: block;
  border: 0;
  line-height: 50px;
  margin-left: 20px;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu {
  position: relative !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
  display: none;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
  z-index: 0;
  background: transparent;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 0;
  max-height: none !important;
  left: 0;
}

.navbar .offcanfas_menu > .nav-item.submenu .dropdown-menu > .nav-item {
  background: transparent;
  padding: 0px;
}

.navbar
  .offcanfas_menu
  > .nav-item.submenu
  .dropdown-menu
  > .nav-item
  > .nav-link {
  color: #fff;
  font-size: 16px;
  line-height: 31px;
}

.navbar
  .offcanfas_menu
  > .nav-item.submenu
  .dropdown-menu
  > .nav-item:hover
  .nav-link,
.navbar
  .offcanfas_menu
  > .nav-item.submenu
  .dropdown-menu
  > .nav-item.active
  .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.active .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.active .nav-link:before {
  background: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .nav-link {
  color: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .nav-link:before {
  width: 100%;
  background: #5f28fb;
}

.navbar .offcanfas_menu > .nav-item.submenu.show .dropdown-menu {
  position: relative !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.header_footer {
  display: table-footer-group;
  padding-bottom: 80px;
}

.header_footer h5 {
  font-size: 16px;
  color: #fff;
}

.header_footer ul {
  margin-bottom: 40px;
}

.header_footer ul li {
  display: inline-block;
  margin-right: 6px;
}

.header_footer ul li a {
  width: 44px;
  height: 44px;
  border: 1px solid #656c82;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  line-height: 44px;
  color: #fff;
  display: block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.header_footer ul li a:hover {
  background: #fff;
  border-color: #fff;
  color: #5f28fb;
}

.header_footer p {
  font-size: 16px;
  color: #858faf;
  margin-bottom: 100px;
}

.close_icon {
  position: absolute;
  right: 60px;
  top: 40px;
  color: #7b84a2;
  font-size: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.close_icon:hover {
  color: #fff;
}

.burger_menu {
  width: 40px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  font-size: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #051441;
  margin-left: 25px;
}

.side_menu_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(5, 20, 65, 0.9);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.8s ease 0.4s;
  transition: -webkit-transform 0.8s ease 0.4s;
  -o-transition: transform 0.8s ease 0.4s;
  transition: transform 0.8s ease 0.4s;
  transition: transform 0.8s ease 0.4s, -webkit-transform 0.8s ease 0.4s;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.side_menu_wrapper .side_menu {
  max-width: 545px;
  height: 100%;
  margin-left: auto;
  background: #fff;
  position: relative;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
}

.side_menu_wrapper .side_menu ul {
  margin-bottom: 0;
}

.side_menu_wrapper .side_menu .menu_top {
  padding: 60px 70px 0px;
}

.side_menu_wrapper .side_menu .menu_top .close {
  font-size: 20px;
}

.side_menu_wrapper .side_menu .m_content {
  position: absolute;
  left: 0px;
  top: 10vh;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 55vh;
  -webkit-overflow-scrolling: touch;
  font-weight: 600;
  width: 100%;
  display: block;
  letter-spacing: -0.025rem;
  list-style: none;
  margin: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 0.5s 0.6s ease, -webkit-transform 0.5s 0.6s ease;
  transition: opacity 0.5s 0.6s ease, -webkit-transform 0.5s 0.6s ease;
  -o-transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease;
  transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease;
  transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease,
    -webkit-transform 0.5s 0.6s ease;
  list-style: none;
  padding-left: 18%;
  padding-right: 100px;
  margin-top: 5vh;
}

.side_menu_wrapper .side_menu .m_content h2 {
  font-size: 3.5vh;
  line-height: 4vh;
  font-weight: 700;
  color: #051441;
  margin-bottom: 1vh;
}

.side_menu_wrapper .side_menu .m_content a {
  font-size: 2.5vh;
  font-weight: 500;
}

.side_menu_wrapper .side_menu .m_content p {
  font-size: 1.9vh;
  line-height: 2.9vh;
  color: #7e86a1;
  font-weight: 300;
  margin-top: 4vh;
  margin-bottom: 8.2vh;
}

.side_menu_wrapper .side_menu .get_info li .media img {
  margin-right: 20px;
  height: 5vh;
}

.side_menu_wrapper .side_menu .get_info li .media .media-body h4 {
  font-size: 2vh;
  font-weight: 600;
  margin-bottom: 0.5vh;
}

.side_menu_wrapper .side_menu .get_info li .media .media-body p {
  margin-bottom: 0vh;
  margin-top: 0.5vh;
  font-size: 1.8vh;
}

.side_menu_wrapper .side_menu .get_info li + li {
  margin-top: 2.9vh;
}

.side_menu_wrapper .side_menu .m_footer {
  position: absolute;
  left: 18%;
  bottom: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.side_menu_wrapper .side_menu .m_footer li a {
  font-size: 20px;
  color: #959ebb;
  font-size: 16px;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #959ebb;
  display: inline-block;
  margin: 0px 5px;
  line-height: 50px;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.side_menu_wrapper .side_menu .m_footer li a:hover {
  background: #5f28fb;
  border-color: #5f28fb;
  color: #fff;
  -webkit-box-shadow: 0px 20px 20px 0px rgba(95, 40, 251, 0.3);
  box-shadow: 0px 20px 20px 0px rgba(95, 40, 251, 0.3);
}

.menu-is-opened .side_menu_wrapper {
  z-index: 100;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transition: -webkit-transform 0.7s ease 0.2s;
  -webkit-transition: -webkit-transform 0.7s ease 0.2s;
  -o-transition: transform 0.7s ease 0.2s;
  transition: transform 0.7s ease 0.2s;
  transition: transform 0.7s ease 0.2s, -webkit-transform 0.7s ease 0.2s;
}

.menu-is-opened .side_menu_wrapper .side_menu {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.5s 0.6s linear;
  transition: -webkit-transform 0.5s 0.6s linear;
  -o-transition: transform 0.5s 0.6s linear;
  transition: transform 0.5s 0.6s linear;
  transition: transform 0.5s 0.6s linear, -webkit-transform 0.5s 0.6s linear;
}

/*================= menu_scroll css ================*/
.menu_scroll .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

/*====================================================*/
/*====================================================*/
/*================= saas_home_area css ===============*/
.saas_home_area {
  position: relative;
  background: #fafafe;
  height: 1100px;
}

.banner_top {
  padding-top: 210px;
}

.banner_top h2 {
  color: #051441;
  margin-bottom: 25px;
}

.banner_top p {
  margin-bottom: 42px;
}

.banner_top .subcribes {
  max-width: 630px;
  margin: 0 auto;
}

.banner_top .subcribes .form-control {
  border: 1px solid #e9ebf4;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 30px 30px 0px rgba(0, 11, 40, 0.1);
  font: 400 15px/46px "Poppins", sans-serif;
  padding-left: 30px;
  color: #051441;
  z-index: 0;
}

.banner_top .subcribes .form-control.placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control:-moz-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control::-moz-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .form-control::-webkit-input-placeholder {
  color: #b4b9c8;
}

.banner_top .subcribes .btn_submit {
  position: absolute;
  right: 8px;
  color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 11px 32px;
}

.saas_home_img {
  text-align: center;
  margin-top: 120px;
  position: relative;
}

.saas_home_img img {
  -webkit-box-shadow: 0px 40px 60px 0px rgba(0, 11, 40, 0.16);
  box-shadow: 0px 40px 60px 0px rgba(0, 11, 40, 0.16);
  border-radius: 8px;
}

/*============== slider_area css ==============*/
.slider_area {
  background-image: -moz-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  background-image: -webkit-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  background-image: -ms-linear-gradient(40deg, #1786d8 0%, #00aff0 100%);
  height: 930px;
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.slider_area .bottom_shoape {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  width: 100%;
}

.slider_area .middle_shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.leaf {
  position: absolute;
}

.leaf.l_left {
  top: 200px;
  left: 260px;
}

.leaf.l_right {
  right: 0;
  bottom: 100px;
}

.mobile_img {
  position: relative;
}

.mobile_img .img {
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.mobile_img .mobile {
  z-index: -1;
  position: relative;
}

.mobile_img .women_img {
  top: 245px;
  right: 160px;
  z-index: 1;
}

.slider_content {
  padding-top: 200px;
}

.slider_content h2,
.slider_content p {
  color: #fff;
}

@-webkit-keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*=============== prototype_banner_area css ==============*/
.prototype_banner_area {
  background: #fbfbfd;
  padding-top: 220px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.prototype_content .banner_subscribe .subcribes {
  display: block;
}

.prototype_content .banner_subscribe .subcribes .form-control {
  max-width: 370px;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  border: 0px;
  height: 50px;
  color: #222d39;
  padding-left: 30px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.06);
  font: 300 14px/28px "Poppins", sans-serif;
  border: 1px solid transparent;
}

.prototype_content .banner_subscribe .subcribes .form-control.placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control:-moz-placeholder {
  color: #aeb4ba;
}

.prototype_content
  .banner_subscribe
  .subcribes
  .form-control::-moz-placeholder {
  color: #aeb4ba;
}

.prototype_content
  .banner_subscribe
  .subcribes
  .form-control::-webkit-input-placeholder {
  color: #aeb4ba;
}

.prototype_content .banner_subscribe .subcribes .form-control:focus {
  border-color: #6754e2;
}

.partner_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 100px;
  border-bottom: 1px solid #eeeef5;
  padding-bottom: 35px;
  position: relative;
  z-index: 4;
}

.partner_logo .p_logo_item {
  width: calc(100% / 5);
  text-align: center;
}

.partner_logo .p_logo_item img {
  max-width: 100%;
  -webkit-filter: contrast(0.3%);
  filter: contrast(0.3%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.partner_logo .p_logo_item:hover img {
  -webkit-filter: contrast(100%);
  filter: contrast(100%);
}

.viewbtn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000029;
  border: 1px solid #ffffff;
  border-radius: 40px;
  padding: 6px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  margin-left: 550px;
}

.my-prod {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50%;
  cursor: pointer;
}
.my-prod-signin {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 5px 20px 5px 20px;
}

.my-prod img {
  padding: 10px 8px 10px 9px;
  cursor: pointer;
}

.my-prod-menu-txt {
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 7px;
}

/*================ software_banner_area css ================*/
.software_banner_area {
  background: url("../img/web/web-automation-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.api_automation_banner_area {
  background: url("../img/api/api-automation-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.mobile_automation_banner_area {
  background: url("../img/mobile/mobile-automation-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.defect_management_banner_area {
  background: url("../img/defect-management/defect-management-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.manual_test_case_banner_area {
  background: url("../img/manual-test-case-management/manual-test-case-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.manual_test-case_exe {
  background: url("../img/manual-test-case-management/test-exe-report.jpg");
  background-position: center;
  background-size: cover;
  height: 430px;
}

.manual_test-case_exe p {
  text-align: center;

  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
}

.manual_test-case_exe h2 {
  text-align: center;
  font: normal normal medium 45px/68px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 100px;
}

.home_logo_height {
  height: 20px;
}

.defect_management_text_margin {
  margin-top: -50px;
}

.web_locator_banner_area {
  background: url("../img/web-locator-finder/web-locator-bg-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
  /* height: 700px; */
}

.mobile_locator_banner_area {
  background: url("../img/mobile-locator-finder/mobile-locator-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.comprehensive h2 {
  text-align: center;
  font-size: 35px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
}

.comprehensive h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
}

.comprehensive img {
  margin-top: 30px;
}

.comprehendive_border {
  border: 1px solid #434db8;
  margin-left: -10px;
}

.msdynamics_banner_area {
  background: url("../img/msdyamics-locator-finder/msdynamics-locator-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.software_banner_content h1 {
  text-align: left;
  font-size: 35px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 100px;
}

.software_banner_content h2 {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.software_banner_content p {
  text-align: left;
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.software_img {
  margin-top: 120px;
}

.platform_banner_line {
  border: 3px solid #db7221;
  width: 60px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.quoteleft {
  margin-top: -16px;
  margin-right: 6px;
}

.quoteright {
  margin-top: 16px;
  margin-left: 6px;
}

/*========= FireCrowd Page*/
.firecrowd_banner {
  background: url("../img/firecrowd/fire-crowd-background-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 720px;
}

.live_report_crowd {
  background: url("../img/firecrowd/live-report-bg.png");
  background-position: center;
  background-size: cover;
}

.live_report_crowd h2 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 45px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 120px;
  margin-left: 119px;
}

.live_report_crowd h5 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 50px;
}

.live_report_crowd p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 80px;
}

.firecrowd_banner_content h1 {
  font: normal normal bold 35px/55px Poppins;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  margin-top: 120px;
}

.firecrowd_banner_content span {
  color: #db7221;
}

.firecrowd_banner_content p {
  text-align: center;
  font-size: 26px;
  font-family: poppins, medium;
  letter-spacing: 1px;
  font-weight: normal;
  color: #ffffff;
  opacity: 1;
}

.firecrowd_banner_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 10px;
  text-align: center;
  width: 298px;
  height: 56px;
  padding: 12px 25px 5px 25px;
  color: #ffffff;
  margin-left: 460px;
}

.firecrowd_banner_hover {
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 22px;
  font-family: "Poppins", semiBold;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/*========= FireFlink Home Page*/
.homebanner {
  background: url("../img/home/home.jpg");
  background-position: center;
  background-size: cover;
  height: 710px !important;
}

.home_banner_content h1 {
  font-size: 35px;
  font-family: Poppins, Bold;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  margin-top: 120px;
}

.home_banner_content span {
  color: #db7221;
}

.home_banner_content p {
  text-align: center;
  font-size: 20px;
  font-family: poppins, medium;
  letter-spacing: 0px;
  font-weight: normal;
  color: #ffffff;
  opacity: 1;
}

.home_banner_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  width: 298px;
  height: 56px;
  padding: 12px 25px 5px 25px;
  color: #ffffff;
}

.home_banner_btn:hover {
  color: #ffffff;
}

.home_banner_btn_hover {
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 22px;
  font-family: "Poppins", semiBold;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.home_video_icon {
  margin-top: -300px;
  text-align: center;
}

.home_video_icon svg {
  background: #646fe9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 36px #434db8b3;
  border: 6px solid #aebcf9;
  font-size: 50px;
  border-radius: 50%;
  color: #ffffff;
  padding-left: 3px;
  cursor: pointer;
}

.click-here {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 40px;
  padding: 6px;
  color: #ffff;
  opacity: 1;
}

.feat_content img {
  width: 23px;
  height: 23px;
  margin-right: 5px;
  margin-top: -6px;
}

.feat_content p {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  font-family: Poppins, Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-right: 10px;
  margin-top: -10px;
}

.feat_content span {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #db7221;
}

.sce_btn {
  display: flex;
  justify-content: center;
}

/*=====Pricing page========= */
.pricebanner {
  background-image: url("../img/home/contact-banner.png");
  background-position: center;
  background-size: cover;
  height: 800px;
  margin-bottom: 250px;
}

.price_banner_content h1 {
  font-size: 40px;
  font-weight: bold;
  font-family: Poppins, semiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
}

.price_banner_content p {
  text-align: center;

  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.price_banner_content {
  margin-top: 170px;
}

.price_banner_line {
  border: 4px solid #db7221;
  width: 65px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 100px;
}

.price_banner_content span {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #db7221;
}

.my_prod_txt {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #323232;
  margin-left: 45px;
  margin-top: 5px;
}
.my_prod_res {
  display: none;
}
.dashed_border {
  border-bottom-style: dashed;
  border-bottom-color: #d6d0d0;
}

/*====Contact Us Page*/
.contactbanner {
  background-image: url("../img/home/contact-banner.png");
  background-position: center;
  background-size: cover;
  height: 800px;
  margin-bottom: 380px;
}

.contact_banner_content h1 {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
}

.contact_banner_content p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.contact_banner_content {
  margin-top: 170px;
}

.contact_banner_line {
  border: 4px solid #db7221;
  width: 65px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 100px;
}

.contact_banner_content span {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #db7221;
}

.formbox,
.data_protect {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 10px #070d4b7a;
  border-radius: 16px 0px 0px 16px;
  width: 100%;
}

.contactinfo {
  background: #484db8;
  padding: 20px;
  border-radius: 0px 16px 16px 0px;
  box-shadow: 0px 0px 10px #070d4b7a;
  width: auto;
}

.contactinfo h2 {
  text-align: left;
  font-size: 24px;
  font-weight: medium;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.contactinfo_banner_line {
  border: 3px solid #db7221;
  width: 50px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.contactinfo p {
  text-align: left;
  font: normal normal 300 14px/21px Poppins;
  font-size: 14px;
  font-weight: 300;
  font-family: Poppins, Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 5px;
}

.contactinfo h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
}

.clickhere {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 45px;
  padding: 1px;
  width: fit-content;
  margin-left: 120px;
  margin-top: 20px;
  margin-bottom: 11px;
}

.clickhere p {
  text-align: center;
  font-size: 16px;
  font-weight: medium;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
  padding: 1px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.demo img {
  width: 60px;
  height: 60px;
  margin-left: 140px;
  margin-top: 10px;
}

.formbox h2 {
  text-align: left;

  font-family: Poppins, medium;
  font-size: 24px;
  letter-spacing: 0px;
  color: #363636;
  margin-bottom: 40px;
}

.send_btn {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  float: right;
  color: #ffffff;
  width: 115px;
  padding: 8px;
  padding-left: 15px;
}

.button_disable {
  opacity: 0.5;
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  /* margin-left: 592px; */
  color: #ffffff;
  width: 115px;
  padding: 8px;
  /* margin-top: 30px; */
  padding-left: 15px;
  /* margin-right: -30px; */
}

.btn_none {
  border: none;
  background: none;
  font-family: Poppins, medium;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.send_btn img {
  width: 20px;
  height: 20px;
  margin-top: 6px;
}

.service_desk {
  background-color: #e5e7f9;
  margin-left: -50px;
  height: 100%;
}

.email_support {
  background-color: #e0e2f9;
  margin-left: -30px;
  height: 100%;
  margin-right: -30px;
}

.phone_support {
  background-color: #dadcff;
  height: 100%;
  margin-right: -50px;
}

.need_help h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #000000;
}

.need_help p {
  text-align: center;
  font-size: 17px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: auto;
}

.need_help a {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #434db8;
}

.need_help_img {
  margin-top: 40px;
  margin-bottom: 20px;
}

.country_name {
  font-size: 17px;
  letter-spacing: 0px;
  color: #3c3838;
}

#exampleFormControlTextarea1::placeholder {
  font-size: 13px !important;
  opacity: 0.5 !important;
}

/*====Contact Us Page end*/
/*=======FF Platform Pricng Page Start=============*/
.pricingbanner {
  background: url("../img/price/pricing-banner.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.platformpricebox {
  background: #ffffff;
  box-shadow: 0px 0px 26px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding-top: 30px;
}

.prod_selection_content {
  padding: 33px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 30px;
}

.prod_selection_content h3 {
  text-align: left;
  font-size: 20px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #282828;
  opacity: 1;
}

.prod_selection_content p {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  line-height: normal;
  margin-top: -7px;
}

.prod_selection_content img {
  margin-top: -7px;
  margin-right: -7px;
}

.priceinfobox span {
  color: #07c93b;
  padding: 3px 8px;
}

.priceinfobox p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
  opacity: 1;
}

.priceinfobox h5 {
  font-size: 13px;
  font-family: Poppins, Regular;
  text-align: left;
}

.parellel {
  box-shadow: 0px 0px 6px #00000000;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  opacity: 1;
  padding: 13px;
  width: 280px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.pricebox {
  background: #434db8 0% 0% no-repeat padding-box;
  border-radius: 16px;
  padding: 20px;
  width: 280px;
  height: 220px;
  margin-left: 46px;
}

.pricebox img {
  width: 55px;
  height: 55px;
  margin-bottom: 10px;
}

.pricebox h4 {
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 10px;
}

.pricebox h3 {
  text-align: center;
  font-size: 23px;
  font-weight: medium;
  font-weight: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.pricebox span {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 23px;
  font-weight: normal;
  font-family: Poppins, Regular;
}

.priceinfobox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 20px;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: -45px;
}

.pricegetstart_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 35px;
  opacity: 1;
  padding: 5px;
  width: 280px;
  margin-left: 6px;
  display: block;
  width: 280px !important;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0px;
  font-family: Poppins, SemiBold;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  font-weight: 400;
}

.pricegetstart_btn:hover {
  color: #ffffff;
}

.request_quote_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 35px;
  opacity: 1;
  padding: 5px;
  height: 10px !important;
  margin-left: 29px;
  color: #ffffff;
  font: normal normal medium 20px/38px Poppins;
  font-size: 17px;
  letter-spacing: 0px;
  display: inline-table;
  width: 200px;
  font-weight: medium;
  font-family: Poppins, Medium;
  margin-left: 45px;
  margin-left: 20px;
  font-weight: 400;
  text-align: center;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.request_quote_btn:hover {
  color: #ffffff;
}

.support {
  padding: 100px;
  margin-left: 20px;
  text-align: center;
}

.support h3 {
  font: normal normal 600 20px/30px Poppins;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 15px;
}

.annualtab {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 450px;
  padding: 50px;
}

.annualtab h3 {
  background: #434db8 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  padding: 15px;
  text-align: left;
  font-size: 15px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 15px;
}

.pricefeature_list h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-bottom: 50px;
}

.pricefeature_list h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #282828;
  margin-top: -30px;
}

.feature_content {
  padding: 20px;
  margin-top: 100px;
}

.feature_content p {
  text-align: left;
  margin-bottom: 24px;
  font-family: Poppins, Light;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #282828;
  opacity: 1;
  margin-right: -1px;
}

.pricecircle {
  border: 2px solid red;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  padding: -2px;
  font-size: 16px;
  margin-left: 55px;
  color: red;
  margin-top: 24px;
}

.pricecircle_green {
  border: 2px solid green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  padding: -2px;
  font-size: 16px;
  margin-left: 78px;
  color: green;
  margin-top: 24px;
}

.price_name_box {
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  /* border: 1px solid #434DB8; */
  border-radius: 8px;
  opacity: 1;
  padding: 3px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
}

.pricing_name_box_background {
  background: #f5f7ff;
  padding: 50px;
  margin-left: -140px;
}

.enterprice_name_box_background {
  background: #f5f7ff;
}

.professional_box {
  margin-left: 100px;
}

.professional_box h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #282828;
  margin-top: -30px;
  margin-top: 20px;
  margin-left: -50px;
  margin-right: 50px;
}

.enterprice_name_box_background h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #282828;
  margin-top: -30px;
  margin-top: 20px;
  margin-left: -50px;
  margin-right: 50px;
}

.first_cross_mark {
  margin-left: -95px;
  margin-top: 50px;
}

.second_cross_mark {
  margin-left: -94px;
}

.first_tick_mark {
  margin-left: -40px;
  margin-top: 50px;
}

.second_tick_mark {
  margin-left: -40px;
  margin-top: 13px;
}

.third_tick_mark {
  margin-top: 50px;
}

.third_sec_tick_mark {
  margin-top: 13px;
}

.greyline {
  border: 1px solid #eee;
  width: 95%;
  margin-left: 30px;
}

.prod_selection_content:hover {
  background: #fcfdff 0% 0% no-repeat padding-box;
  border: 1px solid #434db8;
  border-radius: 16px;
  width: 320px;
  height: 120px;
  margin-top: 30px;
  margin-top: 30px;
}

.ffplatform {
  background: #434db8;
  border: 1px solid #434db8;
  border-radius: 16px;
  opacity: 1;
  width: 320px;
  height: 120px;
  margin-left: 27px;

  margin-top: 30px;
  color: #ffffff;
  padding: 30px;
}

.ffplatform h3 {
  text-align: left;
  font-size: 20px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ffplatform p {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  line-height: normal;
  margin-top: -7px;
}

.ffplatform img {
  margin-top: -7px;
  margin-right: -7px;
  color: white;
}

.platformfaq {
  background: #ffffff;
  box-shadow: 0px 0px 4px #434db861;
  border-radius: 16px;
  opacity: 1;
  padding: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.platformfaq_back {
  background: #e5eaff3d;
  border-radius: 16px;
  opacity: 1;
  margin-left: 6px;
  margin-right: 6px;
  padding-top: 160px;
}

.platformfaq_back h2 {
  text-align: center;
  font: normal normal 600 40px/56px Poppins;
  font-size: 40px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-top: -100px;
  margin-bottom: 30px;
}

.head_line {
  border: 3px solid #db7221;
  border-radius: 5px;
  opacity: 1;
  width: 50px;
  margin-bottom: 30px;
}

.regression_testing_headline {
  border: 4px solid #db7221;
  border-radius: 5px;
  opacity: 1;
  width: 50px;
  margin-left: 340px;
  margin-bottom: 30px;
}

.platformfaq p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 20px;
  letter-spacing: 0px;
  color: #282828;
  margin-top: 80px;
  margin-bottom: -30px;
}

.platformfaq span {
  text-align: center;
  font: normal normal normal 20px/24px Poppins, Regular;
  letter-spacing: 0px;
  color: #1592e6;
}

.platformfaq p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 20px;
  letter-spacing: 0px;
  color: #282828;
  margin-top: 80px;
  margin-bottom: -30px;
}

.platformfaq span {
  text-align: center;
  font: normal normal normal 20px/24px Poppins, Regular;
  letter-spacing: 0px;
  color: #1592e6;
}

/*=======FF Platform Pricng Page End*===============/

/*============= app_banner_area css ===========*/
.app_banner_area {
  background: url(../img/home7/banner.png) no-repeat scroll center 100%;
  padding-top: 230px;
  padding-bottom: 210px;
  overflow: hidden;
  background-size: cover;
}

.app_banner_area .app_img .app_screen {
  position: absolute;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: animateUpDown;
  animation-name: animateUpDown;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.app_banner_area .app_img .app_screen.one {
  top: 135px;
  left: 110px;
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
}

.app_banner_area .app_img .app_screen.two {
  top: 46px;
  left: 220px;
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.app_banner_area .app_img .app_screen.three {
  top: 6px;
  left: 375px;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
}

.app_banner_contentmt h2 {
  line-height: 64px;
}

.app_banner_contentmt .app_btn {
  border: 0px;
  background: #fff;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #4069eb;
  padding: 14px 40px;
}

.app_banner_contentmt .app_btn:hover {
  border-color: #4069eb;
  background: #4069eb;
  color: #fff;
}

/*================ agency_banner_area_two css ===============*/

/*============ keyframes animation  =============*/
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

/*================= Start breadcrumb_area css =================*/
.breadcrumb_area {
  background: url("../img/breadcrumb/case-studies.png");
  position: relative;
  z-index: 1;
  padding: 160px 0px 125px;
  overflow: hidden;
  height: 380px;
}

.breadcrumb {
  background-color: #f5f5ff !important;
}

.breadcrumb-item.active {
  color: #434db8;
}

.video_breadcrumb a {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #616161;
  cursor: default;
  pointer-events: none;
}

.video_breadcrumb li {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #434db8;
  margin-left: 10px;
}

.blog_area {
  padding: 120px;
}

.breadcrumb_area .breadcrumb_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.breadcrumb_area p {
  text-align: center;

  font-size: 17px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}

.blog_home_banner {
  background: url("../img/blogs/blog-banner.jpg");
  height: 320px !important;
}

.movefwd_blog_banner {
  background: url("../img/blogs/moving.jpg");
}

.better_blog_banner {
  background: url("../img/blogs/which-is-better.jpg");
}

.ecomm_banner {
  background: url("../img/case-study/case-banner/e-comm.jpg");
}

.insurance_banner {
  background: url("../img/case-study/case-banner/insurance.jpg");
}

.health_banner {
  background: url("../img/case-study/case-banner/health-care.jpg");
}

.edtech_banner {
  background: url("../img/case-study/case-banner/e-learn.jpg");
}

.media_banner {
  background: url("../img/case-study/case-banner/media.jpg");
}

.trading_banner {
  background: url("../img/case-study/case-banner/trading.jpg");
}

.case_banner {
  background: url("../img/case-study/case-study-banner.jpg");
}

.backbg_css {
  background-position: center;
  background-size: cover;
  height: 380px;
  padding: 160px 0px 125px;
}

.roi_banner_img {
  background: url("../img/blogs/does-roi-benefit.jpg");
  background-position: center;
  background-size: cover;
}

.scalable_on_demand_banner {
  background: url("../img/blogs/scalable-on-demand-testing.jpg");
  background-position: center;
  background-size: cover;
}

.impact_of_ui_banner {
  background: url("../img/blogs/impact-of-ui.jpg");
  background-position: center;
  background-size: cover;
}

.streamlining_debugging {
  background: url("../img/blogs/streamlining-debugging.jpg");
  background-position: center;
  background-size: cover;
}

.enhancing_test_automation {
  background: url("../img/blogs/enhancing-test-automation.jpg");
  background-position: center;
  background-size: cover;
}

.essentials_of_end_to_end_testing {
  background: url("../img/blogs/essentials-of-end-to-end-testing.jpg");
  background-position: center;
  background-size: cover;
}

.mastering_unit_testing {
  background: url("../img/blogs/mastering-unit-testing.jpg");
  background-position: center;
  background-size: cover;
}

.best_practices_in_end_to_end_testing {
  background: url("../img/blogs/best-practices-in-end-to-end-testing.jpg");
  background-position: center;
  background-size: cover;
}

.end_to_end_testing {
  background: url("../img/blogs/end-to-end-testing.jpg");
  background-position: center;
  background-size: cover;
}

.mastering_end_to_end_testing {
  background: url("../img/blogs/mastering-end-to-end-testing.jpg");
  background-position: center;
  background-size: cover;
}

.optimising_testing {
  background: url("../img/blogs/optimising-testing.jpg");
  background-position: center;
  background-size: cover;
}

.common_unit_testing_pitfalls {
  background: url("../img/blogs/common-unit-testing-pitfalls.jpg");
  background-position: center;
  background-size: cover;
}

.crucial_role_of_unit_testing {
  background: url("../img/blogs/crucial-role-of-unit-testing.jpg");
  background-position: center;
  background-size: cover;
}

.step_tagging {
  background: url("../img/blogs/step-tagging.jpg");
  background-position: center;
  background-size: cover;
}

.simplifying_test_automation {
  background: url("../img/blogs/simplifying-test-automation.jpg");
  background-position: center;
  background-size: cover;
}

.elevating_test_automation {
  background: url("../img/blogs/seamless-integration.jpg");
  background-position: center;
  background-size: cover;
}

.simplifying_regression_testing {
  background: url("../img/blogs/simplifying-regression-testing.jpg");
  background-position: center;
  background-size: cover;
}

.simplifying_api_testing {
  background: url("../img/blogs/simplifying-api-testing.jpg");
  background-position: center;
  background-size: cover;
}

.challenges_faced_by_qa {
  background: url("../img/blogs/challenges-faced-by-qa.jpg");
  background-position: center;
  background-size: cover;
}

.mobile_testing_simplified {
  background: url("../img/blogs/mobile-testing-simplified.jpg");
  background-position: center;
  background-size: cover;
}

.power_of_no_code {
  background: url("../img/blogs/power-of-no-code.jpg");
  background-position: center;
  background-size: cover;
}

.blogs_img {
  height: 86%;
  width: auto;
  margin-left: 150px;
}

/*================= End breadcrumb_area css =================*/
/*================= Start breadcrumb_area_two css =================*/
.breadcrumb_area_two {
  background: #f8f6fe;
  position: relative;
  padding: 235px 0px 125px;
  z-index: 1;
}

.breadcrumb_area_two .breadcrumb_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  height: 100%;
}

.breadcrumb_area_two .bubble li {
  background: #5e2ced;
  opacity: 0.1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
}

.breadcrumb_area_two .bubble li:nth-child(1) {
  left: 220px;
  bottom: 50px;
  -webkit-animation: spin3 2s infinite alternate;
  animation: spin3 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(2) {
  left: 35%;
  top: 100px;
  -webkit-animation: spin 2s infinite alternate;
  animation: spin 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(3) {
  left: 40%;
  width: 51px;
  height: 51px;
  bottom: 50px;
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(4) {
  left: 42%;
  bottom: 50px;
  width: 20px;
  height: 20px;
}

.breadcrumb_area_two .bubble li:nth-child(5) {
  left: 76%;
  width: 51px;
  height: 51px;
  top: 160px;
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(6) {
  left: 75%;
  width: 30px;
  height: 30px;
  top: 186px;
}

.breadcrumb_area_two .bubble li:nth-child(7) {
  left: 52%;
  top: 150px;
  width: 20px;
  height: 20px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.breadcrumb_area_two .bubble li:nth-child(8) {
  left: 90%;
  top: 250px;
  width: 20px;
  height: 20px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate;
}

.breadcrumb_content_two h1 {
  color: #222d39;
  margin-bottom: 12px;
}

.breadcrumb_content_two .breadcrumb {
  background: transparent;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.breadcrumb_content_two .breadcrumb li {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
  color: #525c7c;
}

.breadcrumb_content_two .breadcrumb li a {
  color: #051441;
}

.breadcrumb_content_two .breadcrumb li + li {
  padding-left: 32px;
}

.breadcrumb_content_two .breadcrumb li + li:after {
  content: "\e649";
  font-family: "themify";
  position: absolute;
  left: 8px;
  top: 0;
  font-size: 14px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translateX(-10px) scale(0.9);
    transform: translateX(-10px) scale(0.9);
  }

  100% {
    -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translateX(-10px) scale(0.9);
    transform: translateX(-10px) scale(0.9);
  }

  100% {
    -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@-webkit-keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@keyframes spin3 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

/*================= End breadcrumb_area_two css =================*/
/*========= blog_breadcrumb_area css ========*/
.blog_breadcrumb_area {
  position: relative;
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.breadcrumb_content h1 {
  font-size: 35px;
  font-weight: 600;
  font-family: Poppins, Bold;
  text-align: center;
}

.blog_breadcrumb_area .background_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: -moz-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  background-image: -webkit-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  background-image: -ms-linear-gradient(-140deg, #5e2ced 0%, #8037da 100%);
  opacity: 0.9;
  z-index: -1;
}

.blog_breadcrumb_area .breadcrumb_content_two {
  color: #fff;
  padding-top: 80px;
}

.blog_breadcrumb_area .breadcrumb_content_two h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 18px;
  color: #fff;
}

.blog_breadcrumb_area .breadcrumb_content_two h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.25em;
  color: #fff;
  margin-bottom: 25px;
}

.blog_breadcrumb_area .breadcrumb_content_two ol {
  margin-bottom: 0;
}

.blog_breadcrumb_area .breadcrumb_content_two ol li {
  display: inline-block;
  font-size: 16px;
}

.blog_breadcrumb_area .breadcrumb_content_two ol li + li:before {
  content: "\e649";
  display: inline-block;
  font-family: "themify";
  font-size: 10px;
  margin: 0px 5px 0px 3px;
}

/*========= blog_breadcrumb_area css ========*/

.learn_btn_two {
  color: #051441;
  font-size: 15px;
  display: inline-block;
  margin-top: 7px;
  position: relative;
  -webkit-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.learn_btn_two:before {
  content: "";
  height: 1px;
  width: 0;
  position: absolute;
  left: 0;
  background: #fff;
  bottom: 0;
  -webkit-transition: width 0.2s ease;
  -o-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.learn_btn_two i {
  font-size: 13px;
  padding-left: 5px;
  vertical-align: middle;
}

.learn_btn_two:hover {
  color: #fff;
}

.learn_btn_two:hover:before {
  width: 100%;
}

.learn_btn_two:hover i {
  padding-left: 10px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.service_promo_area_two {
  margin-top: -140px;
  padding-bottom: 120px;
}

/*=============== s_features_section css ================*/

.learn_btn {
  font: 500 16px/25px "Poppins", sans-serif;
  color: #051441;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  margin-top: 30px;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.learn_btn i {
  padding-left: 8px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.learn_btn:before {
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 1px;
  background: #051441;
  position: absolute;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}

.learn_btn:hover {
  color: #7fcb11;
}

.learn_btn:hover:before {
  background: #7fcb11;
}

.learn_btn:hover i {
  padding-left: 15px;
}

/*============= software_promo_area css ============*/
/*============= prototype_featured_area css ===============*/
.prototype_featured_area {
  background: #f4f4f9;
  position: relative;
  z-index: 1;
}

/*============== agency_featured_area css =============*/

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 161, 39, 0.161);
  display: block;
  position: absolute;
  left: -9px;
  top: 15px;
  z-index: 1;
}

.dot .dot1 {
  position: absolute;
  left: 50%;
  margin-top: -4px;
  margin-left: -4px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #feb85d;
}

.dot .dot2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(254, 184, 93, 0.8);
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  will-change: transform;
}

.dot.middle_dot {
  left: 54.8%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  top: auto;
}

.agency_featured_item {
  margin-top: 130px;
}

.agency_featured_item .agency_featured_content {
  position: relative;
}

.agency_featured_item .agency_featured_content h3 {
  font: 500 26px/36px "Poppins", sans-serif;
  color: #222d39;
  margin: 32px 0px 25px;
}

.agency_featured_item .agency_featured_content p {
  font-size: 15px;
}

.agency_featured_item .agency_featured_content .icon {
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(94, 44, 237, 0.2);
  font-size: 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
}

.agency_featured_item.agency_featured_item_two .agency_featured_content .dot {
  left: 30px;
}

/*=============== software_featured_area css ===============*/
.software_featured_area {
  padding-top: 120px;
}

.software_featured_item .s_icon {
  display: inline-block;
  position: relative;
}

.software_featured_item .s_icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -22px;
}

.software_featured_item h3 {
  font-size: 17px;
  margin: 30px 0px 25px;
}

.software_featured_item .learn_btn {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #222d39;
  position: relative;
  margin-top: 0px;
}

.software_featured_item .learn_btn:before {
  content: "";
  width: 0;
  height: 1px;
  left: auto;
  right: 0;
  bottom: 0;
  background: #3d64f4;
  position: absolute;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.software_featured_item .learn_btn:hover {
  color: #3d64f4;
}

.software_featured_item .learn_btn:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

/*=============== software_featured_area_two css ==================*/
.software_featured_area_two {
  background: url(../img/web/scriptless-automation-tool.jpg);
  background-size: cover;
  background-position: center;
}

.mobilescriptless {
  background-image: url(../img/mobile/scriptless-mobile-automation-tool.jpg);
  background-size: cover;
  background-position: center;
}

.software_featured_img {
  margin-left: -30px;
}

.software_featured_content .btn_four {
  border: 0px;
  color: #3d64f4;
  background: #fff;
}

.software_featured_content .btn_four:hover {
  color: #fff;
  background: #3d64f4;
}

.software_featured_content h2 {
  font-size: 45px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 40px;
}

.software_featured_content p {
  font-size: 17px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.software_featured_content {
  margin-left: 70px;
}

/*=============== saas_featured_area css ==============*/
/*=============== app_featured_area css ===============*/

.triangle_shape {
  position: absolute;
  z-index: -2;
  height: 161%;
  width: 149%;
  background: #fbfbfd;
  bottom: -290px;
  right: -47%;
  -webkit-transform: rotate(-36deg);
  -ms-transform: rotate(-36deg);
  transform: rotate(-36deg);
}

.app_img {
  position: relative;
  display: inline-block;
  z-index: 0;
}

.app_img .dot {
  background: rgba(64, 105, 235, 0.161);
}

.app_img .dot .dot1 {
  background: #6c8bed;
}

.app_img .dot .dot2 {
  background: rgba(64, 105, 235, 0.8);
}

.app_img .dot_one {
  right: 75px;
  top: 35px;
  left: auto;
}

.app_img .dot_two {
  right: 100px;
  top: 67%;
  left: auto;
}

.app_img .dot_three {
  top: 38%;
  left: 105px;
}

.app_img .text_bg {
  position: absolute;
  z-index: 1;
}

.app_img .text_bg.one {
  left: -50%;
  top: 190px;
}

.app_img .text_bg.two {
  right: -34%;
  top: -50px;
}

.app_img .text_bg.three {
  right: -50%;
  bottom: 100px;
}

/*=========== startup_fuatures_area css ==========*/
.startup_tab {
  border: 0px;
  margin-bottom: 50px;
}

.startup_tab .nav-item {
  width: calc(100% / 5);
  padding: 0px 15px;
  margin: 0px;
  text-align: center;
}

.startup_tab .nav-item .nav-link {
  border: 0px;
  background: #fbfbfd;
  padding: 32px 0px;
  border-radius: 4px;
  border: 2px solid transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.startup_tab .nav-item .nav-link .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  line-height: 72px;
  color: #fff;
  margin-bottom: 15px;
}

.startup_tab .nav-item .nav-link h3 {
  font-size: 18px;
  line-height: 26px;
  color: #051441;
  font-weight: 400;
  white-space: pre-line;
  margin-bottom: 0;
}

.startup_tab .nav-item .nav-link.active {
  border-color: #6fadfa;
  background: #fff;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(29, 7, 90, 0.08);
  box-shadow: 0px 30px 40px 0px rgba(29, 7, 90, 0.08);
}

.startup_tab .nav-item:nth-child(1) .icon {
  background: #fa6fd1;
}

.startup_tab .nav-item:nth-child(1) .nav-link.active {
  border-color: #fa6fd1;
}

.startup_tab .nav-item:nth-child(2) .icon {
  background: #6fadfa;
}

.startup_tab .nav-item:nth-child(2) .nav-link.active {
  border-color: #6fadfa;
}

.startup_tab .nav-item:nth-child(3) .icon {
  background: #f3af4e;
}

.startup_tab .nav-item:nth-child(3) .nav-link.active {
  border-color: #f3af4e;
}

.startup_tab .nav-item:nth-child(4) .icon {
  background: #aa6ffa;
}

.startup_tab .nav-item:nth-child(4) .nav-link.active {
  border-color: #aa6ffa;
}

.startup_tab .nav-item:nth-child(5) .icon {
  background: #fa6666;
}

.startup_tab .nav-item:nth-child(5) .nav-link.active {
  border-color: #fa6666;
}

.startup_tab_content .show .startup_tab_img .phone_img,
.startup_tab_content .show .startup_tab_img .web_img {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.startup_tab_img {
  position: relative;
  /* padding-top: 40px; */
  padding-bottom: 64px;
  z-index: 1;
}

.startup_tab_img:before {
  content: "";
  height: 100%;
  background: url("../img/new/tab_shape_bg.png") no-repeat scroll center 0 /
    contain;
  top: 0px;
  right: -55px;
  left: -55px;
  position: absolute;
  z-index: -1;
}

.startup_tab_img .web_img {
  display: inline-block;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(0, 11, 40, 0.14);
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.web_img img {
  width: 100%;
}

.startup_tab_img .phone_img {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/*=========== service_area css ============*/
.service_area {
  background: #f4f6fa;
}

.service_item {
  background: #fff;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
  box-shadow: 0px 30px 40px 0px rgba(3, 115, 156, 0.1);
  padding: 47px 40px 0px;
  height: 100%;
  position: relative;
  border: 2px solid transparent;
  -webkit-transition: border 0.2s linear;
  -o-transition: border 0.2s linear;
  transition: border 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}

.service_item p {
  line-height: 28px;
}

.service_item .icon {
  width: 54px;
  height: 54px;
  line-height: 54px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  box-shadow: 0px 14px 30px 0px rgba(94, 44, 237, 0.4);
  position: absolute;
  right: 30px;
  top: 30px;
}

.service_item .icon.s_icon_one {
  background-image: -moz-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -webkit-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: -ms-linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
  box-shadow: 0px 14px 30px 0px rgba(94, 44, 237, 0.4);
}

.service_item .icon.s_icon_two {
  background-image: -moz-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -webkit-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: -ms-linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  background-image: linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(224, 56, 39, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(224, 56, 39, 0.4);
}

.service_item .icon.s_icon_three {
  background-image: -moz-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -webkit-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: -ms-linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  background-image: linear-gradient(40deg, #2c82ed 0%, #38d0fc 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(44, 130, 237, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(44, 130, 237, 0.4);
}

.service_item .icon.s_icon_four {
  background-image: -moz-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: -webkit-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: -ms-linear-gradient(40deg, #e09520 0%, #fae926 100%);
  background-image: linear-gradient(40deg, #e09520 0%, #fae926 100%);
  -webkit-box-shadow: 0px 14px 30px 0px rgba(224, 149, 32, 0.4);
  box-shadow: 0px 14px 30px 0px rgba(224, 149, 32, 0.4);
}

.service_item img {
  margin-right: -25px;
  width: auto;
}

.service_item:hover {
  border-color: #c4e7f7;
}

/*================ prototype_service_info css ==============*/
.prototype_service_area_two,
.prototype_service_area_three {
  overflow: hidden;
}

.prototype_service_info {
  padding: 50px 0px 175px;
  position: relative;
  z-index: 0;
}

.prototype_service_info h2 {
  font-size: 35px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
}

.p_service_info {
  margin-bottom: -90px;
}

.p_service_item {
  margin-bottom: 40px;
}

.p_service_item .icon {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 55px;
  height: 60px;
  position: relative;
  margin: 0px -5px 15px;
  font-size: 24px;
  -webkit-border-radius: 50% / 14%;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 64px;
  -o-text-overflow: clip;
  text-overflow: clip;
  z-index: 1;
  background: transparent linear-gradient(144deg, #4c57cd 0%, #0b1582 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000061;
  opacity: 1;
}

.p_service_item .icon:before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  content: "";
  top: 10%;
  right: -5.5%;
  bottom: 10%;
  left: -5%;
  border: none;
  -webkit-border-radius: 7% / 50%;
  border-radius: 7% / 50%;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-shadow: none;
  z-index: -1;
}

.p_service_item .icon.icon_one {
  background: transparent linear-gradient(144deg, #4c57cd 0%, #0b1582 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000061;
  opacity: 1;
}

/* .p_service_item .icon.icon_one:before {
  background: #7142f8;
} */

.p_service_item .icon.icon_two {
  background: #5cbd2c;
}

.p_service_item .icon.icon_two:before {
  background: #5cbd2c;
}

.p_service_item .icon.icon_three {
  background: #f38650;
}

.p_service_item .icon.icon_three:before {
  background: #f38650;
}

.p_service_item .icon.icon_four {
  background: #ecbf24;
}

.p_service_item .icon.icon_four:before {
  background: #ecbf24;
}

.p_service_item .icon.icon_five {
  background: #fa4c9a;
}

.p_service_item .icon.icon_five:before {
  background: #fa4c9a;
}

.p_service_item .icon.icon_six {
  background: #35bfe7;
}

.p_service_item .icon.icon_six:before {
  background: #35bfe7;
}

.p_service_item h5 {
  font-size: 18px;
  color: #434db8;
  /* line-height: 22px;
  margin-bottom: 15px; */
}

.p_service_item p {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.symbols-pulse {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.symbols-pulse > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 23rem;
  height: 23rem;
  border-radius: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(227, 221, 246, 0.1)),
    color-stop(65%, rgba(227, 221, 246, 0.2)),
    to(rgba(227, 221, 246, 0.1))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  background-image: -o-linear-gradient(
    top,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  background-image: linear-gradient(
    -180deg,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  z-index: -1;
}

.symbols-pulse .pulse-1 {
  -webkit-transform: translate(-50%, -50%) scale(0.67);
  -ms-transform: translate(-50%, -50%) scale(0.67);
  transform: translate(-50%, -50%) scale(0.67);
}

.symbols-pulse .pulse-2 {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}

.symbols-pulse .pulse-3 {
  -webkit-transform: translate(-50%, -50%) scale(1.63);
  -ms-transform: translate(-50%, -50%) scale(1.63);
  transform: translate(-50%, -50%) scale(1.63);
}

.symbols-pulse .pulse-4 {
  -webkit-transform: translate(-50%, -50%) scale(2.1);
  -ms-transform: translate(-50%, -50%) scale(2.1);
  transform: translate(-50%, -50%) scale(2.1);
}

.symbols-pulse .pulse-x {
  will-change: transform;
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

/*================ prototype_service_area css =============*/
.prototype_service_area {
  background: #fbfbfd;
  padding-bottom: 130px;
  padding-top: 20px;
}

.service_carousel {
  padding-left: 155px;
  position: relative;
  z-index: 1;
}

.service_carousel:before {
  width: 100px;
  content: "";
  height: 100%;
  right: -25px;
  top: 0;
  position: absolute;
  background: #fbfbfb;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  -webkit-box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
  box-shadow: 0px 100px 40px 0px rgba(251, 251, 251, 0.1);
  z-index: 1;
}

.service_carousel .slick-list {
  padding-left: 0 !important;
}

.service_carousel .slick-slide > div {
  padding-left: 35px;
  padding-bottom: 55px;
  height: 100%;
}

.service_carousel .slick-slide .service_item {
  -webkit-box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 30px 40px 0px rgba(12, 0, 46, 0.1);
}

.service_carousel .slick-slide .service_item:hover {
  border-color: rgba(116, 68, 253, 0.2);
}

.service_carousel .slick-slide .service_item img {
  width: auto;
}

.service_carousel .slick-list .slick-track,
.service_carousel .slick-list {
  display: flex;
}

.service_carousel .slick-prev {
  display: none !important;
}

.service_carousel .slick-next {
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: white;
  -webkit-box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 20px 2px rgba(12, 0, 46, 0.06);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-indent: -999px;
}

.service_carousel .slick-next:before {
  content: "\e629";
  font-family: "themify";
  text-indent: 999px;
  position: absolute;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: 0;
  width: 100%;
  font-size: 16px;
  color: #222d39;
  line-height: 56px;
}

.service_carousel .slick-next:hover {
  background: #7444fd !important;
}

.service_carousel .slick-next:hover:before {
  color: #fff !important;
}

.prototype_service_area_two {
  background: #f8f8fc;
  padding: 120px 0px 60px;
  position: relative;
  z-index: 1;
}

/*============= agency_service_area css =============*/
.agency_service_area {
  padding: 135px 0px 140px;
}

.agency_service_item {
  margin-bottom: 30px;
}

.agency_service_item .icon {
  width: auto;
  height: auto;
  display: inline-block;
}

.agency_service_item .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.agency_service_item a {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #222d39;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-transition: color 0.3s linear 0s;
  -o-transition: color 0.3s linear 0s;
  transition: color 0.3s linear 0s;
}

.agency_service_item a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #222d39;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  -webkit-transition: width 0.3s linear 0s;
  -o-transition: width 0.3s linear 0s;
  transition: width 0.3s linear 0s;
}

.agency_service_item p {
  display: inline-block;
}

.agency_service_item p i {
  vertical-align: middle;
  padding-left: 8px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.agency_service_item p:hover a {
  color: #5e2ced;
}

.agency_service_item p:hover a:before {
  width: 132%;
  background: #5e2ced;
}

.agency_service_item p:hover i {
  color: #5e2ced;
  padding-left: 12px;
}

/*============== software_service_area css =============*/
.software_service_area {
  background: #f9f9fc;
}

.software_service_tab {
  margin: 0px;
  border: 0px;
  display: flex;
  justify-content: space-between;
}

.software_service_tab .nav-item {
  margin: 0px;
}

.software_service_tab .nav-item .nav-link {
  display: block;
  padding: 16px 32px 16px 40px;
  -webkit-transition: border-left-color 0.25s linear;
  -o-transition: border-left-color 0.25s linear;
  transition: border-left-color 0.25s linear;
  border-radius: 0px;
  border: 0px;
  border-left: 4px solid #e6e8ef;
  background: transparent;
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  position: relative;
}

.software_service_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 8px;
  border-color: transparent transparent transparent #3d64f4;
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.software_service_tab .nav-item .nav-link.active {
  border-left-color: #3d64f4;
  color: #3d64f4;
}

.software_service_tab .nav-item .nav-link.active:before {
  opacity: 1;
}

.software_service_tab_content {
  margin-bottom: -70px;
}

.software_service_tab_content .software_service_item {
  padding-right: 30px;
}

.software_service_tab_content .software_service_item i {
  font-size: 30px;
  color: #3d64f4;
  line-height: 40px;
}

.software_service_tab_content .software_service_item h5 {
  font: 600 17px/25px "Poppins", sans-serif;
  color: #222d39;
}

.software_service_tab_content .software_service_item p {
  font-size: 15px;
}

.flexcnt {
  display: flex;
  justify-content: space-between;
}

.flexbox {
  width: 100%;
}

.flexbox p {
  line-height: initial;
  margin-top: -4px;
}

.icon1 {
  width: 25%;
  height: 50%;
  border-radius: 10%;
  background-color: #484cbc;
  box-shadow: 0px 20px 30px 0px rgb(16 33 234 / 18%);
  text-align: center;
  font-size: 20px;
  line-height: 52px;
  color: #fff;
  margin-right: 20px;
  display: block;
}

.flexfeature {
  display: flex;
  margin-left: -20px;
  margin-top: 10px;
}

/*============= stratup_service_area css =============*/
.stratup_service_img {
  position: relative;
  padding-top: 20px;
  z-index: 1;
}

.stratup_service_img .shape {
  position: absolute;
  left: -37px;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../img/new/new_shape.png) no-repeat scroll left 0;
  z-index: -1;
  background-size: contain;
}

.stratup_service_img .phone_img {
  position: absolute;
  left: 0;
  bottom: 0;
}

.stratup_service_info {
  margin-left: -15px;
}

.stratup_service_info .startup_service_item {
  position: relative;
  padding-left: 75px;
}

.stratup_service_info .startup_service_item .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  color: #fff;
  background: #6fadfa;
  line-height: 65px;
  position: absolute;
  left: 0;
  top: -5px;
}

.stratup_service_info .startup_service_item .icon.icon_two {
  background: #f3af4e;
}

.stratup_service_info .startup_service_item .icon.icon_three {
  background: #fa6fd1;
}

.stratup_service_info .startup_service_item .icon.icon_four {
  background: #fa6666;
}

.stratup_service_info .startup_service_item h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #051441;
  margin-bottom: 2px;
}

.stratup_service_info .startup_service_item p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

.stratup_service_info .startup_service_item + .startup_service_item {
  margin-top: 40px;
}

/*============= stratup_service_area css =============*/

/*============= seo_service_area css =============*/
.seo_service_item {
  background-color: white;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  padding: 60px 40px 38px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.seo_service_item h4 {
  font-size: 20px;
  line-height: 28px;
  color: #263b5e;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 16px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item h4:hover {
  color: #5e2ced;
}

.seo_service_item p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 30px;
  color: #6a7695;
  margin-bottom: 40px;
}

.seo_service_item i {
  font-size: 40px;
  color: #afb8ce;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item i:hover {
  color: #5e2ced;
}

.seo_service_item:hover {
  -webkit-box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
  box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
}

/*====================================================*/
/*================= pricing_area css ==============*/
.pricing_area {
  background: #fbfbfd;
}

.price_tab {
  border-radius: 45px;
  max-width: 400px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 13px 0px rgba(0, 11, 40, 0.08);
  box-shadow: 0px 3px 13px 0px rgba(0, 11, 40, 0.08);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  border: 0px;
  padding: 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.price_tab .nav-item {
  padding: 0px;
  margin: 0px;
}

.price_tab .nav-item .nav-link {
  margin: 0px;
  font: 400 16px "Poppins", sans-serif;
  color: #677294;
  padding: 12px 30px;
  display: inline-block;
  border: 0px;
  border-radius: 45px;
  min-width: 193px;
  text-align: center;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  position: relative;
  z-index: 1;
}

.price_tab .nav-item .nav-link.active {
  border: 0px;
  border-radius: 45px;
  background: transparent;
  color: #fff;
}

.price_tab .hover_bg {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: calc(100% - 14px);
  border-radius: 45px;
  z-index: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_tab_two .hover_bg {
  background: #5f2eed;
}

.price_content .fade {
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.price_content .fade.show {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.price_content .price_item {
  text-align: center;
  background-color: white;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 11, 40, 0.1);
  box-shadow: 0px 4px 6px 0px rgba(0, 11, 40, 0.1);
  padding: 50px 50px 40px;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}

.price_content .price_item .tag {
  position: absolute;
  font: 400 14px "Poppins", sans-serif;
  color: #fff;
  background: #00aff0;
  padding: 19px 32px;
  top: -10px;
  left: -39px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.price_content .price_item .tag span {
  position: relative;
  top: 11px;
}

.price_content .price_item p {
  font-size: 16px;
  line-height: 22px;
  color: #677294;
  font-weight: 300;
  margin-bottom: 0px;
}

.price_content .price_item .price {
  line-height: 40px;
  border-bottom: 1px solid #e9e9f4;
  padding: 33px 0px 30px;
}

.price_content .price_item .price sub {
  color: #677294;
  bottom: 0;
}

.price_content .price_item .p_list {
  padding: 30px 0px 20px;
}

.price_content .price_item .p_list li {
  font: 300 16px/40px "Poppins", sans-serif;
  color: #505975;
}

.price_content .price_item .p_list li i {
  padding-right: 10px;
}

.price_content .price_item .p_list li .ti-check {
  color: #00aff0;
}

.price_content .price_item .p_list li .ti-close {
  color: #f0002d;
}

.price_content .price_item .price_btn {
  border-radius: 45px;
  color: #00aff0;
  border-color: #00aff0;
  padding: 0px 36px;
}

.price_content .price_item .price_btn:hover {
  color: #fff;
  background: #00aff0;
}

.price_content .price_item:hover {
  border-color: #c9ebfa;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.14);
}

/*=================== pricing_area_two css ===============*/

/*============== priceing_area_four css ============*/

.price_info_two {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
}

.price_info_two .price_head {
  display: table;
  width: 100%;
}

.price_info_two .price_head .p_head {
  width: calc(100% / 4);
  text-align: center;
  color: #fff;
  background-color: #db7221;
  vertical-align: middle;
  display: table-cell;
  padding: 31px 0px;
}

.price_info_two .price_head .p_head h4 {
  /* color: #222d39; */
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

.price_info_two .price_head .p_head h4,
.price_info_two .price_head .p_head h5 {
  margin-bottom: 0px;
}

.price_info_two .price_head .p_head p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.price_info_two .price_head .p_head:nth-child(2) {
  background: #0ed1b3;
}

.price_info_two .price_head .p_head:nth-child(3) {
  background: #6c84ee;
}

.price_info_two .price_head .p_head:nth-child(4) {
  background: #ee6cda;
}

.price_info_two .price_head .p_head h5 {
  font-size: 20px;
  font-weight: 600;
}

.price_info_two .price_item {
  width: calc(100% / 4);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding: 15px 0px;
}

.price_info_two .price_item:nth-child(odd) {
  background: #f8f8fa;
}

.price_info_two .price_item:first-child {
  text-align: left;
  padding-left: 30px;
}

.price_info_two .price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.price_info_two .price_item h5 i {
  font-size: 20px;
}

.price_info_two .price_item .pr_title {
  position: relative;
  display: inline-block;
  padding-left: 0px;
  margin-top: -20px;
}

.price_info_two .price_item .pr_title:before {
  /* content: "\70"; */
  position: absolute;
  left: 0;
  top: 0;
  font-family: eleganticons;
  color: #afb5c7;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.price_info_two .price_item .pr_title:hover:before {
  color: #5e2ced;
}

.price_info_two .price_item .check {
  color: #0ed1b3;
}

.price_info_two .price_item .cros {
  color: #afb5c7;
}

.price_info_two .pr_list {
  display: table;
  width: 100%;
}

.price_info_two .price_btn {
  border-radius: 45px;
  color: #677294;
  border-color: #dfe3ed;
  padding: 0px 32px;
  line-height: 46px;
  margin: 40px 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_info_two .price_btn:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.tooltip .tooltip-inner {
  background-color: #fff;
  opacity: 1;
  color: #111;
  opacity: 1;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  font-size: "Poppins", sans-serif;
  font-size: 16px;
  color: #677294;
  line-height: 26px;
  width: 100%;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff;
}

/*====================================================*/
/*============= fun_fact_area  css ==============*/
.fun_fact_area {
  padding-top: 150px;
  padding-bottom: 110px;
  overflow: hidden;
}

.fun_fact_area_two {
  background: #f7f6fa;
}

.fun_fact_content h1 {
  font-size: 60px;
}

.fun_fact_content .fact_item + .fact_item {
  padding-left: 100px;
}

.fun_fact_content .fact_item h1 {
  font-size: 70px;
  font-weight: 400;
}

.shap {
  position: absolute;
  opacity: 0.02;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 45px;
  left: 90px;
  z-index: -1;
}

/*================ payment_action_area css ===================*/

/*=========== sass_partner_logo_area css ==========*/
.partner_logo_area .partner_logo_area_four {
  margin-bottom: 0;
  padding-top: 0px;
  padding-bottom: 50px;
}

.sass_partner_logo_area,
.saas_map_area,
.saas_subscribe_area {
  background: #fbfcfe;
}

/*=========== saas_subscribe_area css ==========*/
/*========== video_area css ============*/

.video_area {
  position: relative;
  z-index: 1;
}

.video_content {
  position: relative;
}

.video_leaf {
  position: absolute;
  left: 85px;
  top: -80px;
  background: url("../img/new/leaf.png") no-repeat scroll;
  z-index: -2;
}

.cup {
  position: absolute;
  right: 98px;
  bottom: 40px;
}

.video_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  background: -webkit-linear-gradient(
      40deg,
      rgba(58, 43, 220, 0.9) 0%,
      rgba(23, 199, 189, 0.9) 100%
    ),
    url("../img/new/video_bg.jpg") no-repeat;
  background: -o-linear-gradient(
      40deg,
      rgba(58, 43, 220, 0.9) 0%,
      rgba(23, 199, 189, 0.9) 100%
    ),
    url("../img/new/video_bg.jpg") no-repeat;
  background: linear-gradient(
      50deg,
      rgba(58, 43, 220, 0.9) 0%,
      rgba(23, 199, 189, 0.9) 100%
    ),
    url("../img/new/video_bg.jpg") no-repeat;
  background-size: cover;
  -webkit-box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  height: 450px;
  max-width: 770px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.video_info h2 {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 100px;
  opacity: 0.1;
  font-weight: 700;
  z-index: -1;
}

.video_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
  text-align: center;
  line-height: 85px;
  font-size: 30px;
  position: relative;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.video_icon:before,
.video_icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 0.2px solid #fff;
  border-radius: 50%;
  opacity: 0.1;
  position: absolute;
  left: 0;
}

.video_icon:before {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  transform: scale(2.5);
}

.video_icon:after {
  -webkit-transform: scale(3.8);
  -ms-transform: scale(3.8);
  transform: scale(3.8);
}

.video_icon i {
  color: #17c7bd;
  text-shadow: 0px 6px 25px rgba(16, 147, 140, 0.9);
  position: relative;
}

.video_icon i:after {
  width: 300%;
  height: 300%;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
  content: "";
  -webkit-animation: pulse 2s infinite;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  animation: pulse 2s infinite;
}

.video_icon:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

/*============ seo_fact_area css ===========*/
/*=============== get started area css ===============*/
.get_started_area {
  background-image: -moz-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: -webkit-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: -ms-linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  background-image: linear-gradient(140deg, #672dde 0%, #4069eb 100%);
  padding: 120px 0px 80px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.get_started_area .shap {
  opacity: 0.03;
}

.get_started_area .shap.one {
  background: white;
  top: -290px;
  left: -50px;
}

.get_started_area .shap.two {
  background: white;
  top: -260px;
  left: 100px;
}

.get_started_area .shap.three {
  background: white;
  top: auto;
  left: 47%;
  bottom: -300px;
}

.get_started_area .shap.four {
  background: white;
  top: auto;
  left: 63%;
  bottom: -280px;
}

.get_content h3 {
  font-size: 24px;
  color: #fff;
}

.get_content h2,
.get_content p {
  color: #fff;
}

.get_content .app_btn {
  font: 500 16px "Poppins", sans-serif;
  padding: 12px 34px;
  border-color: #fff;
  background: #fff;
  line-height: 27px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  min-width: 200px;
  text-align: center;
}

.get_content .app_btn.app_btn_one {
  color: #4069eb;
  vertical-align: middle;
}

.get_content .app_btn.app_btn_one img {
  padding-right: 12px;
}

.get_content .app_btn.app_btn_one:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.get_content .app_btn.app_btn_two {
  background: rgba(255, 255, 255, 0.1);
  vertical-align: middle;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: 20px;
}

.get_content .app_btn.app_btn_two i {
  font-size: 25px;
  padding-right: 10px;
  vertical-align: sub;
}

.get_content .app_btn.app_btn_two:hover {
  color: #4069eb;
  background: #fff;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1);
}

/*============ get_started_three css ============*/
.get_started_three .startup_tab_img .web_img,
.get_started_three .startup_tab_img .phone_img {
  opacity: 1;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.get_started_three .get_btn_info {
  margin-top: 50px;
}

.get_started_three .get_btn_info .get_btn_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 110px;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.get_started_three .get_btn_info .get_btn_item .get_icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 45px;
  text-align: center;
  border-radius: 3px;
  background: #fff1f3;
  color: #ff738b;
  margin-right: 25px;
}

.get_started_three .get_btn_info .get_btn_item h3 {
  font-size: 20px;
  line-height: 34px;
  color: #051441;
  font-weight: 400;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.get_started_three .get_btn_info .get_btn_item .icon_two {
  background: #f1f5ff;
}

.get_started_three .get_btn_info .get_btn_item .icon_two .small {
  color: #46d779;
  font-size: 25px;
  position: relative;
  top: -25px;
  left: 6px;
  padding-top: 0;
}

.get_started_three .get_btn_info .get_btn_item .icon_two i {
  color: #73a1ff;
  padding-top: 30px;
  display: inline-block;
}

/*============== faq area css =============*/
.faq_tab {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px 40px;
}

.faq_tab .nav-tabs {
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.faq_tab .nav-item {
  margin: 0px;
}

.faq_tab .nav-item .nav-link {
  font: 400 16px/22px "Poppins", sans-serif;
  color: #677294;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.faq_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.faq_tab .nav-item .nav-link.active {
  color: #5e2ced;
}

.faq_tab .nav-item .nav-link.active:before {
  width: 100%;
}

.faq_content .tab-pane .card {
  border: 0px;
  border-radius: 0px;
  background: transparent;
  border-bottom: 1px solid #e4e0ee;
}

.faq_content .tab-pane .card .card-header {
  padding: 0px;
  border: 0px;
  background: transparent;
}

.faq_content .tab-pane .card .card-header .btn {
  color: #5e2ced;
  display: block;
  width: 100%;
  text-align: left;
  font: 500 18px/26px "Poppins", sans-serif;
  padding: 20px 30px 20px 0px;
  position: relative;
  white-space: normal;
}

.faq_content .tab-pane .card .card-header .btn i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
  font-size: 20px;
}

.faq_content .tab-pane .card .card-header .btn i + i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed {
  color: #576370;
  text-align: left;

  font-size: 18px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
  opacity: 1;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i + i {
  display: none;
}

.faq_content .tab-pane .card .card-body {
  padding: 0px 0px 45px;
  text-align: left;
  font-size: 16px;
  font-weight: 300px;
  font-family: Poppins, Light;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
}

/*====================================================*/
/*================== contact_info_area css ================*/
.contact_info_item {
  border-bottom: 1px solid #eeebf6;
  padding-bottom: 25px;
}

.contact_info_item p {
  color: #677294;
  margin-bottom: 8px;
}

.contact_info_item a {
  color: #677294;
  padding-left: 3px;
  display: inline-block;
}

.contact_info_item a:hover {
  color: #5e2ced;
}

.contact_info_item + .contact_info_item {
  border: 0px;
  margin-top: 33px;
}

.mapbox {
  height: 400px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  border: 10px solid #fff;
}

#mapBox {
  height: 100% !important;
}

.text_box {
  margin-bottom: 30px;
}

.text_box input[type="text"],
.text_box textarea,
.text_box input[type="password"],
.text_box input[type="email"] {
  font: 400 15px/60px "Poppins", sans-serif;
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  width: 100%;
  border: 1px solid #fff;
  padding-left: 30px;
}

.text_box input[type="text"].placeholder,
.text_box textarea.placeholder,
.text_box input[type="password"].placeholder,
.text_box input[type="email"].placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:-moz-placeholder,
.text_box textarea:-moz-placeholder,
.text_box input[type="password"]:-moz-placeholder,
.text_box input[type="email"]:-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-moz-placeholder,
.text_box textarea::-moz-placeholder,
.text_box input[type="password"]::-moz-placeholder,
.text_box input[type="email"]::-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-webkit-input-placeholder,
.text_box textarea::-webkit-input-placeholder,
.text_box input[type="password"]::-webkit-input-placeholder,
.text_box input[type="email"]::-webkit-input-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:focus,
.text_box textarea:focus,
.text_box input[type="password"]:focus,
.text_box input[type="email"]:focus {
  border-color: #f2effc;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  outline: none;
}

.text_box textarea {
  height: 220px;
}

.contact_form {
  padding-left: 40px;
}

.contact_form_box {
  overflow: hidden;
}

.contact_form_box .btn_three {
  min-width: 180px;
  text-align: center;
  background: #5e2ced;
  border-color: #5e2ced;
  margin-top: 0px;
  float: right;
}

.contact_form_box .btn_three:hover {
  background: transparent;
  color: #5e2ced;
}

.mapbox2 {
  height: 440px;
  border-top: 10px solid #fff;
}

/*============= login_area css =============*/

.login-form .text_box .text_c {
  color: #161341;
  font-size: 14px;
  margin-bottom: 15px;
}

.login-form .text_box input[type="password"] {
  padding-top: 8px;
  line-height: 50px;
}

.login-form .text_box input[type="password"]:focus {
  padding-top: 0px;
}

.login-form .extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font: 400 14px/30px "Poppins", sans-serif;
  margin-top: 45px;
}

.login-form .extra .checkbox label {
  margin-bottom: 0px;
  color: #161341;
}

.login-form .extra .checkbox input[type="checkbox"] {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.login-form .extra .checkbox input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  border: 1px solid #7348ef;
  border-radius: 2px;
  background-color: white;
}

.login-form .extra .checkbox input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 4px;
  height: 8px;
  border: solid #7348ef;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}

.login-form .extra .forgotten-password a {
  color: #677294;
}

.login-form .btn_three {
  width: 100%;
  background: #5e2ced;
  color: #fff;
  margin-top: 40px;
}

.login-form .btn_three:hover {
  -webkit-box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
  box-shadow: 0px 20px 30px 0px rgba(61, 100, 244, 0.16);
}

.login-form .alter-login {
  font: 400 15px/26px "Poppins", sans-serif;
  color: #9ca3b9;
}

.login-form .alter-login a {
  color: #5e2ced;
  display: inline-block;
  margin-left: 6px;
}

.login_img {
  padding-left: 100px;
  margin-right: -250px;
}

.login_img img {
  max-width: 100%;
}

/*==================== sign_in_area css ==================*/
.sign_info {
  border: 20px solid #fff;
  background: #fbfbfd;
  padding: 80px 50px;
  -webkit-box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05);
}

.sign_info .login_info {
  padding-left: 30px;
}

.sign_info .sign_info_content h2 {
  font-size: 30px;
  color: #677294;
}

.sign_info .sign_info_content ul li {
  font: 400 16px/34px "Poppins", sans-serif;
  color: #677294;
}

.sign_info .sign_info_content ul li i {
  color: #5e2ced;
  margin-right: 10px;
}

.sign_info .login-form .text_box {
  margin-bottom: 25px;
}

.sign_info .lead-text {
  font: 400 14px/40px "Poppins", sans-serif;
  color: #677294;
  padding-right: 20px;
}

.sign_info .social_tag li {
  display: inline-block;
}

.sign_info .social_tag li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  line-height: 44px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-left: 7px;
}

.sign_info .social_tag li:first-child a {
  background: #4779de;
}

.sign_info .social_tag li:nth-child(2) a {
  background: #e43e30;
}

.sign_info .social_tag li:nth-child(3) a {
  background: #1da1f3;
}

.sign_info .sign-in-form .btn_three {
  width: auto;
  margin-top: 0;
}

.sign_info .sign-in-form .extra .forgotten-password a {
  color: #161341;
  font-weight: 400;
}

.sign_info .sign-in-form .extra .forgotten-password a:hover {
  color: #5e2ced;
}

.sign_btn_transparent {
  display: inline-block;
  background: transparent;
  border-color: #5e2ced;
  color: #5e2ced;
}

.sign_btn_transparent:hover {
  background: #5e2ced;
  color: #fff;
}

/*============= get_quote_form css============*/
.get_quote_form .form-group {
  margin-bottom: 30px;
}

.get_quote_form .form-group .form-control {
  font: 300 15px "Poppins", sans-serif;
  color: #677294;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  border: 1px solid transparent;
  height: 60px;
  padding: 0px 30px;
}

.get_quote_form .form-group .form-control.placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control:-moz-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control::-moz-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control::-webkit-input-placeholder {
  color: #9ca3b9;
}

.get_quote_form .form-group .form-control:focus {
  border-color: #f2effc;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

.get_quote_form .form-group textarea.form-control {
  height: 210px;
  padding-top: 15px;
}

.get_quote_form .btn_three {
  margin-top: 10px;
}

/*=========== comingsoon css ============*/

#success,
#error {
  position: relative;
  top: 10px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  display: none;
  font-weight: 600;
}

#success {
  color: lawngreen;
}

#error {
  color: orangered;
}

/*=============== seo_subscribe_area css ============*/
/*================= error_area css ==================*/
.error_area {
  background: -moz-linear-gradient(
      180deg,
      rgba(94, 44, 237, 0.93) 0%,
      rgba(164, 133, 253, 0.9) 100%
    ),
    url("../img/error_bg.png") no-repeat scroll center 100%;
  background: -webkit-linear-gradient(
      180deg,
      rgba(94, 44, 237, 0.93) 0%,
      rgba(164, 133, 253, 0.9) 100%
    ),
    url("../img/error_bg.png") no-repeat scroll center 100%;
  background: -ms-linear-gradient(
      180deg,
      rgba(94, 44, 237, 0.93) 0%,
      rgba(164, 133, 253, 0.9) 100%
    ),
    url("../img/error_bg.png") no-repeat scroll center 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100vh;
  min-height: 650px;
}

.error_shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.error_contain h1 {
  font-size: 15vw;
  line-height: 14vw;
  margin-bottom: 0px;
}

.error_contain h2 {
  letter-spacing: 12px;
  margin-bottom: 25px;
}

.error_contain .about_btn {
  min-width: 230px;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(15, 0, 57, 0.1);
  box-shadow: 0px 20px 30px 0px rgba(15, 0, 57, 0.1);
}

/*================= error_area css ==================*/

/* p span {
  padding: 3px 8px;
} */

.tooltip_p .text-green {
  color: #49c10e;
}

.tooltip_p .text-red {
  color: #f0368d;
}

.tooltip > .tooltip-inner {
  background-color: #f00;
  color: #fff;
}

.tooltip > .tooltip-inner .arrow:before {
  border-bottom-color: red;
}

.drop_text span {
  float: left;
  font-size: 54px;
  color: #2cabed;
  font-weight: 700;
  line-height: 50px;
}

.nested {
  font-size: 16px;
  color: #627387;
  line-height: 36px;
  font-weight: 400;
}

.blockquote {
  border-left: 2px solid #4b505e;
  padding-left: 30px;
}

.blockquote p {
  font-size: 18px;
  color: #4b505e;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
}

.blockquote_two {
  background: #f9fafb;
  padding: 20px 20px 20px 40px;
  font-size: 22px;
  font-style: italic;
  color: #627387;
}

.blockquote_two img {
  margin-right: 40px;
}

.blockquote_three {
  background: #f9fafb;
  padding: 40px;
  font-size: 22px;
  color: #677294;
  font-style: italic;
  line-height: 40px;
}

.blockquote_three img {
  margin-right: 40px;
}

.btn_s {
  padding: 9px 44px;
}

.btn_m {
  padding: 16px 50px;
}

.btn_l {
  padding: 24px 86px;
}

/*====================================================*/
/*=========== blog area css ===========*/
.blog_grid_item {
  cursor: pointer;
}

.blog_grid_item img {
  -webkit-transition: opacity 600ms linear;
  -o-transition: opacity 600ms linear;
  transition: opacity 600ms linear;
}

.blog_grid_item:hover .blog_img img {
  opacity: 0.7;
}

.blog_img {
  background: #000022;
}

.blog_img img {
  max-width: 100%;
}

/* .blog_content {
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.06);
  padding: 30px 47px 42px 50px;
  position: relative;
} */

.blog_content .post_date {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 3px 0px 0px rgba(12, 0, 46, 0.06);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 50px;
  top: -40px;
  background: #fff;
}

.blog_content .category {
  text-align: left;
  font: normal normal normal 15px/25px Poppins;
  letter-spacing: 0px;
  color: #b1b1b1;
  text-transform: uppercase;
  opacity: 1;
}

.blog_content .post_date h2 {
  font: 700 30px/20px "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #5e2ced;
  padding-top: 5px;
}

.blog_content .post_date span {
  font-weight: 300;
  font-size: 14px;
  display: block;
  padding-top: 5px;
}

.blog_content p {
  font-weight: 400;

  font-size: 17px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 10px;
}

.blog_content li {
  font-weight: 400;
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
}

.blog_content h5:hover {
  color: #5e2ced;
}

.blog_content .learn_btn_two {
  font: 400 14px "Poppins", sans-serif;
  color: #282835;
}

.blog_content .learn_btn_two:before {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog_content .learn_btn_two i {
  font-size: 14px;
}

.blog_content .learn_btn_two:hover {
  color: #5e2ced;
}

.blog_content .learn_btn_two:hover:before {
  background: #5e2ced;
}

.entry_post_info {
  font: 400 15px/25px "Poppins", sans-serif;
  color: #677294;
  padding-bottom: 5px;
}

.entry_post_info a {
  color: #677294;
}

.entry_post_info a + a:before {
  content: "|";
  display: inline-block;
  margin: 0px 7px 0px 7px;
}

.blog_single-content {
  padding: 15px;
  box-shadow: 0px 0px 66px #162d5229;
}

.recent_blog_bottom_bar {
  border: 1px solid #707070;
  opacity: 0.19;
}

.blog_single .blog_content {
  padding-left: 0px;
  padding-right: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog_single .blockquote {
  color: #5e2ced;
  font-style: italic;
  border-left: 4px solid #5e2ced;
  padding-left: 35px;
}

.post_share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #d8dceb;
  padding-bottom: 10px;
}

.post_share a {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #525c7c;
}

.post_share a i {
  padding-right: 12px;
}

.post_share a:hover {
  color: #5e2ced;
}

.post_share a + a {
  margin-left: 15px;
}

.post_share .flex {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.post-nam {
  font: 400 15px/26px "Poppins", sans-serif;
  color: #282835;
}

.post_tag {
  padding-top: 20px;
}

.post_tag .post-nam {
  padding-right: 10px;
}

.post_tag a {
  font: 400 13px/26px "Poppins", sans-serif;
  color: #595b64;
  border: 1px solid #cfcfe6;
  border-radius: 3px;
  padding: 0px 11px;
  display: inline-block;
  margin-left: 10px;
}

.post_author .media-body {
  padding-left: 20px;
}

.post_author .media-body h6 {
  color: #677294;
}

.post_author .media-body p {
  color: #677294;
  margin-bottom: 0px;
}

.blog_post_item .blog_content {
  padding: 22px 22px 35px;
}

.blog_post_item .blog_content h5 {
  margin-bottom: 15px;
}

.comment-box .post_comment .post_author {
  margin-bottom: 30px;
}

.comment-box .post_comment .post_author .media-left {
  margin-right: 30px;
}

.comment-box .post_comment .post_author .replay {
  display: block;
  text-align: center;
  font-size: 20px;
  color: #525c7c;
  padding-top: 14px;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.comment-box .post_comment .post_author .replay:hover {
  color: #5e2ced;
}

.comment-box .post_comment .post_author .media-body {
  border-bottom: 1px solid #e8ebf4;
  padding-left: 0;
  padding-bottom: 25px;
}

.comment-box .post_comment .reply-comment {
  padding-left: 110px;
}

.comment-box .post_comment .reply-comment .post_author {
  margin-bottom: 0px;
}

/*=========== blog sidebar css ===========*/
.widget_title {
  margin-bottom: 35px;
}

.widget_title .border_bottom {
  width: 180px;
  height: 1px;
  background: #677294;
  display: block;
}

.widget_title_two {
  font-size: 20px;
  font-weight: 600;
  color: #282835;
  margin-bottom: 30px;
}

.blog-sidebar {
  padding-left: 40px;
}

.blog-sidebar .widget.widget_search .search-form {
  position: relative;
}

.blog-sidebar .widget.widget_search .search-form .form-control {
  font: 300 14px "Poppins", sans-serif;
  color: #677294;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.08);
  box-shadow: 0px 2px 3px 0px rgba(12, 0, 46, 0.08);
  border-radius: 0px;
  border: 0px;
  padding-left: 30px;
  height: 55px;
  z-index: 0;
  border: 1px solid transparent;
}

.blog-sidebar .widget.widget_search .search-form .form-control.placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.widget_search
  .search-form
  .form-control:-moz-placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.widget_search
  .search-form
  .form-control::-moz-placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.widget_search
  .search-form
  .form-control::-webkit-input-placeholder {
  color: #677294;
}

.blog-sidebar .widget.widget_search .search-form .form-control:focus {
  border-color: #ccc5fa;
  border-radius: 3px;
}

.blog-sidebar .widget.widget_search .search-form button {
  border: 0px;
  border-radius: 0px;
  font-size: 16px;
  color: #282835;
  background: transparent;
  position: absolute;
  right: 20px;
  height: 100%;
  padding: 0px;
  z-index: 1;
  cursor: pointer;
}

.blog-sidebar .widget.widget_search .search-form button:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.widget_recent_post .post_item img {
  border-radius: 3px;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body {
  padding-left: 20px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body h3 {
  color: #282835;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body h3:hover {
  color: #5e2ced;
}

.blog-sidebar
  .widget.widget_recent_post
  .post_item
  .media-body
  .entry_post_info {
  padding-bottom: 0px;
}

.blog-sidebar .widget.widget_recent_post .post_item + .post_item {
  margin-top: 30px;
}

.blog-sidebar .widget.widget_categorie .widget_title {
  margin-bottom: 28px;
}

.blog-sidebar .widget.widget_categorie ul {
  margin-bottom: 0px;
}

.blog-sidebar .widget.widget_categorie ul li a {
  font: 400 16px/35px "Poppins", sans-serif;
  color: #282835;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog-sidebar .widget.widget_categorie ul li a span {
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog-sidebar .widget.widget_categorie ul li a em {
  float: right;
  font-style: normal;
}

.blog-sidebar .widget.widget_categorie ul li a:hover span {
  color: #5e2ced;
  border-color: #5e2ced;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags {
  margin-top: -5px;
  margin-left: -5px;
  margin-right: -5px;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags a {
  font: 400 13px/26px "Poppins", sans-serif;
  color: #595b64;
  border: 1px solid #cfcfe6;
  border-radius: 3px;
  padding: 6px 18px;
  float: left;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 5px;
}

.blog-sidebar .widget.widget_tag_cloud .post-tags a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control {
  padding: 0px 30px;
  background: #f3f4f9;
  border-radius: 4px;
  height: 60px;
  font-size: 15px;
  color: #677294;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  z-index: 0;
  border-color: transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control.placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.search_widget_two
  .search-form
  .form-control:-moz-placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.search_widget_two
  .search-form
  .form-control::-moz-placeholder {
  color: #677294;
}

.blog-sidebar
  .widget.search_widget_two
  .search-form
  .form-control::-webkit-input-placeholder {
  color: #677294;
}

.blog-sidebar .widget.search_widget_two .search-form .form-control:focus {
  border-color: #5e2ced;
  background: #fff;
}

.blog-sidebar .widget.search_widget_two .search-form button {
  position: absolute;
  background: transparent;
  padding: 0px;
  border: 0px;
  right: 30px;
  top: 51%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  color: #282835;
  z-index: 0;
}

.blog-sidebar .widget.recent_post_widget_two .post_item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-sidebar .widget.recent_post_widget_two .post_item img {
  border-radius: 4px;
  padding-right: 25px;
}

.blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3 {
  font: 500 16px/22px "Poppins", sans-serif;
  color: #222d39;
  margin-bottom: 0;
}

.blog-sidebar .widget.recent_post_widget_two .post_item .media-body h3:hover {
  color: #5e2ced;
}

.blog-sidebar
  .widget.recent_post_widget_two
  .post_item
  .media-body
  .entry_post_info {
  font-size: 14px;
  color: #85859c;
  font-weight: 400;
  text-transform: uppercase;
}

.blog-sidebar .widget.recent_post_widget_two .post_item:not(:last-child) {
  margin-bottom: 30px;
}

.blog-sidebar .widget.categorie_widget_two ul {
  margin-bottom: 0;
}

.blog-sidebar .widget.categorie_widget_two ul li a {
  font-size: 14px;
  line-height: 36px;
  color: #282835;
  text-transform: uppercase;
}

.blog-sidebar .widget.categorie_widget_two ul li a em {
  font-style: normal;
  color: #282835;
  padding-left: 10px;
}

.blog-sidebar .widget.categorie_widget_two ul li a:hover {
  color: #5e2ced;
}

.blog-sidebar .widget.tag_widget_two .post-tags {
  margin: -5px -4px;
}

.blog-sidebar .widget.tag_widget_two .post-tags a {
  padding: 6px 20px;
  font-size: 14px;
  color: #5a5a77;
  background: #f0f2f9;
  border-radius: 4px;
  margin: 5px 4px;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog-sidebar .widget.tag_widget_two .post-tags a:hover {
  background: #5e2ced;
  color: #fff;
}

.blog-sidebar .widget.instagram_widget ul {
  margin: -7px;
}

.blog-sidebar .widget.instagram_widget ul li {
  display: inline-block;
  padding: 7px 6px;
}

.blog-sidebar .widget.instagram_widget ul li a {
  display: block;
  position: relative;
}

.blog-sidebar .widget.instagram_widget ul li a:before {
  content: "\e73d";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "themify";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  background: rgba(94, 44, 237, 0.6);
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
  color: #fff;
}

.blog-sidebar .widget.instagram_widget ul li a img {
  max-width: 100%;
  border-radius: 4px;
}

.blog-sidebar .widget.instagram_widget ul li a:hover:before {
  opacity: 1;
}

/*========= blog_area_two css ===========*/
.blog_area_two .blog_list_item:not(:last-child) {
  margin-bottom: 70px;
}

.blog_area_two .shop_page_number {
  border-top: 1px solid #ebe8f1;
  padding-top: 35px;
  margin-top: 0px;
}

.blog_list_item_two {
  position: relative;
}

.blog_list_item_two .post_date {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 4px;
  background-color: #fbfbfd;
  -webkit-box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
  box-shadow: 0px 14px 30px 0px rgba(68, 28, 183, 0.25);
  text-align: center;
  color: #5e2ced;
  padding: 13px 26px;
  z-index: 1;
}

.blog_list_item_two .post_date h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
}

.blog_list_item_two .post_date span {
  display: block;
  font-size: 15px;
  font-weight: 400;
}

.blog_list_item_two a img {
  border-radius: 6px;
}

.blog_list_item_two .video_post {
  position: relative;
  z-index: 0;
}

.blog_list_item_two .video_post:before {
  content: "";
  background: #282835;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  border-radius: 6px;
}

.blog_list_item_two .video_post img {
  border-radius: 6px;
}

.blog_list_item_two .video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.blog_list_item_two .video_icon:after {
  display: none;
}

.blog_list_item_two .video_icon:before {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.blog_list_item_two .video_icon i {
  color: #794afd;
  text-shadow: 0px 6px 25px rgba(121, 74, 253, 0.9);
}

.blog_list_item_two .blog_content {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.blog_list_item_two .blog_content p {
  font-size: 16px;
  margin-bottom: 25px;
}

.blog_list_item_two .blog_content .post-info-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog_list_item_two .blog_content .post-info-bottom .post-info-comments {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #282835;
}

.blog_list_item_two .blog_content .post-info-bottom .post-info-comments i {
  color: #5e2ced;
  padding-right: 5px;
}

.blog_list_item_two .blog_content .learn_btn_two {
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 0px;
  line-height: 20px;
  display: inline-block;
  font-weight: 500;
}

.blog_list_item_two .blog_content .post-info-comments {
  text-transform: uppercase;
  line-height: 20px;
  display: inline-block;
}

.blog_title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #222d39;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

/* .blog_title:hover {
  color: #5e2ced;
}  */

.blog_grid_info .blog_list_item {
  margin-bottom: 60px;
}

.blog_grid_info .blog_list_item .post_date {
  padding: 7px 19px;
}

.blog_grid_info .blog_list_item .post_date h2 {
  font-size: 24px;
}

.blog_grid_info .blog_list_item .blog_content {
  padding-top: 25px;
}

.blog_grid_info .blog_list_item .blog_content .blog_title {
  font-size: 20px;
  margin-bottom: 10px;
}

.blog_grid_info .blog_list_item.qutoe_post .blog_content {
  padding-bottom: 80px;
}

.blog_grid_info .blog_list_item.qutoe_post .blog_content:after {
  bottom: 0px;
  font-size: 90px;
  line-height: 90px;
}

.blog_grid_info .blog_list_item.qutoe_post_two .blog_content:after {
  bottom: auto;
  top: 80%;
}

.blog_single_info .blog_list_item_two .blog_content {
  padding-top: 50px;
}

.blog_single_info .blog_list_item_two .blog_content .qutoe_post {
  margin: 45px 0px;
}

.blog_single_info .blog_list_item_two .blog_content .post-info-bottom {
  padding-top: 20px;
}

.blog_single_info
  .blog_list_item_two
  .blog_content
  .post-info-bottom
  .social_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  font-size: 13px;
  color: #424255;
  font-weight: 500;
  letter-spacing: 1px;
}

.blog_single_info
  .blog_list_item_two
  .blog_content
  .post-info-bottom
  .social_icon
  ul {
  display: inline-block;
}

.blog_single_info
  .blog_list_item_two
  .blog_content
  .post-info-bottom
  .social_icon
  ul
  li {
  display: inline-block;
  margin-left: 8px;
}

.blog_single_info
  .blog_list_item_two
  .blog_content
  .post-info-bottom
  .social_icon
  ul
  li
  a {
  font-size: 12px;
  color: #848ca5;
}

.blog_single_info
  .blog_list_item_two
  .blog_content
  .post-info-bottom
  .social_icon
  ul
  li
  a:hover {
  color: #5e2ced;
}

.blog_single_info .blog_titles {
  font: 600 20px "Poppins", sans-serif;
  color: #282835;
  margin-bottom: 30px;
}

.blog_single_info blockquote {
  background-color: white;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(20, 3, 67, 0.08);
  border-radius: 6px;
  padding: 28px 40px 22px 40px;
  position: relative;
  overflow: hidden;
  border-left: 6px solid #5e2ced;
  z-index: 0;
  margin: 40px 0px 45px;
}

.blog_single_info blockquote:before {
  content: "\f10d";
  font-size: 30px;
  color: #5e2ced;
  padding-bottom: 12px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.blog_single_info blockquote:after {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 30px;
  bottom: auto;
  top: 80%;
  color: #f2effd;
  font-size: 100px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}

.blog_single_info blockquote i {
  font-size: 30px;
  color: #5e2ced;
  padding-bottom: 12px;
  display: inline-block;
}

.blog_single_info blockquote h6 {
  font-size: 20px;
  color: #525c7c;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
}

.blog_single_info blockquote .author_name {
  font-size: 14px;
  color: #848ca5;
  line-height: 20px;
  letter-spacing: 1px;
  margin-top: 22px;
  display: inline-block;
  text-transform: uppercase;
}

.post_author_two {
  background: #f3f5fa;
  border-radius: 4px;
  padding: 35px 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post_author_two .img_rounded {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 30px;
}

.post_author_two .media-body .comment_info h3 {
  font: 500 18px/24px "Poppins", sans-serif;
  color: #282835;
}

.post_author_two .media-body .comment_info .comment-date {
  font: 400 14px "Poppins", sans-serif;
  color: #677294;
}

.post_author_two .media-body p {
  margin-bottom: 0;
  font-size: 16px;
  color: #525c7c;
}

.blog_related_post {
  margin-top: 100px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content {
  padding-top: 25px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content h5 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
}

.blog_related_post.blog_grid_info .blog_list_item .blog_content p {
  font-size: 15px;
}

.blog_list_item {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 9px;
  height: 90%;
}

.blog_list_item img {
  width: 390px;
  height: 230px;
  padding: 10px;
}

.blog_content h2 {
  text-align: left;
  font-size: 20px;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: black;
  /* margin-left: 40px; */
  /* margin-top: 20px; */
  /* margin-bottom: -20px; */
}

.blog_content p {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: "Poppins", Regular;
  letter-spacing: 0px;
  color: #000000;
  /* margin-left: 40px; */
}

.blog_content .category {
  font-size: 15px;
  color: #b1b1b1;
  margin: 0px;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subtitle .calendar {
  color: orange;
}

.subtitle .blog-subtitle-authorName p {
  color: #484848;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
}

.subtitle .blog-subtitle-date p {
  color: #6f6f6f;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}

.subtitle .blog-subtitle-line {
  border-right: 1px solid #707070;
  height: 23px;
}

.subtitle .blog-subtitle-duration {
  align-items: center;
}

.subtitle .blog-subtitle-duration p {
  color: #db7221;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}

.subtitle .blog-subtitle-duration svg {
  color: #db7221;
  font-size: 20px;
}

/* .blog_content a {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  margin-right: 40px;
} */

.blog-detail-section {
  box-shadow: 0px 0px 66px #162d5229;
  padding: 10px;
}

.blogs_link {
  color: #434db8 !important;
}

.blogs_link:hover {
  color: #434db8 !important;
  text-decoration: underline !important;
}

.blog-content-img {
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
}

.blog-subtitle-share svg {
  cursor: pointer;
}

/*====================================================*/
/* Css keyframes css
============================================================================================ */
@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }

  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }

  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

/* End Css keyframes css
============================================================================================ */
.height {
  height: 100%;
}

.square {
  width: 50px;
  height: 50px;
}

.intro {
  width: 70%;
  margin-left: auto;
  margin-right: 100px;
  position: relative;
}

.border_shap {
  position: absolute;
  width: 400px;
  height: 700px;
  background: rgba(255, 255, 255, 0.05);
  top: 0;
  left: 100px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  opacity: 0;
  visibility: hidden;
}

.square {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
}

/*================= domain_search_area css ==============*/

.hosting_title {
  max-width: 700px;
  margin: 0 auto 70px;
}

.hosting_title h2 {
  font-size: 40px;
  line-height: 55px;
  font-weight: 600;
  color: #2c2c51;
  margin-bottom: 15px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.hosting_title p {
  color: #7b7b93;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.hosting_title .w_color {
  color: #fff;
}

/*========== h_blog_area css ========*/
.h_blog_item {
  background-color: white;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.06);
  box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.06);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.h_blog_item img {
  width: 100%;
}

.h_blog_item .h_blog_content {
  padding: 25px 40px 30px;
}

.h_blog_item .h_blog_content h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #2c2c51;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.h_blog_item .h_blog_content h3:hover {
  color: #f8636b;
}

.h_blog_item .h_blog_content .post_time {
  font-size: 14px;
  color: #7b7b93;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
}

.h_blog_item .h_blog_content .post_time i {
  color: #f8636b;
  margin-right: 5px;
}

.h_blog_item .h_blog_content .post-info-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two {
  margin-top: 0;
  font-size: 14px;
  line-height: 16px;
  color: #7b7b93;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:before {
  content: "";
  height: 1px;
  background: #f8636b;
  position: absolute;
  bottom: 0px;
  width: 0;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover {
  color: #f8636b;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover:before {
  width: 100%;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two:hover i {
  padding-left: 5px;
}

.h_blog_item .h_blog_content .post-info-bottom .learn_btn_two i {
  vertical-align: inherit;
  padding-left: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.h_blog_item .h_blog_content .post-info-bottom .post-info-comments {
  color: #7b7b93;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.h_blog_item .h_blog_content .post-info-bottom .post-info-comments i {
  color: #f8636b;
  padding-right: 3px;
}

.h_blog_item:hover {
  -webkit-box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.08);
  box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.08);
}

/*========== h_blog_area css ========*/
.h_footer_dark {
  background: #010e28;
  padding: 100px 0px 120px;
}

.h_footer_dark .company_widget p {
  font-size: 15px;
  font-weight: 400;

  margin-bottom: 0;
}

.h_footer_dark .company_widget .f_social_icon {
  margin-top: 35px;
}

.h_footer_dark .company_widget .f_social_icon a {
  background: transparent;
  font-size: 20px;
  color: #7b7b93;
  border: 1px solid #2c3445;
}

.h_footer_dark .company_widget .f_social_icon a:hover {
  background: #f8636b;
  color: #fff;
  border-color: #f8636b;
}

.h_footer_dark .f_widget.about-widget .f_list li a,
.h_footer_dark .f_widget.company_widget p {
  color: #7b7b93;
  font-weight: 400;
}

.h_footer_dark .f_widget.about-widget .f_list li {
  margin-bottom: 0px;
}

.h_footer_dark .f_widget.about-widget .f_list li a:before {
  background: #f8636b;
}

.h_footer_dark .f_widget.about-widget .f_list li a:hover {
  color: #f8636b;
}

.h_footer_dark_two .company_widget .f_social_icon a:hover {
  background: #4f79f6;
  border-color: #4f79f6;
}

.h_footer_dark_two .f_widget.about-widget .f_list li a:before {
  display: none;
}

.h_footer_dark_two .f_widget.about-widget .f_list li a:hover {
  color: #4f79f6;
}

.f_widget h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;

  margin-bottom: 20px;
}

.f_widget ul li {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
}

.pos_footer_area {
  background: url("../img/home/footer-background.jpg");
  padding-bottom: 0;
  padding-bottom: 0;
  padding-top: 60px;
  position: relative;
  background-position: center;
  background-size: cover;
}

.foot_support_call img {
  width: 19px !important;
  height: 19px !important;

  margin-right: 8px;
}

.foot_support_email img {
  width: 20px !important;
  height: 16px !important;

  margin-right: 8px;
}

.pos_footer_area .leaf_left,
.pos_footer_area .leaf_right {
  position: absolute;
  bottom: 0;
}

.pos_footer_area .leaf_left {
  left: 0;
}

.pos_footer_area .leaf_right {
  right: 0;
}

.pos_footer_area .top_shap {
  position: absolute;
  bottom: 100%;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: -1;
}

.pos_footer_area:before {
  content: "";
  width: 100%;
  height: 40px;
  /* background: #484DB8; */
  position: absolute;
  bottom: 0;
  left: 0;
}

.pos_footer_area:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(../img/pos/wave_two.png) no-repeat scroll center bottom;
  position: absolute;
  left: 0;
  top: -100%;
  z-index: -1;
}

.pos_footer_area .f_widget.company_widget p {
  color: #ffffff;
}

.pos_footer_area .f_widget.company_widget .f_social_icon a {
  color: #fff;
  border-color: #fff;
}

.pos_footer_area .f_widget.company_widget .f_social_icon a:hover {
  background: #fff;
  border-color: #fff;
  color: #5f54fd;
}

.pos_footer_area .f_widget.about-widget .f_list li a {
  color: #ffffff;
}

.pos_footer_area .f_widget.about-widget .f_list li a:before {
  background: #fff;
}

.pos_footer_area .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.f_widget img {
  width: 55%;
}

.pos_footer_bottom {
  /* padding-top: 80px; */
  padding-bottom: 30px;
}

.pos_footer_bottom .pos_women {
  position: relative;
  bottom: -12px;
}

.country_footer_top {
  margin-top: -30px !important;
  margin-bottom: -25px !important;
}

.wave_shap {
  width: 100%;
  position: absolute;
  height: 212px;
  bottom: 0;
  z-index: -1;
}

/*============== erp_banner_area_two css =============*/
@-webkit-keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }

  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }

  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }

  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }

  80% {
    left: 90%;
    top: 180px;
    opacity: 0.5;
  }

  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }

  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }

  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }

  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }

  80% {
    left: 90%;
    top: 180px;
    opacity: 0.5;
  }

  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@-webkit-keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

@keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

/*============== erp_banner_area_two css =============*/
@-webkit-keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

.menu_pos .container {
  position: relative;
}

.menu_pos .container .search_cart {
  background: transparent;
}

.menu_pos .container .menu_toggle .hamburger span,
.menu_pos .container .menu_toggle .hamburger-cross span {
  background: #fff;
}

/*============ support_home_area css ============*/

.border_bottom {
  width: 100%;
  height: 1px;
  background: #e1e5e8;
}

.support_integration_area {
  background-image: url(../img/home-security/why-choose-fireflink.png);
  padding-top: 120px;
  margin-bottom: 50px;
}

.support_integration_area h2 {
  font: normal normal 600 32px/48px Poppins;
}

.integration_img {
  margin-left: -175px;
}

.flip-card h5 {
  font-size: 16px;
  color: #000;
  margin-top: 0px;
}

.flip-card h4 {
  font-size: 50px;
  color: #484db8;
  font-weight: 700;
  margin-top: 25px;
}

.sec_title {
  background: #fff;
  box-shadow: 0px 2px 7px 0px rgb(12 0 46 / 10%);
  border-radius: 10px;
  text-align: center;
  display: block;
  padding: 26px 0px;
  margin-bottom: 30px;
  transition: all 0.2s linear;
  z-index: 1;
  position: relative;
  width: 40%;
  height: 50%;
  margin-left: 400px;
  text-align: center;
  margin-top: 155px;
}

.sec_title h2 {
  color: #484db8;
}

.whychoosehead {
  margin-top: -60px;
}

.flip-card {
  width: 262px;
  height: 134px;
  perspective: 1000px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  padding: 0px;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flip-card-back {
  background-color: #484db8;
  color: white;
  transform: rotateY(180deg);
  padding: 10px;
  border-radius: 10px;
}

.flip-card p {
  color: #fff;
}

/*============ support_home_area css ============*/
.getstartedform .subcribes .form-control {
  border-radius: 45px;
  box-shadow: none;
  border-color: #e1e1e1;
  height: 60px;
  z-index: 1;
}

.getstarted h2 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
}

.getstarted p {
  text-align: center;
  font-family: Poppins, medium;
  font-size: 19px;
  letter-spacing: 0px;
  color: #000000;
}

.getstarted h3 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 30px;
}

.getstarted img {
  width: 78px;
  height: 78px;
}

.getstartedform .subcribes .btn_submit {
  color: #ffffff;
  padding: 11px 32px;
  background: #db7221;
  border-radius: 45px;
}

.getstartedtext {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.snackbar_circle {
  background-color: #484db8;
  border-radius: 13px;
  padding: 0px 7px 2px 7px;
}

.checkmark {
  font-family: arial;
  font-weight: bold;
  -ms-transform: scaleX(-1) rotate(-35deg);
  -webkit-transform: scaleX(-1) rotate(-35deg);
  transform: scaleX(-1) rotate(-35deg);
  color: #fff;
  display: inline-block;
}

.tickmark {
  display: flex;
  justify-content: space-between;
}

.tickmark p {
  padding-left: 5px;
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 4px;
}

/*=====================Ready to Explore Section Start============================*/
/*=====================Product Offering Section Start============================*/
/*=====================Product Offering Section Start============================*/
.explore {
  background: #e5eaff 0% 0% no-repeat padding-box;
  padding: 50px;
}

.request-btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  padding: 15px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  margin-left: 480px;
  margin-bottom: 41px;
}

.exploretext h2 {
  text-align: center;
  font-size: 40px;
  letter-spacing: 0px;
  font-weight: 600;
  color: #434db8;

  font-family: Poppins, SemiBold;
}

.exploretext p {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  font-family: Poppins, SemiBold;
}

/*==================Ready to Explore Section End==================================*/
.header_area.navbar_fixed .menu_two .nav_right_btn .login_btn.active,
.header_area.navbar_fixed .menu_two .nav_right_btn .login_btn:hover {
  border-color: #00aff0;
}

.header_area.navbar_fixed .menu_two .nav_right_btn .login_btn.active:hover,
.slider_btn:hover,
.price_tab .hover_bg {
  background: #00aff0;
}

/*====================================================*/
/*============== footer_area css ==============*/
.f_bg {
  background: #eff2f9;
  -webkit-box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
  box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
}

.footer_top {
  border-bottom: 1px solid #e0e3ef;
  padding: 115px 0px 100px;
}

.f_widget .widget-wrap {
  margin-top: 35px;
}

.f_widget .widget-wrap p span {
  color: #051441;
}

.f_widget .widget-wrap p a {
  color: #677294;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.f_widget.company_widget .mchimp-errmessage,
.f_widget.company_widget .mchimp-sucmessage {
  position: absolute;
}

.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}

.f_widget.about-widget .f_list li a {
  font: normal normal normal 15px/30px Poppins;
  color: #677294;
  position: relative;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  display: inline-block;
}

.f_widget.about-widget .f_list li a:before {
  content: "";
  width: 0;
  height: 1px;
  background: #6754e2;
  right: 0;
  left: auto;
  bottom: 0;
  position: absolute;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.f_widget.about-widget .f_list li a:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}

.f_subscribe {
  position: relative;
  margin-top: 40px;
}

.f_subscribe .form-control {
  font: 300 14px "Poppins", sans-serif;
  color: #333;
  border: 1px solid #e2e7f3;
  border-radius: 3px;
  background: #e9ecf3;
  padding-left: 25px;
  height: 54px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.f_subscribe .form-control.placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control:-moz-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control::-moz-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control::-webkit-input-placeholder {
  color: #9ba2b5;
}

.f_subscribe .form-control:focus {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.f_subscribe button {
  position: absolute;
  right: 25px;
  background: transparent;
  padding: 0;
  color: #222d39;
  font-size: 16px;
  top: 52%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}

.footer_bottom p a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_bottom .f_menu {
  margin-bottom: 0px;
}

.footer_bottom .f_menu li {
  display: inline-block;
  position: relative;
}

.footer_bottom .f_menu li a {
  color: #7f88a6;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_bottom .f_menu li a:hover {
  color: #6754e2;
}

.footer_bottom .f_menu li + li {
  margin-left: 16px;
}

.footer_bottom .f_menu li + li:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #b1b7ca;
  display: inline-block;
  margin-right: 18px;
  vertical-align: middle;
}

.footer_bottom a:hover {
  color: #6754e2;
}

.f_social_icon_two a {
  font-size: 14px;
  color: #969db4;
  margin: 0px 8px;
}

/*================ footer area two css ==============*/
.footer_area_two {
  background: #fbfbfd;
}

.footer_area_two .footer_top_two {
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
}

.footer_area_two .footer_top_two .f_widget.about-widget .f_list li a:before {
  background: #00aff0;
}

.footer_area_two .footer_top_two .f_widget.about-widget .f_list li a:hover {
  color: #00aff0;
}

.footer_area_two .footer_top_two .f_widget .widget-wrap p a:hover {
  color: #00aff0;
}

.footer_area_two .footer_bottom {
  position: relative;
}

.footer_area_two .footer_bottom p a {
  color: #00aff0;
}

.footer_area_two .footer_bottom .f_menu li a:hover {
  color: #00aff0;
}

.footer_area_two .footer_bottom .f_menu li + li:before {
  background: #7f88a6;
}

.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.f_social_icon a:hover {
  background: #00aff0;
  color: #fff;
}

.f_social_icon a + a {
  margin-left: 10px;
}

.pagescroll_btn {
  width: 44px;
  height: 44px;
  border: 1px solid #1d1d42;
  font-size: 16px;
  color: #00aff0;
  text-align: center;
  line-height: 44px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -22px;
  background: #121233;
}

.footer_three {
  background: #1b1e29;
}

.footer_three .footer_top {
  border-color: #252130;
  color: #b2b6c4;
}

.footer_three .footer_top p {
  color: #b2b6c4;
}

.footer_three .footer_top .f-title {
  color: #fff;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a {
  color: #b2b6c4;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a:before {
  background: #a8a9ab;
}

.footer_three .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.footer_three .footer_top .social_icon {
  margin-top: 42px;
}

.footer_three .footer_top .social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #8a8e9b;
  font-size: 14px;
  line-height: 45px;
  background: #282b38;
  text-align: center;
  display: inline-block;
  margin-right: 12px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footer_three .footer_top .social_icon a:hover {
  background: #7444fd;
  color: #fff;
}

.footer_three .footer_bottom {
  color: #b2b6c4;
}

.footer_three .footer_bottom .f_menu li a {
  color: #b2b6c4;
}

.footer_three .footer_bottom .f_menu li a:hover {
  color: #fff;
}

/*=============== footer_area_four css =============*/
.footer_area_four .footer_top {
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
}

.footer_area_four .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}

.footer_area_four .footer_top .f_widget.about-widget .f_list li a:hover:before {
  background: #5e2ced;
}

.footer_area_four .footer_top .f_widget .widget-wrap p a:hover {
  color: #5e2ced;
}

.footer_area_four .footer_bottom p a {
  color: #5e2ced;
}

.footer_area_four .footer_bottom a:hover {
  color: #5e2ced;
}

/*======= footer_area_five css ========*/
.footer_area_five .footer_top .f_widget.about-widget .f_list li a:hover {
  color: #3d64f4;
}

.footer_area_five .footer_top .f_widget.about-widget .f_list li a:hover:before {
  background: #3d64f4;
}

.footer_area_five .footer_top .f_widget .widget-wrap p a:hover {
  color: #3d64f4;
}

.footer_area_five .footer_bottom p a {
  color: #3d64f4;
}

.footer_area_five .footer_bottom a:hover {
  color: #3d64f4;
}

.payment_footer_area.footer_area_six {
  background-image: linear-gradient(0deg, #1c143b 0%, #111339 100%);
  border-top: 2px solid #192161;
  padding-top: 120px;
}

.payment_footer_area .footer_top_six .company_widget a {
  color: #3d64f4;
}

.payment_footer_area
  .footer_top_six
  .f_widget.about-widget
  .f_list
  li
  a:before {
  background: #fff;
}

.payment_footer_area .footer_top_six .f_widget.about-widget .f_list li a:hover {
  color: #fff;
}

.payment_footer_area .footer_top_six .social-widget {
  margin-right: -15px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon {
  padding-top: 4px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #232453;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a + a {
  margin-left: 2px;
}

.payment_footer_area .footer_top_six .social-widget .f_social_icon a:hover {
  background: #3d57f4;
}

/*============ footer_seven css==============*/

/*============= error_footer css ===========*/
.error_footer {
  background: #eff2f9;
  padding: 40px 0px;
}

.error_footer a:hover {
  color: #5e2ced;
}

.error_footer .f_menu li a {
  position: relative;
}

.error_footer .f_menu li a:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.error_footer .f_menu li a:hover {
  color: #5e2ced;
}

.error_footer .f_menu li a:hover:before {
  width: 100%;
}

.error_footer .f_menu li + li:before {
  display: none;
}

/*============== footer_dark_one css =============*/
.footer_dark_one {
  background: #15133a;
}

.footer_dark_one .footer_top {
  border-color: #1f1d48;
}

.footer_dark_one .f_widget.about-widget .f_list li a,
.footer_dark_one .f_widget .widget-wrap p a,
.footer_dark_one .f_social_icon_two a {
  color: #9ca5c1;
}

.dark_f_bottom a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.dark_f_bottom a:hover {
  color: #4069eb;
}

.dark_f_bottom p {
  color: #9ca5c1;
}

.dark_f_bottom p a {
  color: #4069eb;
}

.dark_f_bottom .f_menu li a {
  color: #9ca5c1;
}

.dark_f_bottom .f_menu li a:hover {
  color: #4069eb;
}

.dark_f_bottom .f_menu li + li:before {
  background: #5e6482;
}

.dark_widget .f-title {
  color: #fff;
}

.dark_widget.company_widget .f_social_icon_two {
  margin-top: 25px;
}

.dark_widget.company_widget .f_social_icon_two a:first-child {
  margin-left: 0px;
}

.dark_widget.company_widget .f_social_icon_two a:hover {
  color: #4069eb;
}

.dark_widget .widget-wrap p span {
  color: #fff;
}

.dark_widget .widget-wrap p a:hover {
  color: #4069eb;
}

.dark_widget.about-widget .f_list li a:before {
  background: #4069eb;
}

.dark_widget.about-widget .f_list li a:hover {
  color: #4069eb;
}

.dark_widget .f_subscribe .form-control {
  background: transparent;
  border-color: #202430;
}

.dark_widget .f_subscribe button {
  color: #fff;
}

/*============ footer_dark_two css ===========*/
/*=========== footer_nine_area css ============*/
.footer_nine_area {
  position: relative;
}

.footer_nine_area .footer_shap {
  position: absolute;
  background: url("../img/home9/footer.png") no-repeat scroll center;
  left: 0;
  width: 100%;
  background-size: cover;
  top: 0px;
  height: 100%;
}

.footer_nine_top {
  padding-bottom: 170px;
  position: relative;
  padding-top: 150px;
}

.footer_nine_top .f_widget .f-title {
  color: #051441;
  text-transform: uppercase;
}

.footer_nine_top .f_widget .f-title:after {
  content: "";
  width: 100px;
  height: 1px;
  display: block;
  background: #ebeefa;
  margin-top: 15px;
}

.footer_nine_top .f_widget.about-widget .f_list li a:before {
  background: #0479f7;
}

.footer_nine_top .f_widget.about-widget .f_list li a:hover {
  color: #0479f7;
}

.footer_nine_top .company_widget .f_social_icon_two a:first-child {
  margin-left: 0;
}

.footer_nine_top .company_widget .f_social_icon_two a:hover {
  color: #0479f7;
}

.footer_nine_bottom {
  background: #051441;
  padding: 27px 0px;
}

.footer_nine_bottom p {
  font: 300 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.footer_nine_bottom p a {
  color: #fff;
}

.flag_selector {
  float: right;
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font: 400 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.flag_selector .dropdown-toggle {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font: 400 14px "Poppins", sans-serif;
  color: #7f88a6;
}

.footer_nine_bottom .flag_selector .dropdown-toggle:after {
  display: none;
}

.footer_nine_bottom .flag_selector .dropdown-toggle:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.footer_nine_bottom .flag_selector .text {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.footer_nine_bottom .flag_selector .text .flag-icon {
  margin-left: 0px;
  margin-right: 20px;
  float: none;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.footer_nine_bottom .flag_selector .flag-icon {
  position: relative;
  width: 39px;
  line-height: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  height: 22px;
  margin-left: 27px;
  float: right;
}

.footer_nine_bottom .flag_selector .flag-icon:before {
  content: "";
  width: 1px;
  height: 100%;
  position: relative;
  display: inline-block;
  background: #18295c;
  left: -15px;
}

.footer_nine_bottom .flag_selector .flag-icon.flag-icon-us {
  background-image: url(../img/home9/flag.png);
}

.footer_nine_bottom .flag_selector .flag-icon.flag-icon-mx {
  background-image: url(../img/home9/flag.png);
}

.footer_nine_bottom .flag_selector .dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  border: 0px;
}

.footer_nine_bottom .flag_selector .dropdown-menu .flag-icon:before {
  display: none;
}

.footer_nine_bottom .flag_selector .dropdown-menu li .dropdown-item {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footer_nine_bottom .flag_selector .dropdown-menu li .dropdown-item:hover {
  background: #21d4fd;
  color: #fff;
}

/*===========footer_dark_ten css ===========*/
.footer_dark_ten .f_widget .widget-wrap p a:hover {
  color: #23b1fe;
}

.footer_dark_ten .f_widget.about-widget .f_list li a:before {
  background: #23b1fe;
}

.footer_dark_ten .f_widget.about-widget .f_list li a:hover {
  color: #23b1fe;
}

.footer_dark_ten .dark_f_bottom .f_menu li a:hover,
.footer_dark_ten .dark_f_bottom a {
  color: #23b1fe;
}

.payment_footer_area {
  background: #07112d;
  border: 0px;
  padding: 220px 0px 100px;
}

.payment_footer_area .f_widget.company_widget p {
  color: #7f88a6;
}

.payment_footer_area .f_widget.company_widget a {
  color: #5f51fb;
}

.payment_footer_area .f_widget.about-widget .f_list li a {
  color: #959eb8;
}

.payment_footer_area .social-widget .f_social_icon a {
  background: #182240;
}

.payment_footer_area .social-widget .f_social_icon a:hover {
  background: #5f51fb;
}

.payment_footer_area_two {
  padding: 100px 0px;
}

/*============ new_footer_area css ===========*/
.new_footer_area {
  background: #fbfbfd;
}

.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}

.new_footer_area .footer_bottom p {
  font-size: 16px;
  color: #6a7695;
  line-height: 28px;
  margin-bottom: 0;
}

.new_footer_area .footer_bottom p i {
  color: #fd2f51;
}

.new_footer_top {
  padding: 120px 0px 270px;
  position: relative;
}

.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}

.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}

.new_footer_top .company_widget .f_subscribe_two .form-control {
  border: 1px solid #e2e2eb;
  border-radius: 4px;
  height: 55px;
  background: #fff;
  font-size: 15px;
  font-weight: 300;
  line-height: 55px;
  padding-left: 30px;
}

.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}

.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}

.new_footer_top .f_widget.about-widget .f_list li a:before {
  display: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 12px;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("../img/seo/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("../img/seo/car.png") no-repeat center center;
  width: 70px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("../img/seo/bike.png") no-repeat center center;
  width: 50px;
  height: 52px;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

.sec_title p {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  color: #000000;

  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;

  opacity: 1;
}

.breadcrumb_area_three .breadcrumb_shap {
  width: 100%;
  height: 100%;
  background-size: cover;
}

/*=================== h_security_banner_area css =============*/
.h_security_banner_area {
  height: 100vh;
  min-height: 650px;
  /* background-image: linear-gradient(60deg, #0063c1 0%, #0e79de 100%); */
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
}

.h_security_banner_area .s_round {
  position: absolute;
  border-radius: 50%;
  top: -100px;
  background: rgba(255, 255, 255, 0.05);
  z-index: -1;
}

.h_security_banner_area .s_round.one {
  width: 280px;
  height: 280px;
  left: -8%;
}

.h_security_banner_area .s_round.two {
  width: 500px;
  height: 500px;
  left: -430px;
}

.h_security_banner_area .s_shap {
  right: 0;
  bottom: 0;
}

.security_banner_content h1 {
  font-size: 42px;
  line-height: 64px;
  font-weight: 700;
  color: #434db8;
  margin-bottom: 18px;
}

.security_banner_content {
  width: 497px;
}

.security_banner_content p {
  letter-spacing: 0px;
  margin-left: 26px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #434db8;
}

.quote {
  position: absolute;
  margin-top: -72px;
}

.security_banner_content span {
  font-weight: bold;
}

.security_banner_content .app_btn {
  font-size: 16px;
  padding: 10px 39px;

  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000029;
  border: 1px solid #ffffff;
}

.security_banner_content .app_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.security_banner_content .w_btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-left: 30px;
}

.security_banner_content .w_btn img {
  margin-right: 10px;
  vertical-align: sub;
}

.security_title h2 {
  font-size: 34px;
  line-height: 44px;
}

.security_title h2 span {
  display: block;
  font-weight: 300;
}

.productoffer h2 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 35px;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-top: 170px;
}

.productoffer ul {
  list-style: none;
  margin-bottom: 30px;
  margin-left: -40px;
}

.productoffer ul img {
  margin-right: 5px;
}

.productoffer ul li {
  text-align: left;
  font-family: Poppins, Medium;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.line {
  border: 3px solid #db7221;
  width: 50px;

  border-radius: 5px;
  margin-bottom: 60px;
}

.productoffer p {
  text-align: left;

  font-size: 17px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
}

.productoffer h3 {
  text-align: left;
  font-family: Poppins, semiBold;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0px;
  color: #434db8;
}

.productoffer span {
  text-align: left;
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0px;
  color: #434db8;
}

.productoffer h4 {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  font-family: Poppins, Medium;

  letter-spacing: 0px;
  color: #db7221;
}

.prodimg {
  width: 450px;
  height: 450px;
  margin-left: 40px;
}

.readmore {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 35px;
  padding: 0px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
}

.readmore a {
  color: #ffffff;
}

.readmore img {
  width: auto;
  margin-left: 5px;
}

.productoffer h5 {
  text-align: center;
  font-size: 19px;
  letter-spacing: 0px;
  color: #000000;
  font-family: Poppins, Medium;
}

.unifeat {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #434db82b;
  border-radius: 10px;
  padding: 0px;
  height: 100%;
}

.unifeat h3 {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
}

.unifeat h5 {
  margin-top: -5px;
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  text-align: center;
}

.unifeat p {
  font-size: 17px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  padding: 25px;
  margin-top: -25px;
}

.unifeat img {
  margin-top: 25px;
  margin-bottom: 15px;
}

.whychoose h2 {
  text-align: center;
  font-size: 35px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
}

.whychoose h5 {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.fast {
  background: url("../img/home/faster.jpg");
  background-position: center;
  background-size: cover;
  margin-left: -56px;
  padding: 40px;
  padding-bottom: 1px;
  margin-right: 17px;
}

.save {
  background: url("../img/home/latency.jpg");
  background-position: center;
  background-size: cover;
  margin-left: -47px;
  padding: 40px;
  padding-bottom: 29px;
  margin-right: -31px;
}

.roi {
  background: url("../img/home/get-roi.jpg");
  background-position: center;
  background-size: cover;
  margin-right: -47px;
  padding: 40px;
  padding-bottom: 27px;
}

.latency {
  background: url("../img/home/save-tester.jpg");
  background-position: center;
  background-size: cover;
  margin-left: -56px;
  padding: 40px;
  padding-bottom: 3px;
  margin-right: 16px;
  margin-top: -1px;
}

.response {
  background: url("../img/home/response-time.jpg");
  background-position: center;
  background-size: cover;
  margin-left: -47px;
  padding: 40px;
  padding-bottom: 61px;
  padding-top: 38px;
  margin-right: -31px;
  margin-top: -1px;
}

.coverage {
  background: url("../img/home/nlp1.jpg");
  background-position: center;
  background-size: cover;
  margin-right: -47px;
  padding: 40px;
  padding-bottom: -9px;
  margin-top: -3px;
  /* padding-top: 77px; */
  padding-bottom: 5px;
}

.whychoose h3 {
  text-align: left;
  font-size: 55px;
  color: #db7221;
  font-weight: bold;
  margin-left: -10px;
  margin-bottom: -4px;
}

.whychoose h4 {
  text-align: left;

  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: -7px;
}

.whychoose p {
  text-align: left;

  font-size: 20px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 20px;
  margin-top: -20px;
  margin-left: -23px;
}

/*============== priceing_area_four css ============*/
.pricing_area_four .sec_title {
  margin-bottom: 50px;
}

.pricing_area_four .sec_title h2 {
  margin-bottom: 0px;
}

.pricing_area_four .sec_title p {
  font-style: italic;
}

.price_info_two {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 4px 5px 0px rgba(12, 0, 46, 0.05);
}

.price_info_two .price_head {
  display: table;
  width: 100%;
}

.price_info_two .price_head .p_head {
  width: calc(100% / 4);
  text-align: center;
  color: #fff;
  vertical-align: middle;
  display: table-cell;
  padding: 31px 0px;
}

/* .price_info_two .price_head .p_head h4 {
  color: #222d39;
  font-size: 20px;
} */

.price_info_two .price_head .p_head h4,
.price_info_two .price_head .p_head h5 {
  margin-bottom: 0px;
}

.price_info_two .price_head .p_head p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.price_info_two .price_head .p_head:nth-child(2) {
  background: #0ed1b3;
}

.price_info_two .price_head .p_head:nth-child(3) {
  background: #6c84ee;
}

.price_info_two .price_head .p_head:nth-child(4) {
  background: #ee6cda;
}

.price_info_two .price_head .p_head h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.price_info_two .price_item {
  width: calc(100% / 4);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding: 15px 0px;
}

.price_info_two .price_item:nth-child(odd) {
  background: #f8f8fa;
}

.price_info_two .price_item:first-child {
  text-align: left;
  padding-left: 30px;
}

.price_info_two .price_item h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.price_info_two .price_item h5 i {
  font-size: 20px;
}

.price_info_two .price_item .pr_title {
  position: relative;
  display: inline-block;
  padding-left: 0px;
  margin-top: -20px;
}

/* .price_info_two .price_item .pr_title:before {
  content: "\70";
  position: absolute;
  left: 0;
  top: 0;
  font-family: eleganticons;
  color: #afb5c7;
  -webkit-transition: color 0s linear;
  -o-transition: color 0s linear;
  transition: color 0s linear;
} */

.price_info_two .price_item .pr_title:hover:before {
  color: #5e2ced;
}

.price_info_two .price_item .check {
  color: #0ed1b3;
}

.price_info_two .price_item .cros {
  color: #afb5c7;
}

.price_info_two .pr_list {
  display: table;
  width: 100%;
}

.price_info_two .price_btn {
  border-radius: 45px;
  color: #677294;
  border-color: #dfe3ed;
  padding: 0px 32px;
  line-height: 46px;
  margin: 40px 0px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.price_info_two .price_btn:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.tooltip .tooltip-inner {
  background-color: #fff !important;
  opacity: 1;
  color: #111 !important;
  opacity: 1;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.14);
  font-size: "Poppins", sans-serif;
  font-size: 16px;
  color: #677294;
  line-height: 26px;
  width: 100%;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.tooltip.show {
  opacity: 1;
}

.price_info_three .price_head .p_head:nth-child(2) {
  background: #434db8;
}

.price_info_three .price_head .p_head:nth-child(3) {
  background: #db7221;
}

.price_info_three .price_head .p_head:nth-child(4) {
  background: #434db8;
}

.price_info_three .price_item h5 {
  color: #2c2c51;
}

.price_info_three .price_item .pr_title:hover:before {
  color: #26da15;
}

.price_info_three .price_item .check {
  color: #0e79de;
}

.price_info_three .price_item .cros {
  color: #fc3f48;
}

.price_info_three .price_btn:hover {
  background: #0e79de;
  border-color: #0e79de;
}

/*======== security_action_area css =========*/

.security_footer_area:after {
  display: none;
}

.security_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50px;
  color: #ffffff;
}

.livebtn {
  margin-top: 0;
  font-size: 15px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000029;
  border: 1px solid #db7221;
  border-radius: 50px;
  color: #db7221;
}

.security_btn:hover {
  background: #fff;
  color: #0e79de;
  border-color: #0e79de;
}

.start_free_trial_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50px;
  color: #ffffff;
}

.start_free_trial_btn:hover {
  background: #fff;
  border-color: #0e79de;
}

.navbar_fixed .security_btn,
.position .security_btn {
  color: #fff;
}

.navbar_fixed .security_btn:hover,
.position .security_btn:hover {
  background: #0e79de;
  color: #fff;
}

.styles_overlay__CLSq- {
  background: rgba(0, 11, 40, 0.9) !important;
  z-index: 999999;
}

.styles_overlay__CLSq- div div > div {
  background: rgba(0, 11, 40, 0.9) !important;
}

.uptri::before {
  left: 37%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri_signin::before {
  left: 43%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri1::before {
  left: 48%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri1_signin::before {
  left: 54%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri2::before {
  left: 70%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri2_signin::before {
  left: 76%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.Mview {
  display: none;
}

.uptri3::before {
  left: 84%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.uptri4::before {
  left: 92%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  /* line-height: 0; */
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}
.uptri_prod::before {
  left: 59%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}
.uptri_prod1::before {
  left: 70%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}
.uptri_prod2::before {
  left: 92%;
  bottom: 100%;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #fff;
  font-size: 0;
  display: inline-block;
  position: absolute;
  background-color: transparent;
  z-index: 9999;
  right: 0;
  width: 0;
  height: 0;
}

.top_line {
  border-left: 1px solid #ffffff;
}

.top_text {
  display: flex;
  justify-content: end;
  margin-right: 40px;
  margin-top: 5px;
  margin-bottom: -15px;
  height: 30px;
}

.top_text a {
  font-size: 13px;
  font-family: Poppins, Regular;
  color: #ffffff;
  font-weight: 600;
  margin-right: 30px;
}

.top_text_a:hover {
  border-bottom: 1.5px solid;
  /* color: #DB7221 !important; */
}

/*====================================================*/
.activeusers {
  background-image: url("../img/home/activeuser.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
}

.activebox {
  background-color: #fff;
  border-radius: 40px;
  margin-top: -60px;
  margin-bottom: -60px;
}

.activeusers h3 {
  text-align: center;

  font-size: 80px;
  font-weight: 600;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #db7221;
  margin-left: 30px;
}

.activeusers h2 {
  text-align: center;

  font-size: 65px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.center_alignment {
  display: flex;
  justify-content: center;
}

.activeline {
  border: 3px solid #ffffff;

  border-radius: 2px;
  width: 60px;
}

/*====================================================*/
.happy_clients {
  background: #e5eaff;
}

.happy_client_head_content {
  position: relative;
  top: -100px;
}

/*===========================Web testing types sec start=========================*/
.testingtypes {
  background-color: #f6f8f9;
}

.testingtypesbox {
  opacity: 1;
  background: #fff;
  box-shadow: 0px 0px 26px #434db82b;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  height: 100%;
}

.webtestingcontent {
  padding-left: 10px;
}

.webtestingcontent h3 {
  margin-top: 10px;
}

.webtestingcontent p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/*===========================Web testing types sec end=========================*/
.supportmulti h2 {
  text-align: left;
  font-weight: 600;
  font-size: 34px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-top: 60px;
}

.supportmulti p {
  text-align: left;
  font-weight: 500;
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.supportmulti h3 {
  text-align: left;
  font-weight: normal;
  font-size: 17px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: normal;
}

.supportmultiplatform {
  margin-left: 20px;
}

.accelerateApi h2 {
  text-align: left;
  font-weight: 600;
  font-size: 30px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-top: 110px;
  margin-left: 20px;
}

.accelerateApi p {
  text-align: left;
  font-weight: 500;
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 10px;
}

.accelerateApi h3 {
  text-align: left;
  font-weight: normal;
  font-size: 17px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 20px;
  line-height: normal;
}

.list_tickmark {
  background: #db7221;
  opacity: 1;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #ffffff;
  margin-right: 9px;
  padding-left: 6px;
  margin-bottom: 20px;
  font-size: 16px;
}

.list ul li {
  list-style: none;
  text-align: left;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 15px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.list {
  margin-left: -16px;
}

.stepreuse h2 {
  text-align: left;
  font-weight: 600;
  font-size: 35px;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-top: 50px;
  margin-left: 20px;
}

.execution_report {
  background: url("../img/web/test-execution-report.jpg");
  background-position: center;
  background-size: cover;
}

.execution_report_api {
  background: url("../img/api/execution-report-api.png");
  background-position: center;
  background-size: cover;
}

.execution_report_ux {
  background: url("../img/ux-testing/execution-report.jpg");
  background-position: center;
  background-size: cover;
}

.aiinfused_mobile {
  background: url("../img/mobile/Ai-enchanced.jpg");
  background-position: center;
  background-size: cover;
}

.graphical_rich_report {
  background: url("../img/mobile/graphical-rich-report.jpg");
  background-position: center;
  background-size: cover;
}

.defect_raise {
  background: url("../img/defect-management/defect-raise.jpg");
  background-position: center;
  background-size: cover;
}

.insightful_reports {
  background: url("../img/ui-testing/insightful-reports.jpg");
  background-position: center;
  background-size: cover;
}

.supported_devices {
  background: url("../img/ui-testing/supported-devices.png");
  background-position: center;
  background-size: cover;
}

.accelerate {
  background: url("../img/web/accelerate-automation.png");
  background-position: center;
  background-size: cover;
  margin-bottom: 80px;
}

.accelerate h2 {
  text-align: left;
  font-size: 35px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 20px;
}

.accelerate p {
  text-align: left;
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.parabottom {
  border: 3px solid #db7221;
  opacity: 1;
  width: 60px;
  margin-left: 20px;
  border-radius: 5px;
}

.execution {
  background: transparent linear-gradient(270deg, #434db8 0%, #22275c 100%) 0%
    0% no-repeat padding-box;
  height: 340px;
}

.execution img {
  width: 100%;
  margin-top: -240px;
}

.stepreusebox {
  box-shadow: 0px -6px 6px #0000000d;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  width: 600px;
  height: 500px;
}

.stepreusebox h2 {
  text-align: left;
  font: normal normal 600 32px/48px Poppins;
  letter-spacing: 0px;
  color: #434db8;
}

.stepreusebox p {
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.stepreuseimg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -6px 6px #00000012;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
  width: 518px;
  height: 319px;
  margin-top: -90px;
  margin-bottom: 20px;
}

.stepreuseimg img {
  width: 75%;
  height: 100%;
  margin-top: 10px;
}

.regressionsuites {
  background: transparent linear-gradient(180deg, #434db817 0%, #f2f3ff1f 100%)
    0% 0% no-repeat padding-box;
}

.executionreport {
  height: 378px;
  background: #434db8 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.multiapptext h3 {
  margin-top: 20px;
  margin-left: -25px;
  font-size: 24px;
}

.multimobbox {
  background: url(../img/home5/mobile-page-background-images/bg-1.svg);

  background-size: cover;
  background-position: center;
  height: 890px;
  width: 1290px;
}

.multiappimg {
  background-image: url(../img/home5/support-multi-platform.png);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin-left: -100px;
}

.multimobbox h2 {
  padding: 40px;
  margin-top: 180px;
}

.mobexeautomate img {
  width: 397px;
  height: 337px;
  margin-top: 140px;
}

.mobexeautomate {
  background: url(../img/home5/mobile-page-background-images/bg-5.svg) no-repeat;
  background-size: cover;
  background-position: center;
  width: 1366px;
  height: 1010px;
  margin-left: -50px;
}

.runscript {
  background-image: url(../img/home5/runscript.png);
  background-size: cover;
  background-position: center;
}

.d-flex-space {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.aiinfused {
  background: url(../img/home5/mobile-page-background-images/bg-3.svg) left
      no-repeat,
    url(../img/home5/mobile-page-background-images/bg-8.svg) right no-repeat;
}

.aiinfusedbox {
  background: transparent linear-gradient(270deg, #191e53 0%, #434db8 100%) 0%
    0% no-repeat padding-box;
  box-shadow: -3px 3px 15px #0000006e;
  border-radius: 40px;
  height: 240px;
  margin-top: 110px;
  padding: 30px;
  position: absolute;
  z-index: 9;
}

.restfulbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0b1582;
  opacity: 1;
  border-radius: 0px 0px 68px 0px;
  padding: 41px;
  margin-top: 0px;
  height: 430px;
  margin-left: 30px;
}

.overlay {
  margin-left: -70px;
}

.restfulimgbox {
  background: url(../img/home5/restful-web.png);
  background-size: cover;
  background-position: center;
  height: 430px;
  width: 632px;
}

.apiscriptless {
  background: url(../img/api/scriptless-api-test-automation.png);
  background-size: cover;
  background-position: center;
}

.mobexeautomate h2 {
  padding: 70px;
  text-align: left;
  margin-top: 150px;
  /* margin-bottom: ; */
}

.mobexeautomate p {
  margin-top: -60px;
  margin-left: 70px;
}

.suite_execution h3 {
  text-align: center;
  font: normal normal 600 25px/38px Poppins;
  letter-spacing: 0px;
  color: #434db8;
}

.suite_execution img {
  width: auto;
}

/*================= Defect management Sec Start ================ */
.defectfeatures {
  background-color: #0b1582;
}

/*================= Defect management Sec Start ================ */
.defectraise img {
  width: 320px;
  height: 280px;
  margin-top: -140px;
  margin-left: 100px;
}

.defectraisebox {
  background: transparent linear-gradient(180deg, #434db8 0%, #0b1582 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px -6px 6px #0000000d;
  opacity: 1;
  padding: 20px;
  border-radius: 20px;
}

.defectraise h2 {
  margin-bottom: 140px;
}

.manualtestcaseexecution {
  background: transparent linear-gradient(#6ebdf6 100%, #448fc3 0%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #707070;
  height: 250px;
}

.manualtestexecution {
  padding: 20px;
  border-radius: 20px;
  background: #f6f8f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 90px 0px;
  opacity: 1;
  margin-top: -500px;
}

.manualtestcasetxt {
  margin-top: 40px;
  margin-bottom: 40px;
}

manualtestexebox {
  background: #289df2 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  height: 550px;
  width: 220px;
  margin-top: -190px;
  margin-left: 180px;
  position: static;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: none;
  border: none;
}

.uxworkbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -5px 15px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  height: 100%;
}

.uxworkbox h3 {
  font-size: 20px;
}

.uxworkbox h4 {
  font-size: 16px;
}

.uxworkbox p {
  font-size: 14px;
  text-align: left;
}

.typesofmode h3 {
  font-size: 20px;
  margin-left: 25px;
}

.typesofmode h4 {
  font-size: 16px;
  margin-left: 25px;
}

.typesofmode p {
  font-size: 14px;
  text-align: left;
  margin-left: 25px;
}

.categoriessupport h3 {
  font-size: 18px;
  margin-top: 20px;
}

.categoriessupport p {
  font-size: 14px;
  line-height: 25px;
}

.catrgorybox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
}

.categoryheadbox {
  background: transparent linear-gradient(180deg, #ffffff 0%, #f6f8f9 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px -4px 6px #0000001a;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  padding: 40px;
}

.submenulist {
  display: flex;
  padding: 25px;
  margin-left: 15px;
  gap: 5px;
}

/*==========FireFlink Platform Page Start*/
.platformbanner {
  background: url("../img/firelink-platform/fireflink-platform-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 720px;
}

.buyfireflink {
  background: url("../img/firelink-platform/buy-fireflink.png");
  background-position: center;
  background-size: cover;
  height: 300px;
}

.buyfireflink h3 {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 100px;
  margin-bottom: 20px;
}

.buyfireflink_click {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  color: #ffffff;
  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
  font-size: 16px;
  font-family: Poppins, SemidBold;
  font-weight: 500;
}

.single_thread {
  background: url("../img/firelink-platform/multi-channel-right.svg");
  height: 519px;
  padding-top: 80px;
  padding-left: 145px;
}

.single_thread_left {
  background: url("../img/firelink-platform/multi-channel-left.svg");
  height: 519px;
  padding-top: 80px;
  padding-left: 80px;
  margin-left: -47px;
  background-repeat: no-repeat;
}

/* .single_left_img {
  margin-left: -119px;
} */
.single_thread h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #282828;
}

.single_thread span {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #db7221;
}

.single_thread p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}

.single_thread_left h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #282828;
}

.single_thread_left span {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #db7221;
}

.single_thread_left p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}

/*==========FireFlink Platform Page End*/

/*================FireFlink Finder Page Start=============*/
.fireflink_finder_background_banner {
  background: url("../img/fireflink-finder/fireflink-home-background.jpg");
  background-position: center;
  background-size: cover;
  height: 720px;
}

.finder h4 {
  text-align: center;
  font-size: 20px;
  font-family: poppins, medium;
  letter-spacing: 0px;
  font-weight: normal;
  color: #ffffff;
  opacity: 1;
  margin-top: -7px;
  margin-bottom: 25px;
}

.downloadfinder h2 {
  margin-top: 290px;
  text-align: center;
  font-family: Poppins, medium;
  font-size: 35px;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.downloadfinderbox {
  background: #ffffff;
  box-shadow: 0px 0px 26px #434db82b;
  border-radius: 10px;
  opacity: 1;
  padding: 40px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.downloadfinderbox h3 {
  text-align: center;
  font-size: 56px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 20px;
}

.downloadfinderbox p {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #8a8a8a;
  margin-bottom: 40px;
}

.finder_download_btn {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 35px;
  opacity: 1;
  color: #ffffff;
  padding: 15px;
  padding-left: 35px;
  padding-right: 35px;
  font-weight: 500;
}

.dasheddborder {
  border-right: dashed;
  color: #8a8a8a;
  margin-left: -30px;
}

.know_more_click_here {
  background: #db7221 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  color: #ffffff;
  padding: 10px;
  padding-right: 40px;
  padding-left: 40px;
  text-align: center;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0px;
  margin-left: 490px;
  margin-bottom: 40px;
}

.want_to_know_finder h2 {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 110px;
  margin-bottom: 20px;
}

.whychoose_finder {
  margin-top: 100px;
  margin-bottom: 100px;
}

/*================FireFlink Finder Page End=============*/
/*================Web Locator Page Start=============*/
.download_locator {
  margin-top: 60px;
}

.download_locator h2 {
  text-align: left;
  font-size: 45px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 50px;
}

.download_locator p {
  text-align: left;
  font: normal normal normal 20px/32px Sofia Pro;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
}

/*================Web Locator Page End=============*/
/*================Web Locator Page Start=============*/
.compatible_os h2 {
  text-align: left;
  font-size: 35px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 150px;
}

.compatible_os p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
}

.compatible_os span {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #000000;
}

.eight_col_line {
  border: 3px solid #db7221;
  opacity: 1;
  width: 50px;
  border-radius: 5px;
}

.support_virtual_device {
  background: url("../img/mobile-locator-finder/support-virtual-device-bg.jpg");
  background-position: center;
  background-size: cover;
  padding-bottom: 30px;
}

.support_virtual_device h2 {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 40px;
}

.support_virtual_device h3 {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 19px;
  letter-spacing: 0px;
  color: #ffffff;
}

.emulator h3 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.emulator p {
  text-align: center;

  font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 17px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.emulator img {
  margin-bottom: 20px;
}

.challenge_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #434db82b;
  border-radius: 10px;
  padding: 30px;
}

.challenge_box h3 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.challenge_box p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
}

/*================Web Locator Page End=============*/
/*================FireCrowd Page Start=============*/
.crowd_service {
  margin-left: 300px;
}

.crowd_testing_service h3 {
  text-align: center;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0px;
  color: #434db8;
}

.crowd_testing_service p {
  text-align: center;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 17px;
  letter-spacing: 0px;
  color: #282828;
  padding: 27px;
  margin-top: -5px;
}

.test_service_left_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #a3aaf73d;
  border: 1px solid #a3aaf73d;
  border-radius: 24px;
  padding: 50px;
  text-align: center;
  width: 645px;
  position: absolute;
  z-index: -1;
}

.test_service_right_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #a3aaf73d;
  border: 1px solid #a3aaf73d;
  border-radius: 24px;
  padding: 50px;
  text-align: center;

  margin-left: -80px;
  width: 645px;
  position: absolute;
  z-index: -1;
}

.relative_box {
  position: relative;
}

.various_device {
  background: url("../img/firecrowd/various-device.png");
  background-position: center;
  background-size: cover;
  margin-top: 170px;
  margin-left: -5px;
}

.various_device h3 {
  text-align: center;

  font-weight: 600;
  font-size: 64px;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
}

.various_device p {
  text-align: center;

  font-weight: normal;
  font-size: 24px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 30px;
}

.various_device p {
  text-align: center;

  font-weight: normal;
  font-size: 24px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 30px;
}

.various_device img {
  display: flex;
  margin: auto;
  padding-top: 40px;
}

.scrollto_text span {
  border-left: 2px solid #484db8;
  display: inline-block;
  height: 20px;
  margin-top: 40px;
  padding: 5px;
}

.scrollto_text li {
  list-style: none;
  margin-left: -40px;
  text-align: left;

  font-family: Poppins, medium;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0f0f0f;
}

.scrollto_text {
  display: flex;
  justify-content: space-between;
}

.left_bar {
  border-left: 2px solid #484db8;
  height: 25px;
}

.scroll h5 {
  text-align: left;
  margin-top: 3px;
  font-family: Poppins, medium;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0f0f0f;
  margin-left: 5px;
  margin-right: 10px;
}

.show_more_btn {
  border: none;
  background: none;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 20px;
  float: right;
}

/*================FireCrowd Page End =============*/
/*================UX Page Start=============*/
.ux_banner {
  background: url("../img/ux-testing/ux-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.how_ux_works h2 {
  text-align: center;
  font-size: 30px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
}

.how_ux_works p {
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
}

.how_ux_works h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #454db2;
}

.ux_features_text_color {
  color: #454db2 !important;
}

.how_ux_works h4 {
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #2a2a2a;
}

.ux_fireflink_client {
  text-align: left;
  font: normal normal normal 17px/26px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 5px;
  margin-top: -5px;
}

.ux_fireflink_client_flex {
  display: flex;
  margin-top: 18px;
}

.ux_fireflink_tick_icon {
  width: 18px;
  height: 18px;
}

.ux_fireflink_img_zindex {
  z-index: 6;
}

.ux_fireflink_single_left_img {
  margin-left: -45px;
}

/*================UI Page Start=============*/
.ui_banner {
  background: url("../img/ui-testing/ui-testing-background-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/*================Schedule Demo  Page Start=============*/

.schedule_demo_banner {
  background: url("../img/schedule-demo/schedule-demo-banner.png");
  background-position: center;
  background-size: cover;
  height: 720px;
  margin-bottom: 480px;
}

.demo_include_box {
  background: #434db8;
  border-radius: 16px 0px 0px 16px;
  padding: 25px;
  padding-bottom: 116px;
  height: auto;
  box-shadow: 0px 0px 10px #070d4b7a;
}

.demo_include_box h3 {
  text-align: left;
  font-size: 24px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.demo_include_box img {
  width: 20px;
  height: 20px;
}

.demo_include_box p {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  font-family: Poppins, Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 8px;
  margin-top: -5px;
}

.schedule_demo_box {
  background: #ffffff;
  box-shadow: 0px 0px 10px #070d4b7a;
  border-radius: 0px 16px 16px 0px;
  padding: 25px;
  height: auto;
}

.schedule_demo_box h3 {
  text-align: left;
  font-size: 24px;
  font-family: Poppins, medium;
  letter-spacing: 0px;
  color: #363636;
  margin-left: -7px;
}

/* .schedule_demo_box span {
  color: red;
} */
.book_demo_btn {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 1;
  align-items: center;
  color: #ffffff;
  width: 283px;
  padding: 5px;
  padding-left: 15px;
}

.button_disable_demo {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border: 1px solid #db7221;
  border-radius: 45px;
  opacity: 0.5;
  color: #ffffff;
  padding: 5px 20px 5px 15px;
}

.form-check .term_text {
  text-align: left;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 16px;
  letter-spacing: 0px;
  color: #1592e6;
}

.form-check a:hover {
  color: #1592e6;
}

.red {
  color: red !important;
}

.process_img {
  position: relative;
}

.process_dot {
  position: absolute;
}

.nlpux {
  margin-left: 90px;
}

.nlp_list {
  margin-left: 53px;
  color: black;
}

.nlp_list img {
  margin-right: 5px;
}

.how_ux_works ul {
  list-style: none;
}

.automationux {
  margin-left: 30px;
}

.automation_list img {
  margin-right: 5px;
}

.ul_text_color {
  color: #000000;
  font-weight: 500;
}

.ul_li_text_color {
  color: #000000;
  font-weight: 400;
}

.client_ux {
  margin-top: 65px;
  margin-left: 90px;
}

.client_ux ul {
  margin-left: -40px;
}

.client_ux img {
  margin-right: 5px;
}

.price_body h4 {
  text-align: left;
  font: normal normal 600 20px/24px Poppins;
  letter-spacing: 0px;
  color: #282828;
}

.ux_single_thread {
  background: url("../img/ux-testing/rectangle-left.svg");
  height: 519px;
  padding-top: 80px;
  padding-left: 150px;
  /* margin-right: -36px; */
  background-repeat: no-repeat;
}

.ux_single_thread_left {
  background: url("../img/ux-testing/rectangle-right.svg");
  height: 519px;
  padding-top: 80px;
  padding-left: 80px;
  margin-left: -47px;
  background-repeat: no-repeat;
}

/* .single_left_img {
  margin-left: -119px;
} */
.ux_single_thread h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #282828;
}

.ux_single_thread span {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #db7221;
}

.ux_single_thread p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}

.ux_single_thread_left h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #282828;
}

.ux_single_thread_left span {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  color: #db7221;
}

.ux_single_thread_left p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}

/*================UX Page End=============*/

/*================Desktop testing Page Start=============*/

.desktop_ux {
  margin-top: 65px;
  margin-left: 90px;
}

.desktop_ux ul {
  margin-left: -40px;
}

.desktop_ux img {
  margin-right: 5px;
}

.desktop_banner {
  background: url("../img/desktop-app-testing/desktop-banner.png");
  background-position: center;
  background-size: cover;
  height: 700px;
}

/*================Desktop testing Page End=============*/

.thank_you {
  background: url("../img/home/thank-you-banner.png");
  background-position: center;
  background-size: cover;
  height: 400px;
  margin-bottom: 250px;
}

.thank_box {
  background: #ffffff;
  box-shadow: 0px 0px 10px #070d4b7a;
  border-radius: 16px;
  margin-top: 270px;
  padding: 50px;
  margin-right: 60px;
  margin-left: 90px;
  text-align: center;
}

.ok_btn {
  background: #db7221;
  border-radius: 33px;
  width: 70px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.thank_box h2 {
  font-size: 30px;
  font-weight: 500;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #000000;
}

.thank_box p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000;
}

.template_store {
  background: url("../img/assets/template-store-bg.png");
  background-position: center;
  background-size: cover;
}

.template_store h2 {
  text-align: center;
  font: normal normal medium 45px/68px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 50px;
}

.template_store p {
  text-align: left;
  font: normal normal 400 20px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}

.template_store ul li {
  text-align: left;
  font: normal normal 400 20px/42px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  list-style: none;
  margin-left: -40px;
}

.platformfaq a:hover {
  text-align: center;
  font: normal normal normal 20px/24px Poppins, Regular;
  letter-spacing: 0px;
  color: #1592e6;
}

.template_store ul li {
  text-align: left;
  font: normal normal 400 20px/42px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  list-style: none;
  margin-left: -40px;
}

.platformfaq a:hover {
  text-align: center;
  font: normal normal normal 20px/24px Poppins, Regular;
  letter-spacing: 0px;
  color: #1592e6;
}

.assetsbanner {
  background: url("../img/assets/assets-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 660px;
}

.firecloud_banner {
  background: url("../img/cloud/cloud-banner.png");
  background-position: center;
  background-size: cover;
  height: 720px;
}

.cursor_pointer {
  cursor: pointer !important;
}

.beta {
  color: #db7221;
  font-size: 13px;
  margin-top: -5px;
  margin-left: 80px;
  font-family: Poppins, Regular;
  font-weight: 500;
  margin-bottom: -10px;
}

.menu_beta {
  color: #db7221;
  font-size: 13px;
  margin-top: -15px;
  font-family: Poppins, Regular;
  font-weight: 500;
  margin-bottom: -5px;
}

.logo_beta {
  color: #ffffff;
  font-size: 11px;
  margin-top: -13px;
  margin-left: 113px;
  font-family: Poppins, Regular;
  font-weight: 600;
}

.navbar_fixed .header_area .sticky_logo .logo_beta {
  color: #434db8;
  font-size: 11px;
  margin-top: -13px;
  margin-left: 113px;
  font-family: Poppins, Regular;
  font-weight: 600;
}

.blog_img1 {
  width: 40px;
  height: 40px;
}

/*============ Over riding phone input library css =============== */

.react-tel-input .selected-flag {
  width: 45px !important;
}

.react-tel-input .selected-flag:hover {
  background: #eee;
  border-right: 1px solid #eee;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.signup_phone .react-tel-input .form-control {
  width: 100% !important;
}

.signup_phone .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 0.1rem #1976d2 !important;
}

.signup_phone .react-tel-input:hover {
  -webkit-tap-highlight-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
}

.signup_phone .react-tel-input {
  -webkit-tap-highlight-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
}

.sign_up .form-control:focus {
  box-shadow: 0 0 0 0.1rem #1976d2 !important;
}

.signup_phone phone-input-container.error .react-tel-input .form-control {
  border: 1px solid #c50303 !important;
  /* Apply red border color when error state is true */
}

.react-tel-input .country-list .search-emoji {
  display: none;
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0 6px 10px;
}

.react-tel-input .country-list .search-box {
  width: 90% !important;
  font-size: 12px !important;
  opacity: 0.5 !important;
}

.react-tel-input .country-list {
  width: 360px !important;
  height: 236px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.160784);
  text-align: left;
  position: relative !important;
  background-color: #fbfcfd;
}

.signup_phone .react-tel-input .country-list {
  width: 250px !important;
}

.react-tel-input .country-list .country {
  position: relative;
  padding: 12px 9px 13px 46px !important;
}

.react-tel-input .country-list .flag {
  /* display: inline-block; */
  margin-right: 4px !important;
  margin-top: 13px !important;
  position: absolute;
  left: 13px;
  top: 8px;
}

.react-tel-input .flag-dropdown {
  width: 45px;
  /* border:0px !important */
}

.react-tel-input .flag {
  /* width: 25px !important;
  height: 20px !important; */
  background-repeat: no-repeat !important;
}

.MuiTextField-root {
  -webkit-tap-highlight-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
}

/* ===============Over riding phone input library css ends here================  */
/* ===================== Case Study Start ===============*/
.case_line {
  border-left: 1px solid #db7221;
  margin-left: 30px;
  height: 100%;
}

.case_round {
  border: 1px solid #db7221;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: 26px;
}

.case_study_landing h2 {
  text-align: left;
  font-size: 35px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
}

.case_study_landing p {
  text-align: left;
  font-size: 17px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
}

.case_study_landing img {
  width: 333px;
  height: 333px;
}

.case_study h5 {
  text-align: left;
  font-size: 20px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
  margin-left: 15px;
}

.case_study p {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  padding-left: 15px;
  padding-right: 10px;
}

.case_read_more {
  background: #ffffff;

  color: #db7221;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins, SemiBold;

  margin-left: 15px;
}

.case_read {
  border: 1px solid #db7221;
  border-radius: 18px;
  width: 120px;
}

.case_read a:hover {
  color: #db7221;
}

.case_read img {
  width: auto;
  height: auto;
  padding: 0px;
  margin-left: 5px;
}

.case_study {
  padding-bottom: 25px;
}

/* ===================== Case Study End===============*/
/* This is used to hide the expand icon in text area */
.textarea1 {
  resize: none;
}

.textarea_count {
  float: right;
}

/* summary::-webkit-details-marker {
  display: none !important;
}

summary::before {
  content: '';
  display: inline-block;
  margin-right: 0.5em;
  font-weight: bold;
}
summary::marker {
  display: none !important;
}
::marker{
    display: none !important;
} */

/* ========== Video Tutorials ===================*/
.video_banner {
  background: url("../img/video/video-banner.png");
}

.video_body_bg {
  background: url("../img/video/sign-up.png");
  background-position: center;
  background-size: cover;
}

.video_head {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #282828;
  margin-left: -15px;
}

.video_layout h3 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 10px;
  margin-left: 50px;
}

.video_layout p {
  text-align: left;
  font-family: Poppins, Regular;
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #282828;
  margin-left: 50px;
  margin-top: -15px;
  line-height: normal;
}

.video_icon_tut {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background-color: white; */
  border: 2px solid #ffffff;
  /* box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14); */
  text-align: center;
  line-height: 57px;
  font-size: 25px;
  position: relative;
  transition: all 0.3s linear;
  /* display: inline-block; */
  margin-left: 140px;
  cursor: pointer;
  margin-top: -109px;
  padding-left: 3px;
}

.video_icon_tut i {
  color: #ffffff;
}

.video_line {
  width: 50px;
  border: 3px solid #db7221;
  border-radius: 10px;
  margin-left: 50px;
}

.video_circle {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border: 1px solid #434db8;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.video_line_up {
  border-left: 1px solid #434db8;
  margin-left: 15px;
}

.video_home_content {
  border: 1px solid #434db824;
  border-radius: 20px;
  margin-left: -40px;
  margin-top: 30px;
}

.video_right_border {
  border-bottom: 1px solid #434db8;

  width: 25px;
  margin-top: 113px;
}

.video_home_content h2 {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 30px;
  margin-left: 100px;
}

.video_home_content p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
  margin-left: 100px;
}

.video_layout_back {
  width: 330px;
  margin-top: 15px;
}

.video_icon_tut .icon {
  font-size: 40px;
  color: #ffffff;
  margin-top: -7px;
  margin-left: 1px;
}

.title_hover_color:hover {
  color: #db7221;
  text-decoration: underline;
}

/* ========== Video Tutorials ===================*/
summary::after {
  content: "" !important;
  display: inline-block !important;
  margin-right: 0.5em !important;
  font-weight: bold;
}

span.sidebar-menu-toggle-icon {
  background: url("../img/home/arrow-right.png");
}

.video_left_layout {
  background: #ffffff;
  box-shadow: 0px 0px 6px #09105e21;
  border-radius: 8px;
  margin-left: -30px;
  margin-right: -30px;
}

.video_layout {
  background: #ffffff;
  box-shadow: 0px 0px 12px #09105e29;
  border-radius: 4px;
  margin-right: 1px;
}

/* changed here */
.video_side h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #282828;
  margin-left: -15px;
}

.video_side h4 {
  text-align: left;
  font-size: 15px;
  /* font-weight: normal; */
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #5e5e5e;
  font-weight: 500;
  margin-left: 5px;
}

.video_side h5 {
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #8c8c8c;
  margin-left: -8px;
}

.video_home img {
  margin-left: -45px;
  position: relative;
  z-index: 1;
  margin-top: 15px;
}

.gOMCKd {
  margin-top: -6px;
}

/* .video_side .dUoKAY::after {
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block;
  padding: 3px !important;
  transform: rotate(-135deg) !important;
  -webkit-transform: rotate(-135deg) !important;
}
.video_side .gOMCKd::after {
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block;
  padding: 3px !important;
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
} */

/* changed here for background color */
.right_line {
  border-left: 1px solid #eee;
  margin-top: 0;
  margin-bottom: 0;

  /* background-color: #fbfbfb; */
}

.font_size_override {
  font-weight: 100 !important;
}

.image_center_position {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* ===================== Documentation Start===============*/
.doc_banner {
  background-image: url("../img/docs/docs-banner.png");
}

.docs_head_two {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #282828;
}

.docs p {
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
}

.docs h3 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #282828;
}

.italic_para {
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Italic;
  letter-spacing: 0px;
  margin-bottom: 20px;
}

.docs h4 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282828;
}

.docs h5 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  margin-bottom: 20px;
}

/* .steps .step_tick {
  background-color: #DB7221;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  
  } */
.steps i {
  background: #db7221;
  border-radius: 50%;
  color: #ffffff;
  height: 20px;
  height: 20px;
  width: 20px;
  font-size: 13px;
  text-align: center;
  padding-top: 4px;
  font-weight: 600;
  margin-top: 4px;
}

.docs li {
  text-align: left;
  list-style: none;
  font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282828;
  width: fit-content;
  margin-left: 10px;
}

.stpes li {
  text-align: left;
  list-style: none;
  font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  width: fit-content;
  color: #282828;
  margin-left: 10px;
}

.steps span {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282828;
}

.docs_img {
  width: 100%;
  /* margin-left: 47px; */
}

.underline {
  text-decoration: underline;
}

.doc_home h2 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 35px;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 30px;
}

.doc_home p {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #282828;
}

.doc_line {
  border: 3px solid #db7221;
  width: 60px;
  border-radius: 5px;
  margin-top: -5px;
  margin-left: 17px;
}

.doc_right_border {
  border-right: 2px solid #eee;
  margin-left: -2px;
}

.doc_home h3 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 40px;
}

.doc_home h4 {
  text-align: left;
  font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0px;
  color: #282828;
}

.docs_link {
  color: #434db8;
}

.docs_link:hover {
  color: #434db8;
}

.docs_home_img {
  position: relative;
  padding: 30px;
  margin-left: -5px;
  width: 100%;
}

.docs_home_txt {
  position: absolute;
  z-index: 1;
  margin-top: 50px;
  margin-left: 180px;
  padding: 15px;
  margin-right: 70px;
}

.bread_active_page a {
  color: #434db8;
}

.bread_home_cursor a {
  cursor: pointer !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
  margin-left: -9px;
}

.table-bordered thead td,
.table-bordered thead th {
  /* border-bottom-width: 2px; */
  background: #434db8;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #434db8;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #282828;
  padding-left: 30px;
}

/* .table {
  width: 85%;
} */

.hTMLBE {
  margin-bottom: -10px;
}

.JDFFQ {
  margin-bottom: -15px;
}

.dWaQdM {
  margin-bottom: -15px;
}
.docs_ordered_list li {
  list-style: decimal !important;
}

/* ===================== Documentation End===============*/

/* ===================== Release Notes Start===============*/
.release_banner {
  background: url(../img/blogs/release-banner.jpg);
}

.versions_round {
  background-color: #434db8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.versions_line {
  border-left: 1px solid #434db8;
  height: 100%;
  margin-left: 5px;
}

.release_version h2 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0px;
  color: #282828;
  margin-top: -30px;
}

.release_version span {
  text-align: left;
  font-family: Poppins, Medium;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
}

.release_version h3 {
  text-align: left;
  font-family: Poppins, Medium;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 10px;
  margin-top: 10px;
}

.release_version h4 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282828;
}

.release_version p,
.release_version li {
  text-align: left;
  font-family: Poppins, Medium;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  line-height: normal;
  margin-left: 40px;
}

.release_version h5 {
  text-align: center;
  font-family: Poppins, Medium;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0px;
  color: #707070;
  line-height: normal;
}
.ff_client_img {
  width: 20px;
  height: 20px;
  margin-top: 12px;
  margin-right: -5px;
}
.updates_img {
  width: 415px;
  height: 180px;
}
/* ===================== Release Notes End===============*/

.error_banner {
  background-image: url("../img/home/404.png");
  background-position: center;
  background-size: cover;
  height: 700px;
}

.error_banner h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
}

.error_banner p {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
}

.back_to_home_btn {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50px;
  color: #ffffff;
  padding: 10px;
}

.back_to_home {
  margin-top: 483px;
  margin-bottom: 30px;
}

.fixed_menu {
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.active_label {
  color: #434db8;
}

.active_bg_color {
  background-color: #f3f4ff;
  padding-left: 10px;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  border-radius: 3px;
  margin-left: -7px;
}

.reader_view {
  margin-top: 28px;

  position: absolute;
  z-index: 1;
  margin-left: 710px;
}

.cursor_pointer {
  cursor: pointer;
}

.home_icon_docs {
  margin-bottom: 6px;
  font-size: 15px;
  cursor: pointer !important;
  pointer-events: fill;
}

/* ===================== Sap Solution Start ===============*/
.sap_banner {
  background: url("../img/solution/sap/sap-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.challenges_box {
  background: #ffffff;
  box-shadow: 0px 0px 26px #434db82b;
}

.challenges_box h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #7e7b7b;
  margin-top: 60px;
}

.challenges_box h4 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 60px;
}

.grey_line {
  border-left: 1px solid #eee;
  margin-left: -20px;
  height: auto;
}

.grey_line img {
  width: 17px;
  height: 17px;
  margin-left: -9px;
  margin-top: 65px;
}

.challenges_box p {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  line-height: 2.5;
}

.active_menu_color {
  color: #4347ad !important;
}

.key_features_height {
  height: 50% !important;
}

.sap_sol_box {
  background: url("../img/solution/sap/ui-complex.png");
  background-position: center;
  background-size: cover;
  height: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.ai_bg {
  background: url("../img/solution/sap/ai_bg.png");
  background-position: center;
  background-size: cover;
  height: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.nocode_bg {
  background: url("../img/solution/sap/no-code-bg.png");
  background-position: center;
  background-size: cover;
  height: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.testdev_bg {
  background: url("../img/solution/sap/test-dev-bg.png");
  background-position: center;
  background-size: cover;
  height: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.solution_box h3 {
  text-align: left;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  font-size: 22px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 29px;

  margin-top: 30px;
}

.ui_challenge {
  background: url("../img/solution/sap/ad-ai.png");
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-top: 7px;
  margin-left: 5px;
}

.ad_ai {
  background: url("../img/solution/sap/ad-ai.png");
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-top: 7px;
  margin-left: 5px;
}

.no_code {
  background: url("../img/solution/sap/no-code.png");
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-left: 5px;
  margin-top: 7px;
}

.test_dev {
  background: url("../img/solution/sap/test-dev.png");
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-left: 5px;
  margin-top: 7px;
  margin-right: 5px;
}

.solution_box p {
  text-align: left;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 60px;
  margin-top: 60px;
  margin-right: 40px;
  line-height: 2;
}

.active_bar {
  border: 2px solid #db7221;
  width: 100%;
}

.active_sol_color {
  color: #db7221 !important;
}

.bg_none {
  background: none !important;
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-left: 5px;
  margin-top: 7px;
  margin-right: 5px;
}

/* ===================== Sap Solution End===============*/

/* ===================== Salesforce Solution Start ===============*/

.integration_testing_bg {
  background: url("../img/solution/salesforce/integration_testing_bg.png");
  background-position: center;
  background-size: cover;
  height: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.integration_testing {
  background: url("../img/solution/salesforce/integration_testing.png");
  background-position: center;
  background-size: cover;
  height: 220px;
  width: 100%;
  margin-left: 5px;
  margin-top: 7px;
}

.salesforce_banner {
  background: url("../img/solution/salesforce/salesforce-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== Salesforce Solution End===============*/

/* ===================== MsDynamics Solution Start ===============*/

.msdynamics_banner {
  background: url("../img/solution/msdynamics/msdynamics_banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== MsDynamics Solution End===============*/

/* ===================== Peoplesoft Solution Start ===============*/

.peoplesoft_banner {
  background: url("../img/solution/peoplesoft/peoplesoft_banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== Peoplesoft Solution End===============*/

/* ===================== workday Solution Start ===============*/
.workday_banner {
  background: url("../img/solution/workday/workday-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== workday Solution End ===============*/

/* ===================== servicenow Solution Start ===============*/
.servicenow_banner {
  background: url("../img/solution/servicenow/service-now-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== servicenow Solution End ===============*/

/* ===================== oracle Solution Start ===============*/
.oracle_banner {
  background: url("../img/solution/oracle/oracle-banner.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* ===================== oracle Solution End ===============*/

/* ===================== Appium Start ===============*/
.appium_banner {
  background: url("../img/alternative/appium/selenium-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

.solution_banner h1 {
  text-align: right;
  font-size: 35px;
  font-weight: bold;
  font-family: Poppins, Bold Italic;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 130px;
}

.solution_banner h1 span {
  text-align: right;
  font-size: 45px;
  font-weight: bold;
  font-family: Poppins, SemiBold Italic;
  letter-spacing: 0px;
  color: #ffffff;
}

.solution_banner h2 {
  text-align: right;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold Italic;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 20px;
}

.solution_banner h3 {
  text-align: right;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold Italic;
  letter-spacing: 0px;
  color: #ffc300;
}

.ff_logo {
  margin-top: 120px;
  margin-left: 50px;
}

.vs_logo {
  margin-top: -20px;
  margin-left: 20px;
}

.appium_logo {
  margin-top: -80px;
  margin-left: -8px;
}

.challenge_appium h2 {
  text-align: left;
  font-family: Poppins, Medium;
  font-size: 30px;
  letter-spacing: 0px;
  color: #434db8;
}

.challenge_appium h4 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #000000;
}

.challenge_appium p {
  text-align: left;
  font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
}

.challenge_appium h3 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #db7221;
}

.challenge_appium h3 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  color: #db7221;
}

.limitation_img {
  width: 480px;
  height: 490px;
}

.challenges_img {
  width: 430px;
  height: 510px;
}

.diff_title {
  background: #ffffff;
  box-shadow: 0px 0px 16px #cecaca;
  border-radius: 32px;
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  padding: 30px;
  margin-left: 40px;
  padding-left: 60px;
  padding-top: 15px;
  padding-bottom: 40px;
  margin-top: 15px;
}

.feat_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 20px;
  margin-top: -33px;
}

.ff_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 0px 0px 20px 20px;
  margin-left: -100px;
  margin-right: 64px;
}

.alt_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 0px 0px 20px 20px;
  margin-left: -60px;
  margin-right: 25px;
}

.grey_box {
  background: #797a84;
}

.yes_img {
  width: 21px;
  height: 21px;
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 3px;
}

.diff_table p {
  text-align: left;
  font-size: 15px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  line-height: normal;
  padding-right: 15px;
}

.bottom_grey {
  border-top: 1px solid lightgray;
}

.ff_img {
  margin-top: -60px;
  margin-left: -100px;
}

.appium_img {
  margin-left: -60px;
  margin-top: -33px;
}

.alt_feat_box {
  background: #ffffff;
  box-shadow: 0px 0px 26px #434db82b;
  border-radius: 10px;
  height: 100%;
}

.img_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alt_feat_box h4 {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  padding: 30px;
  margin-bottom: 25px;
}

/* ===================== Appium End===============*/

/* ===================== Katalon Start===============*/
.katalon_banner {
  background: url("../img/alternative/katalon/katalon-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== Katalon End===============*/
/* ===================== Pre Requisites Start===============*/
.pre_req_banner {
  background: url("../img/pre-req/pre-req-banner.png");
  background-position: center;
  background-size: cover;
}

.pre_req_bg {
  background: #ffffff;
  box-shadow: 0px 0px 8px #434db829;
  border-radius: 10px 300px 300px 10px;
  display: flex;
  margin-left: 80px;
  margin-top: 20px;
  width: 100%;
}

.pre_req_bg img {
  margin-left: -80px;
}

.pre_req_bg h2 {
  text-align: left;
  font-family: Poppins, SemiBold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  margin-top: 30px;
  margin-right: 210px;
}

.pre_req_bg p {
  font-family: Poppins, Regular;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  text-align: left;
  margin-right: 80px;
}

.pre_req_line {
  border-bottom: 4px solid #db7221;
  width: 8%;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* ===================== Pre Requisites End===============*/
/* ===================== leapwork Start===============*/
.leapwork_banner {
  background: url("../img/alternative/leapwork//leapwork-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== leapwork End===============*/

/* ===================== selenium Start===============*/
.selenium_banner {
  background: url("../img/alternative/selenium/selenium-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== selenium End===============*/

/* ===================== testim Start===============*/
.testim_banner {
  background: url("../img/alternative/testim/testim-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== testim End===============*/

/* ===================== testrigor Start===============*/
.test_rigor_banner {
  background: url("../img/alternative/testrigor/test-rigor-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== testrigor End===============*/

/* ===================== testsigma Start===============*/
.test_sigma_banner {
  background: url("../img/alternative/testsigma/test-sigma-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== testsigma End===============*/

/* ===================== tosca Start===============*/
.tosca_banner {
  background: url("../img/alternative/tosca/tosca-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== tosca End===============*/

/* ===================== test complete  Start===============*/
.test_complete_banner {
  background: url("../img/alternative/testcomplete/test-complete-banner.png");
  background-position: center;
  background-size: cover;
  height: 570px;
  background-repeat: no-repeat;
}

/* ===================== test complete End===============*/

.cookie_head {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
}

.cookie_para {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.cookie_para span {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  /* color: #DB7221; */
}

/* #rcc-decline-button {
  background: #434DB8 !important;
border: 2px solid #FFFFFF !important;
border-radius: 2px !important;
font-family: Poppins, SemiBold !important;
font-size: 17px !important;
font-weight: 600 !important;
letter-spacing: 0px !important;
color: #FFFFFF !important;
margin: 0 !important;


} */
/* #rcc-confirm-button {
  background: #FFFFFF !important;
border: 2px solid #FFFFFF !important;
border-radius: 2px !important;
text-align: left;
font-size: 17px !important;
font-weight: 600 !important;
font-family: Poppins, SemiBold !important;
letter-spacing: 0px !important;
color: #434DB8 !important;
} */
.CookieConsent {
  align-items: last baseline !important;
}

.decline_button {
  background: #434db8 !important;
  border: 2px solid #ffffff !important;
  border-radius: 2px !important;
  font-family: Poppins, SemiBold !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  margin: 0 !important;
}

.accept_btn {
  background: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 2px !important;
  text-align: left;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: Poppins, SemiBold !important;
  letter-spacing: 0px !important;
  color: #434db8 !important;
}

.accept_btn_popup {
  background: #2d4156 !important;
  border: 2px solid #ffffff !important;
  border-radius: 10px !important;
  text-align: left;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: Poppins, SemiBold !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
}

.reject_btn_popup {
  background: #eaeff2 !important;
  border: 2px solid #ffffff !important;
  border-radius: 10px !important;
  text-align: left;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: Poppins, SemiBold !important;
  letter-spacing: 0px !important;
  color: #2d4156 !important;
}

.save_btn_popup {
  background: #eaeff2 !important;
  border: 2px solid #ffffff !important;
  border-radius: 10px !important;
  text-align: left;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: Poppins, SemiBold !important;
  letter-spacing: 0px !important;
  color: #2d4156 !important;
  width: 145px;
  height: 42px;
  padding: 6px 10px;
  cursor: pointer;
}

/*============ Sign Up Start====================*/
.sign_up {
  background: url("../img/Registration/create-account-bg.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.sign_up h1 {
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  font-family: Poppins, Bold;
  letter-spacing: 0.54px;
  color: #ffffff;
  text-transform: uppercase;
}

.sign_up_content {
  margin-top: 100px;
  margin-left: 16px;
  margin-right: 110px;
}

.sign_up h2 {
  text-align: left;
  font-weight: 500;
  font-size: 19px;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.54px;
  color: #ffffff;
}

.sign_up p {
  text-align: left;
  font-weight: medium;
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0.31px;
  color: #ffffff;
}
.accountLocked {
  padding: 10px 20px;
}
.accountLocked a {
  padding: 5px 90px !important;
}
.signup_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 13px;
  height: 100%;
  padding-top: 24px;
}

.signup_field {
  padding-left: 45px;
  padding-right: 45px;
  height: 70px;
}

.signup_wrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 31px;
  padding-right: 31px;
  justify-content: space-between;
  min-width: 200px;
}

.signup_box h2 {
  text-align: center;
  font-weight: bold;
  font-family: Poppins, Bold;
  font-size: 17px;
  letter-spacing: 0px;
  color: #434db8;
}

.signup_line {
  border-bottom: 2px solid #db7221;
  width: 8%;
  margin: auto;
  border-radius: 5px;
}

.register_txt {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 115px;
  padding-right: 115px;
}

.signing_in {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 300px;
}

.signup_row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.term_txt h5 {
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.18px;
  color: #989797;
  line-height: normal;
}

.term_txt p span {
  text-align: left;
  font-size: 10px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.18px;
  color: #434db8;
}

.already_acc p {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #323232;
}

.already_acc a {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
}

.forgot_txt {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
  border: none;
  background: none;
  box-shadow: none;
  z-index: 1;
  position: absolute;
  margin-left: -105px;
  margin-top: -4px;
}

.sussess_txt {
  text-align: center;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  font-size: 26px;
  letter-spacing: 0.47px;
  color: #79b62f;
  opacity: 1;
}

.success_para p {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.25px;
  color: #263248;
  line-height: normal;
}

.success_para p span {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.25px;
  color: #263248;
}

.resend_btn {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.back_to_web {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border: 1px solid #db7221;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.29px;
  color: #db7221;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 53px;
  padding-right: 53px;
}

.otp_screen {
  display: flex;
  justify-content: space-evenly;
  margin-left: 30px;
  margin-right: 30px;
  height: 50px;
}

.otp_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  width: 53px;
  height: 46px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.29px;
  color: #434db8;
}

#otp input:focus {
  outline: none;
  border: 1px solid #434db8;
  box-shadow: 0px 0px 16px #00000040;
  color: #434db8;
  border-radius: 7px;
}

.verify_code h3 {
  text-align: center;
  letter-spacing: 0.47px;
  font-size: 26px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  color: #3c3838;
}

.verify_code p {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.25px;
  color: #263248;
  line-height: normal;
  margin-left: 47px;
  margin-right: 52px;
}

.verify_code a {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.25px;
  color: #434db8;
}

.verify_code .change-phone-link {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.25px;
  color: #434db8;
  cursor: default;
}

.new_otp p {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  font-family: Poppins, Regular;
  letter-spacing: 0.22px;
  color: #263248;
}

.new_otp a {
  font-weight: 600;
  font-size: 12px;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
}

.verify_continue {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 76px;
  padding-right: 76px;
}

.verifying {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 105px;
  padding-right: 105px;
}

.verify_continue:hover {
  color: #ffffff;
}

.verification_error {
  position: absolute;
  margin-left: -50px;
  margin-top: -5px;
}

.change_number {
  background: #db7221;
  box-shadow: 0px 0px 14px #0000003b;
  border: 1px solid #db7221;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 60px;
  padding-right: 60px;
}

.change_phone_validation {
  position: absolute;
  margin-top: -6px;
}

.signup_box h4 {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 17px;
  letter-spacing: 0.31px;
  color: #f8981c;
}

.user_active {
  background: #f3f6ff;
  border-radius: 10px;
}

.user_active p {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.29px;
  color: #263248;
  line-height: normal;
}

.form_label {
  font-size: 12px;
  color: #323232;
  font-family: Poppins, Regular;
  font-weight: 400;
  margin-bottom: -3px !important;
  position: absolute;
  margin-top: -10px;
}

.form_label_forgot {
  font-size: 12px;
  color: #323232;
  font-family: Poppins, Regular;
  font-weight: 400;
  margin-bottom: -3px !important;
}

.placeholder_txt {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #c4c3c3;
  opacity: 1;
}

input[type="text"]::placeholder {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #c4c3c3;
}

input[type="password"]::placeholder {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #c4c3c3;
}

input[type="tel"]::placeholder {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #c4c3c3;
}

#projecttype {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #000000;
  margin-top: -3px;
}

.add_phone_txt {
  /* float: right; */
  font-size: 8px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.14px;
  color: #989797;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: -43px;
  margin-top: -7px;
  position: absolute;
  z-index: 1;
}

.text-blue-700 {
  font-size: 8px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.18px;
  color: #434db8;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: -43px;
  margin-top: -7px;
  position: absolute;
  z-index: 1;
}

.remove_txt {
  font-size: 8px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.18px;
  color: #c50303;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: -48px;
  margin-top: -9px;
  position: absolute;
  z-index: 1;
}

.text-red-500 {
  text-align: left;
  font-size: 8px !important;
  font-weight: 500 !important;
  font-family: Poppins, Regular !important;
  letter-spacing: 0px !important;
  color: #c50303 !important;
}

.phone_validation p {
  text-align: left;
  font-size: 8px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #c50303 !important;
}

.info svg {
  width: 0.7rem !important;
  height: 0.7rem !important;
  margin-left: 2px;
}

.pos {
  position: absolute;
  margin-left: -13px;
}

.rc-anchor-error-msg-container .rc-anchor-normal .rc-anchor-content {
  width: 0px !important;
}

.text-green-500 {
  text-align: left;
  font-size: 8px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #79b62f;
}

.copy_right_txt p {
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.18px;
  color: #e6e6e7;
}

.already_signin_txt {
  font-size: 9px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.16px;
  color: #434db8;
}

.info_icon svg {
  color: #989797;
  font-size: 15px;
  margin-left: 4px;
  margin-top: 2px;
}

.resend_activation_link_sent {
  font-size: 17px;
  font-family: Poppins, Medium;
  letter-spacing: 0.31px;
  color: #79b62f;
  font-weight: 600;
}

.resent_new_code_success {
  margin-left: 113px;
  margin-right: 113px;
}

.resend_new_code {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
}

.disable_resend_new_code {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
  cursor: default;
}

.tool_tip {
  line-height: normal;
  line-break: 2px;
}

.proceed_to_sign_activation {
  padding-left: 75px;
  padding-right: 74px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.send_reset_link {
  padding-left: 51px;
  padding-right: 51px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.back_to_sign {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 75px;
  padding-right: 75px;
}

.set_password {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 93px;
  padding-right: 93px;
}

.signup_orange_button {
  border-radius: 20px;
  background: #db7221 !important;
  box-shadow: 0px 0px 16px #00000029 !important;
  border: 1px solid #db7221 !important;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.29px;
  color: #ffffff;
}

.signup_white_button {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border: 1px solid #db7221;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #db7221;
}

.verification_err_msg {
  position: absolute;
  margin-left: -50px;
  margin-top: -6px;
}

.pwd_icon {
  width: 11px !important;
  height: 11px !important;
}

.resend_activation_link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 55px;
  padding-right: 55px;
}

.css-16fuem2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 35px !important;
}

.invalid_code {
  display: flex;
  justify-content: center;
  margin-top: -5px;
}

.term_check_box {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 2px solid #808080;
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.term_modal_dialog .modal-xl {
  margin-left: 175px;
  margin-right: 175px;
  height: 100%;
}

.term_modal_dialog .modal-content {
  background: #ffffff;
  border-radius: 15px;
  border: none;

  flex-grow: 1;
  overflow-y: auto;
}

.term_modal_dialog .modal-content p {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.22px;
  color: #3c3838;
  line-height: normal;
}

.term_modal_dialog .modal-content span {
  font-size: 14px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0.22px;
  color: #3c3838;
  margin-right: 10px;
}

.term_modal_dialog .modal-content h3 {
  font-size: 14px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0.25px;
  color: #3c3838;
}

.term_modal_dialog .modal-header {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 15px 15px 0px 0px;
  padding: 0;
  position: relative;
  border: none;
}

.term_modal_dialog .modal-header .modal-title {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.32px;
  color: #434db8;
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 10px;
}

.term_modal_dialog .modal-footer button {
  background: #db7221;
  box-shadow: 0px 0px 14px #0000003b;
  border: 1px solid #db7221;
  border-radius: 20px;
  padding-left: 35px;
  padding-right: 35px;
  margin-right: 35px;
}

.term_modal_dialog .modal-footer button:active {
  background: #db7221 !important;
  box-shadow: 0px 0px 14px #0000003b !important;
  border: 1px solid #db7221 !important;
}

.term_modal_dialog .modal-footer {
  border-top: none;
  position: relative;
  bottom: 0;
}

.term_modal_dialog {
  overflow: hidden !important;
  z-index: 10000 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  /* Set width of scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set color of scrollbar thumb */
  border-radius: 6px;
  /* Set border radius of scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff;
  /* Set color of scrollbar track */
}

.custom-scrollbar {
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Additional styling for the modal body */
.modal-body {
  max-height: calc(100vh - 150px);
  /* Adjust max height of modal body as needed */
}

.term_modal_dialog ul li {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.22px;
  color: #3c3838;
  line-height: normal;
}

.term_txt span {
  background: none;
  font-size: 10px;
  border: none;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.18px;
  color: #434db8;
  cursor: pointer;
}

.term_txt button:hover {
  background: none;
  border: none;
  color: #434db8;
  text-decoration: underline;
}

.term_txt button::active {
  background: none !important;
  border: none !important;
  color: #434db8 !important;
  text-decoration: underline;
  box-shadow: none !important;
}

.term_txt button:focus {
  background: none !important;
  border: none !important;
  color: #434db8 !important;
  text-decoration: underline;
  box-shadow: none !important;
}

.term_modal_dialog .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #434db8;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.32px;
  color: #ffffff !important;
  text-transform: uppercase;
}

.term_modal_dialog .nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.32px;
  color: #434db8;
  text-transform: uppercase;
  padding: 7px 16px 7px 16px;
  border: none;
}

.term_modal_dialog .nav-tabs {
  border: none;
}

/*============ Sign Up End ====================*/

.con_crnt_head {
  text-align: left;
  font-size: 25px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0.45px;
  color: #db7221;
}

.concrnt_head_bar {
  border: 1px solid #707070;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.nav_bg {
  background: #ffffff;
  box-shadow: 0px 0px 6px #09105e21;
  padding-top: 20px;
  padding-bottom: 20px;
}

.con_crcnt_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 10px;
  padding: 30px;
  margin-top: -30px;
}

.concrnt_form_label {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0.27px;
  color: #000000;
}

.concrnt_bottom_bar {
  border-bottom: 1px solid #70707021;
  margin-top: 20px;
}

.concrcnt_head_two {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, Regular;
  letter-spacing: 0.36px;
  color: #000000;
}

.concrnt_copyright_txt {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins, Regular;
  letter-spacing: 0.22px;
  color: #000000;
  margin-bottom: -80px;
}

.concrnt_center_bar {
  border: 1px solid #70707021;
  margin-left: -2px;
}

.profile_icon {
  background: #db7221 !important;
  border: 1px solid #ffffff8a !important;
  border-radius: 50% !important;
  font-size: 22px !important;
  font-weight: normal !important;
  font-family: Poppins, Regular !important;
  letter-spacing: 0px !important;
  color: #ffffff;
  cursor: pointer;
}

.profile_icon_sign {
  background: #db7221 !important;
  border: 1px solid #ffffff8a !important;
  border-radius: 50% !important;
  font-size: 22px !important;
  font-weight: normal !important;
  font-family: Poppins, Regular !important;
  letter-spacing: 0px !important;
  color: #ffffff;
}

.concrnt_info_icon .MuiSvgIcon-root {
  color: #aeaeae;
  margin-top: 7px;
  font-size: 19px;
}

.concrnt_head_txt {
  background: #f9fbff;
  border: 0.5px solid #434db830;
  margin-right: -5px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.concrnt_head_txt h1 {
  text-align: center;
  font-size: 14px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
  padding-left: 150px;
  padding-right: 150px;
}

.concrnt_head_txt {
  background: #f9fbff;
  border: 0.5px solid #434db830;
  margin-right: -5px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.concrnt_head_txt h1 {
  text-align: center;
  font-size: 14px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #434db8;
  padding-left: 150px;
  padding-right: 150px;
}

.tool_tip_txt {
  font-size: 13px;
}

.profile_name {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #7e7b7b;
  margin-bottom: -5px;
}

.profile_email {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
}

.profile_line {
  border: 1px solid #707070;
  opacity: 0.14;
  width: 90%;
  margin-left: 20px;
}

.sign_out {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.bot_content p {
  color: #434db8;
}

.concurrent_final_value_width {
  width: 75px;
  margin-top: 22px;
  margin-left: -50px;
}

.fireflink_con {
  display: block;
  width: 200px;
}

.concrnt_text_field {
  width: 35%;
  margin-left: 20px;
  margin-right: 10px;
}

.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
}

.popup-body {
  position: absolute;
  left: 10%;
  right: 30%;
  width: 80%;
  /* height: 100%; */
  bottom: 20%;
  top: 35%;
  text-align: center;
  margin: auto;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 71vh;
}

.popup_cookie_head {
  color: #000000;
  float: none;
  font-family: Poppins, SemiBold;
  font-size: 16px;

  font-variant: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: unset;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  transition: none;
  vertical-align: baseline;
  visibility: unset;
  margin-left: 40px;
  margin-top: 20px;
}

.popup_cookie_head_two {
  color: #000000;
  float: none;

  font-size: 16px;
  font-family: Poppins, SemiBold;
  font-variant: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: unset;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  transition: none;
  vertical-align: baseline;
  visibility: unset;
  margin-left: 40px;
  margin-top: 20px;
}

.close_btn {
  border: none;
  background: #eee;
  float: right;
  font-size: 1.45em;
  font-weight: 400;
  height: 1.7em;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1.7em;
  border-radius: 5px;
  margin-right: 20px;
  margin-top: 15px;
}

.popup_para {
  color: #2d4156;
  text-align: left;
  font-size: 14px;
  line-height: 1.5em;
  margin-top: 0.85em;
  margin-left: 40px;
}

.popup_para a {
  color: #434db8;
}

.popup_para a:hover {
  border-bottom: 1px solid #434db8;
}

.popup_accordian {
  padding: 40px;
}

.popup_accordian .tab-pane .card .card-header .btn i {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  font-size: 20px;
}

.popup_toggle {
  right: 0;
  position: absolute;
  margin-top: 20px;
}

.setting_txt {
  text-align: left;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  font-family: Poppins, SemiBold;
}

.setting_para {
  text-align: left;
  font-size: 13px;
  color: #000000;
  font-family: Poppins, SemiBold;
}

.popup_cookie {
  display: table;
  height: 4.75em;
  padding: 0 1.8em;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.popup_accordian .tab-pane .card {
  background: #eaeff2;
  border-radius: 10px;
  padding-left: 40px;
}

.scroll_popup {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 300px;
  max-height: 350px;
}

.save_sett_btn {
  right: 0;
  position: absolute;
  margin-right: 61px;
}

.more_info {
  border: 1px solid #eee;
  border-radius: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.ppoup_accept {
  border: none;
  background: #2d4156;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.popup_reject {
  border: none;
  background: #eaeff2;
  border-radius: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.show_setting {
  background: none;
  border: none;
  text-decoration: underline;
  color: #ffffff;
}

.data_protect p,
.data_protect li,
.data_protect table {
  font-size: 16px;
  font-family: Poppins, Regular;
  color: #000000;
  font-weight: 400;
}
.data_protect h2 {
  text-align: left;
  font-family: Poppins, medium;
  font-size: 24px;
  letter-spacing: 0px;
  color: #363636;
}

.data_protect table,
.data_protect table th,
.data_protect table td {
  border: 1px solid black;
}

/*===============Blog New Design ============ */
.blog_box {
  background: #ffffff;
  box-shadow: 0px 0px 66px #162d521c;
  border-radius: 5px;
  height: 100%;
}

.calendar {
  color: #f27f3d;
}

.blog-footer-authorName {
  justify-content: space-evenly;
  padding: 0px;
  /* gap: 5px; */
}

.blog-footer-authorName p {
  color: #7e7e7e;
}

.blog-footer-date {
  align-items: center;
  justify-content: space-between;
}

.blog-footer-date p {
  color: #f27f3d;
}

.blog-footer-line {
  border-right: 1px solid #707070;
  height: 23px;
}

.f12 {
  text-align: left;
  font-size: 12px !important;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
  margin: 0px;
}

.Read-more-btn {
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
  background-color: #f27f3d;
  color: white;
  border: none;
  transition: all 0.5s;
  z-index: 1;
  opacity: 1 !important;
  font-size: 12px;
}

.Read-more-btn:hover {
  background-color: #162d52;
}

.bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-content .MuiAvatar-root {
  width: 35px;
  height: 35px;
  font-size: 15px;
}

.blog1-bg {
  background-image: url("../img/blog-grid/blognew1.jpg");
}

.blog2-bg {
  background-image: url("../img/blog-grid/blognew2.jpg");
}

.blog3-bg {
  background-image: url("../img/blog-grid/blognew3.jpg");
}

.blog4-bg {
  background-image: url("../img/blog-grid/blognew4.jpg");
}

.blog5-bg {
  background-image: url("../img/blog-grid/blognew5.jpg");
}

.blog6-bg {
  background-image: url("../img/blog-grid/blognew6.jpg");
}

.blog7-bg {
  background-image: url("../img/blog-grid/blognew7.jpg");
}

.blog8-bg {
  background-image: url("../img/blog-grid/blognew8.jpg");
}

.blog9-bg {
  background-image: url("../img/blog-grid/blognew9.jpg");
}

.blog10-bg {
  background-image: url("../img/blog-grid/blognew10.jpg");
}

.blog11-bg {
  background-image: url("../img/blog-grid/blognew11.jpg");
}

.blog12-bg {
  background-image: url("../img/blog-grid/blognew12.jpg");
}

.blog13-bg {
  background-image: url("../img/blog-grid/blognew13.jpg");
}

.blog14-bg {
  background-image: url("../img/blog-grid/blognew14.jpg");
}

.blog15-bg {
  background-image: url("../img/blog-grid/blognew15.jpg");
}

.blog16-bg {
  background-image: url("../img/blog-grid/blognew16.jpg");
}

.blog17-bg {
  background-image: url("../img/blog-grid/blognew17.jpg");
}

.blog18-bg {
  background-image: url("../img/blog-grid/blognew18.jpg");
}

.blog19-bg {
  background-image: url("../img/blog-grid/blognew19.jpg");
}

.blog20-bg {
  background-image: url("../img/blog-grid/blognew20.jpg");
}

.blog21-bg {
  background-image: url("../img/blog-grid/blognew21.jpg");
}

.blog22-bg {
  background-image: url("../img/blog-grid/blognew22.jpg");
}

.blog23-bg {
  background-image: url("../img/blog-grid/blognew23.jpg");
}

.blog24-bg {
  background-image: url("../img/blog-grid/blognew24.jpg");
}

.blog25-bg {
  background-image: url("../img/blog-grid/blognew25.jpg");
}

.blog26-bg {
  background-image: url("../img/blog-grid/blognew26.jpg");
}

.blog27-bg {
  background-image: url("../img/blog-grid/blognew27.jpg");
}

.blog28-bg {
  background-image: url("../img/blog-grid/blognew28.jpg");
}

.blog29-bg {
  background-image: url("../img/blog-grid/blognew29.jpg");
}

.blog30-bg {
  background-image: url("../img/blog-grid/blognew30.jpg");
}

.blog31-bg {
  background-image: url("../img/blog-grid/blognew31.jpg");
}

.blog32-bg {
  background-image: url("../img/blog-grid/blognew32.jpg");
}

.blog33-bg {
  background-image: url("../img/blog-grid/blognew33.jpg");
}

.blog34-bg {
  background-image: url("../img/blog-grid/blognew34.jpg");
}
.blog35-bg {
  background-image: url("../img/blog-grid/blognew35.jpg");
}
.blog36-bg {
  background-image: url("../img/blog-grid/blognew36.jpg");
}
.blog37-bg {
  background-image: url("../img/blog-grid/blognew37.jpg");
}
.blog38-bg {
  background-image: url("../img/blog-grid/blognew38.jpg");
}
.blog39-bg {
  background-image: url("../img/blog-grid/blognew39.jpg");
}
.blog40-bg {
  background-image: url("../img/blog-grid/blognew40.jpg");
}
.blog41-bg {
  background-image: url("../img/blog-grid/blognew41.jpg");
}
.blog42-bg {
  background-image: url("../img/blog-grid/blognew42.jpg");
}
.blog43-bg {
  background-image: url("../img/blog-grid/blognew43.jpg");
}
.blog44-bg {
  background-image: url("../img/blog-grid/blognew44.jpg");
}
.blog45-bg {
  background-image: url("../img/blog-grid/blognew45.jpg");
}
.blog46-bg {
  background-image: url("../img/blog-grid/blognew46.jpg");
}
.blog47-bg {
  background-image: url("../img/blog-grid/blognew47.jpg");
}
.blog48-bg {
  background-image: url("../img/blog-grid/blognew48.jpg");
}
.blog49-bg {
  background-image: url("../img/blog-grid/blognew49.jpg");
}
.blog50-bg {
  background-image: url("../img/blog-grid/blognew50.jpg");
}
.blog51-bg {
  background-image: url("../img/blog-grid/blognew51.jpg");
}
.blog52-bg {
  background-image: url("../img/blog-grid/blognew52.jpg");
}
.blog53-bg {
  background-image: url("../img/blog-grid/blognew53.jpg");
}
.blog54-bg {
  background-image: url("../img/blog-grid/blognew54.jpg");
}
.blog55-bg {
  background-image: url("../img/blog-grid/blognew55.jpg");
}
.blog56-bg {
  background-image: url("../img/blog-grid/blognew56.jpg");
}
.blog57-bg {
  background-image: url("../img/blog-grid/blognew57.jpg");
}
.blog58-bg {
  background-image: url("../img/blog-grid/blognew58.jpg");
}
.blog59-bg {
  background-image: url("../img/blog-grid/blognew59.jpg");
}
.blog60-bg {
  background-image: url("../img/blog-grid/blognew60.jpg");
}
.blog61-bg {
  background-image: url("../img/blog-grid/blognew61.jpg");
}
.blog62-bg {
  background-image: url("../img/blog-grid/blognew62.png");
}
.blog63-bg {
  background-image: url("../img/blog-grid/blognew63.png");
}
.blog64-bg {
  background-image: url("../img/blog-grid/blognew64.png");
}
.blog65-bg {
  background-image: url("../img/blog-grid/blognew65.png");
}
.blog67-bg {
  background-image: url("../img/blog-grid/blognew67.png");
}
.blog68-bg {
  background-image: url("../img/blog-grid/blognew68.png");
}
.blog69-bg {
  background-image: url("../img/blog-grid/blognew69.png");
}
.blog70-bg {
  background-image: url("../img/blog-grid/blognew70.png");
}
.blog71-bg {
  background-image: url("../img/blog-grid/blognew71.png");
}
.blog72-bg {
  background-image: url("../img/blog-grid/blognew72.png");
}
.blog73-bg {
  background-image: url("../img/blog-grid/blognew73.png");
}
.blog74-bg {
  background-image: url("../img/blog-grid/blognew74.png");
}
.blog75-bg {
  background-image: url("../img/blog-grid/blognew75.png");
}
.blog76-bg {
  background-image: url("../img/blog-grid/blognew76.png");
}
.blog77-bg {
  background-image: url("../img/blog-grid/blognew77.png");
}
.blog78-bg {
  background-image: url("../img/blog-grid/blognew78.png");
}
.blog79-bg {
  background-image: url("../img/blog-grid/blognew79.jpg");
}
.blog80-bg {
  background-image: url("../img/blog-grid/blognew80.png");
}
.blog81-bg {
  background-image: url("../img/blog-grid/blognew81.jpg");
}
.blog_img_bg_radius {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.blog_box_bg {
  background-size: cover;
  height: 220px;
  background-repeat: no-repeat;
  justify-content: end;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.blog_box_bg h2 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
}

.five_min_read_text {
  /* padding-left: 25px;
  padding-top: 10px; */
  font: normal normal medium 12px/18px Poppins !important;
  letter-spacing: 0px;
  color: #db7221 !important;
  opacity: 1;
}

.blog_writer_name {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #7e7e7e !important;
  opacity: 1;
}

.blog_date {
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.blog_box_content p {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins, Regular;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;

  margin-top: 20px;
  margin-right: 20px;
}

.blog_read_more_btn {
  /* border: none;
  background: none; */
  width: 116px !important;
  background-color: #db7221;
  box-shadow: 0px 0px 16px #00000029;
  border: 2px solid #db7221;
  border-radius: 4px;
  font-size: 12px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.blog_read_more_btn a {
  font-size: 12px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 15px;
}

.blog_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #434db840;
  border-radius: 9px;
}

.blog_box h3 {
  font-size: 18px;
  font-weight: 600;

  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #6c6d74;
}

.blog_box p {
  font-size: 15px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
}

.reg_testing_box_para {
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  font-family: "Poppins", Regular;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 15px;
}

.reg_test_blog_img {
  margin-top: -75px;
  width: 100%;
}

.reg_testing_blog_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #434db840;
  border-radius: 9px;
  margin-top: 120px;
}

.reg_testing h3 {
  font-size: 17px;
  font-weight: 600;
  color: #434db8;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
}

.reg_testing p {
  font-size: 15px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 30px;
  font-weight: 500;
}

.reg_testing {
  display: flex;
  justify-content: space-between;
}

.reg_test_blog-bar {
  border: 1px solid #eee;
  height: 65%;
  margin-top: 180px;
}

.reg_testing img {
  width: 185px;
}

.blog_head_para {
  text-align: center;
  font-family: Poppins, Medium;
  font-size: 19px;
  letter-spacing: 0.5px;
  color: #000000;
  opacity: 1;
  margin-top: 15px;
  font-weight: 500;
}

/* .blog_search:-ms-input-placeholder {


} */
.blog_search_box_shadow {
  box-shadow: 0px 0px 66px #162d521c;
}

.blog_search::placeholder {
  text-align: left;
  font-size: 15px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  font-weight: 500;
  color: #6f6f6f;
  width: 100%;
}

.blog_category_border {
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 30px;
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0px 0px 66px #162d5229;
}

.blog_categor_head {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, Normal;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.blog_category_line {
  border: 3px solid #db7221;
  width: 50px;
  border-radius: 5px;
}

.blog_category_text {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  font-family: Poppins, Normal;
  letter-spacing: 0px;
  color: #6f6f6f;
  width: 360px;
  margin-left: -13px;
  cursor: pointer;
}

.blog_category_text:hover {
  color: #db7221;
}

.blog_search_input {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 66px #162d521c;
}

.blog_category_text.active {
  color: #db7221;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  font-family: Poppins, Normal;
  letter-spacing: 0px;
}

.blog_grid_line {
  border-right: 1px solid #707070;
  opacity: 0.31;
  margin-left: -1px;
  margin-top: 60px;
}

.search_width {
  /* width: 100%; */
  background: #ffffff;
  border: none !important;
  border-radius: 5px;
  background-color: transparent;
  height: 40px;
  padding: 6px 15px;
  width: 100%;
  display: block;
  outline: 0;
}

.search_width_placeholder::placeholder {
  text-align: left;
  color: #6f6f6f !important;
  letter-spacing: 0px;
  font-size: 15px !important;
  opacity: 1;
}

.blog_tag_txt {
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 18px;
  opacity: 0.35;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: normal;
  font-size: 15px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #6f6f6f;
  margin-top: 10px;
  display: inline-block;
  position: relative;
}

.news_letter {
  background: #ffffff;
  box-shadow: 0px 0px 66px #162d5229;
  border-radius: 5px;
  padding: 20px;
}

.news_letter h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #000000;
}

.news_letter p {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #434db8;
}

.subscribe_btn {
  background: #db7221;
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-size: 20px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  width: 270px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.newsletter_email_placeholder::placeholder {
  font-size: 15px !important;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #6f6f6f;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.no_blog_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 115px;
  opacity: 1;
  width: 220px;
  height: 230px;
}

.no_blog_text {
  font: normal normal medium 20px/30px Poppins;
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  letter-spacing: 0px;
  color: #707070;
  text-align: center;
  margin-top: 20px;
}

.recent_blog_border {
  box-shadow: 0px 0px 66px #162d5229;
  border-radius: 5px;
  opacity: 1;
}

.recent_blog_img {
  background-position: center;
  background-size: cover;
  width: 140px;
  height: 82px;
  box-shadow: 0px 0px 66px #162d521c;
  border-radius: 5px;
  opacity: 1;
  background-repeat: no-repeat;
}

.d-blog_search {
  background-color: #ffffff;
  margin-bottom: 25px;
  box-shadow: 0px 0px 66px rgba(22, 45, 82, 0.1098039216);
  border-radius: 5px;
  border: 1px solid #7070701f;
  align-items: center;
}

.d-blog_search svg {
  color: #434db8;
  margin-right: 10px;
}

.inner-sidebar {
  padding-left: 20px;
}

.recent_blog_title {
  /* width: 180px;
  height: 67px; */
  /* margin-left: -20px; */
  padding-left: 5px;
  font: normal normal 600 15px/23px Poppins;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.recent_blog_title:hover {
  color: #db7221;
}

.related_blog_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #434db840;
  border-radius: 9px;
  padding: 25px;
  margin-top: 30px;
}

.related_blog_img {
  background-position: center;
  background-size: cover;
  width: 361px;
  height: 219px;
  box-shadow: 0px 0px 66px #162d521c;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  background-repeat: no-repeat;
}

.related_blog_title {
  padding-top: 130px;
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 22px;
  font-weight: 600;
}

.related_blog_content {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  font: normal normal normal 15px/23px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 20px;
  margin-right: 30px;
}

.related_blog_heading {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, Normal;
  letter-spacing: 0px;
  color: #6f6f6f;
  text-align: center;
}

.related_blog_border_line {
  border: 3px solid #db7221;
  width: 50px;
  border-radius: 5px;
  margin-left: 350px;
  margin-bottom: 25px;
}

.blog_search_img {
  width: 125px;
  height: 80px;
  margin-top: 7px;
  margin-left: 7px;
  margin-bottom: 7px;
}

.blog_search_para {
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #484848;
  line-height: normal;
}

.search_back_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 5px;
  margin-left: 30px;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.blog_search_list_box {
  background: #ffffff;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-left: -30px;
  margin-top: 10px;
}

.blog_search_para {
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #484848;
  line-height: normal;
}

.search_back_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 5px;
  margin-left: 0px;
}

.blog_search_list_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-left: -30px;
  margin-top: 10px;
}

.blog_search_bar {
  /* border-right: 1px solid #bdc2f2; */
  height: 100%;
  margin-top: -25px;
  margin-right: 2px;
}

.blog_search_bar_back {
  /* background: #bdc2f2;
  border-radius: 8px;
  width: 3%; */
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 50px;
}

.search_pagination li {
  margin-right: 10px;
  width: 27px;
  height: 27px;
  border: 1px solid;
  border-radius: 50%;
  border-color: #434db8;
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 10.8px;
  color: #434db8;
  text-align: center;
  padding-left: 10px;
  cursor: pointer;
}

.search_pagination li.active {
  /* border-radius: 50%; */
  background: #434db8;
  color: #ffffff;
}

.typed_search_text_color {
  color: #db7221 !important;
}

.search_box_width_inner_page {
  margin-left: -17px;
  margin-right: -10px;
}

.remove_li ol li {
  list-style: none !important;
}

.blogs_breadcrumb .breadcrumb {
  background-color: #ffffff !important;
}

.home_blogs {
  margin-bottom: 6px;
  cursor: pointer !important;
  pointer-events: fill;
}

.blog_calendar_bar {
  border: 1px solid #eee;
  height: 23px;
}

.table_blog_border {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 12px;
}

.blue_td {
  color: #424db7 !important;
}

.table_blog_border td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 12px;
  font-size: 15px;
  font-weight: normal;
  font-family: "Poppins", Regular;
  letter-spacing: 0px;
  color: #000000;
  vertical-align: top;
}

.table_blog_border th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 12px;
  color: #000000;
  letter-spacing: 0px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
}

.text_align_center {
  text-align: center;
}

.align_items_center {
  align-items: center;
}

ul.three_arrow {
  list-style-image: url("../img/blogs/blog-landing/three-arrow-icon.svg");
}

ul.two_arrow {
  list-style-image: url("../img/blogs/blog-landing/two-arrow-icon.svg");
}

ul.one_arrow {
  list-style-image: url("../img/blogs/blog-landing/one-arrow-icon.svg");
}

.blog_arrow_icon {
  padding: 10px;
  text-align: left;
  list-style: none;
  letter-spacing: 0px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
  margin-bottom: 26px;
}

.blogs_points_li {
  /* text-align: left; */
  list-style: none;
  /* font-family: Poppins, Regular;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282828;
  width: fit-content;
  margin-left: 10px; */
}

/*===============Blog New Design End============ */
/*=============Assests page new deisgn start========== */
.most_used_assets {
  background: #f7fbff;
}

.asset_head_two {
  letter-spacing: 0px;
  color: #434db8;
  font-size: 24px;
  font-weight: bold;
  font-family: Poppins, SemiBold;
  margin-left: 55px;
  padding-top: 50px;
}

.asset_head_line {
  border: 2px solid #db7221;
  width: 40px;
  border-radius: 5px;
}

.asset_head_des {
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #3c3838;
  margin-left: 55px;
}

.used_asset_box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
}

.most_used_asset_box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
  width: 200px;
  height: 150px;
}

.asset_type {
  background: #ffffff;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-top: -33px;
}

.asset_type img {
  margin: 16px;
}

.checked {
  color: orange;
}

.star_rating {
  margin-bottom: 15px;
}

.asset_des {
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  line-height: normal;
}

.asset_type_head {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #3c3838;
  text-align: center;
}

.items_type_asset {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000036;
  border: 1px solid #ffffff;
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 5px;
  font-size: 16px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #db7221;
  cursor: pointer;
}

.item_type {
  display: flex;
  justify-content: space-between;
}

.items_type_asset_active {
  background: #db7221;
  box-shadow: 0px 0px 20px #00000036;
  border: 1px solid #db7221;
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 5px;
  font-size: 16px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}

.asset_item_sold {
  background: url("../img/assets/assest-sold.png");
  background-position: center;
  background-size: cover;
  height: 280px;
}

.asset_search {
  background: #ffffff;
  border-radius: 10px;
  width: 70%;
  margin: auto;
  height: 57px;
}

.asset_search_btn button {
  border: none;
  background: #db7221;
  border-radius: 0px 10px 10px 0px;
  margin-left: -41px;
}

.asset_search_icon {
  font-size: 27px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 13px;
}

.nlp_search_btn {
  font-size: 27px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 18px;
}

.drod_bar {
  border: 1px solid #e1e1e1;
}

.back_slide {
  margin-left: -20px;
  margin-top: -210px;
  position: absolute;
  cursor: pointer;
}

.forward_slide {
  margin-right: -20px;
  margin-top: -210px;
  cursor: pointer;
  position: relative;
}

.view_all_txt {
  text-decoration: underline;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
}

.assest_type_search_icon {
  color: #db7221;
}

.assest_type_search_txt {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #9d9b9b;
  opacity: 1;
}

.asset_list_line_grey {
  border-bottom: 1px solid #eee;
}

.excel {
  background: #fdfdfd;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  height: 100%;
}

.excel_detail {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000050;
  border-radius: 50%;
  width: 113px;
  height: 113px;
}

.excel_detail img {
  width: 57px;
  height: 57px;
  margin: 29px;
}

.extract_box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.asset_detail_head {
  font-size: 30px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #3c3838;
}

.number_of_download {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #3c3838;
}

.number_of_download_txt {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #3c3838;
}

.asset_rating {
  color: #f8b545;
}

.asset_detail_para {
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #000000;
  line-height: normal;
}

.benefit_head {
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
}

.benefit_txt {
  font-weight: normal;
  font-size: 12px;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #3c3838;
}

.benefit_star {
  color: #004086;
}

.extract_head {
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
}

.extract_box h3 {
  letter-spacing: 0px;
  color: #3c3838;
}

.extract_box p {
  letter-spacing: 0px;
  color: #3c3838;
}

.details_btn {
  border: 1px solid #db7221;
  border-radius: 14px;
  font-size: 12px;
  font-family: "Poppins", Regular;
  color: #db7221;
  font-weight: 400;
  background: none;
  padding-left: 12px;
  padding-right: 12px;
}

.explore_more {
  background: #db7221;
  box-shadow: 0px 3px 14px #00000057;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.asset_info {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 5px;
}

.asset_info_text {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #3c3838;
  font-family: Poppins, Medium;
}

.asset_info_para {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3c3838;
  font-family: Poppins, Regular;
  font-weight: normal;
}

.asset_des_table th {
  padding: 0px;
  border: none;
  background: none;
}

.asset_des_table td {
  padding: 0px;
  border: none;
  background: none;
}

.asset_des_box {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 10px;
  height: 100%;
}

.asset_des_benefit {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #707070;
}

.asset_input {
  background: #ffffff;
  border: 1px solid #db7221;
  border-radius: 10px;
  height: 100%;
}

.asset_input_head {
  background: #db7221;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1px;
}

.asset_input_head h3 {
  font-size: 12px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.asset_input p {
  font-size: 12px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  font-weight: 500;
  color: #3c3838;
  line-height: normal;
}

.supported_video {
  background: url("../img//assets/youtube.svg");
  background-position: center;
  background-size: cover;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support_video_link {
  font-size: 9px;
  font-family: Poppins, Medium;
  font-weight: 500;
  letter-spacing: 0px;
  color: #434db8;
}

.assets_inner_banner {
  background: url("../img/assets/assets-banner.jpg");
  background-position: center;
  background-size: cover;
  height: 350px;
}

.when_to_use {
  font-size: 29px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
}

.how_to_use_step {
  background: #db7221;
  border: 1px solid #db7221;
  border-radius: 5px;
}

.how_to_use_step h3 {
  font-size: 17px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 7px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  padding-bottom: 1px;
  text-transform: capitalize;
}

.how_to_use_para {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #3c3838;
}

.how_to_use_head {
  font-size: 20px;
  font-family: Poppins, Medium;
  letter-spacing: 0px;
  color: #ffffff;
}

.how_to_use_head_back {
  background: #db7221;
}

.how_to_use_box {
  background: #ffffff;
  border: 1px dashed #434db8;
  border-radius: 5px;
  height: 100%;
}

.arrow_img {
  width: 48px;
  margin-left: -34px;
}

.back_slide i {
  font-size: 15px;
  color: #db7221;
  -webkit-text-stroke-width: 2px;
}

.back_slide button {
  background: none;
  border: 3px solid #db7221;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.nlp_search_box {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 10px 0px 0px 10px;
}

.elements_head_box {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 10px 10px 0px 0px;
}

.elements_table_content {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}

.elements_table table thead th {
  border: none;
}

.elements_table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Style the table cells */
.elements_table td,
th {
  width: 300px;
}

/* Make the table body scrollable */
.elements_table tbody {
  height: 200px;
  overflow-y: auto;
  display: block;
  width: 100%;
  /* Ensure the tbody takes up the full width */
}

/* Hide the table header when scrolling */
.elements_table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Style for the fixed header cells */
.elements_table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

#demo-simple-select {
  border: none;
  box-shadow: none;
}

/*=============Assests page new deisgn end========== */
.scroll_container::-webkit-scrollbar {
  display: none;
}

.scroll_container {
  /* background: blue; */
  /* height: 350px; */
  height: 630px;
  overflow-y: scroll;
  margin-top: 30px;
}

.already-account {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.already-account > p {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #323232;
}

.already-account > a {
  font-size: 12px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0.22px;
  color: #434db8;
  margin-bottom: 14px;
}

.password_icon > svg {
  height: 10px;
  width: 11px;
}

/*=============Testimonials start========== */
.testimonials {
  /* background: #3e3f59; */
  /* background-image: url("../img/home/happy-clients/Testimonial/testimonilaBg.svg"); */
  opacity: 1;
  /* width: 200px;
    height: 200px; */
  background: linear-gradient(
    to bottom right,
    #3e3f59 0%,
    #3e3f59 50%,
    #eecd87 50%,
    #eecd87 100%
  );
}
.testimonialsAuthor {
  padding-left: 37px;
  margin-bottom: 20px;
  gap: 10px;
}
.testimonials_box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  height: 100%;
  margin: 20px;
}

.testimonial_round {
  /* background: #FFFFFF; */
  /* box-shadow: 0px 3px 6px #00000029; */
  /* border-radius: 100%; */
  width: 250px;
  height: 70px;
  position: relative;
  top: -39px;
  border-radius: 41px;
}

.testimonial_logo_round {
  /* background: #FFFFFF; */
  width: 250px;
  height: 70px;
  /* border-radius: 100%;
  border: 1px solid #DEDEDE;
  transform: translate(10%, 10%); */
}

.testimonial_logo_round img {
  position: absolute;
  top: -86%;
  /* width: 100px; */
  left: -24%;
}

.testimonial_logo {
  /* background: #FFFFFF;
  width: 125px;
  height: 125px;
  border-radius: 100%;
  border: 1px solid #DEDEDE;
  transform: translate(10%, 10%); */
  background: #ffffff;
  width: 250px;
  height: 70px;
  border-radius: 41px;
}

.testimonial_logo img {
  /* position: absolute;
  top: 10%;
  width: 100px;
  left: 10%; */
  position: absolute;
  top: -86%;
  left: -24%;
}

.testimonial_txt {
  color: #555555;
}

.testi_info {
  background: #f5e3c4;
  border-radius: 0px 0px 15px 15px;
}

.client_name {
  color: #111111;
}

.testimonial > .slick-list {
  padding-top: 65px;
}

.testimonial_description {
  height: 368px;
  width: 100%;
  overflow: hidden;
  border: none;
  background: #ffffff;
  resize: none;
  font-size: 18px;
  line-height: 23px;
}

.testimonial_readmore_btn {
  height: 28px;
  display: flex;
  justify-content: end;
  margin-right: 3%;
  margin-bottom: 3%;
  margin-top: -10%;
}

.testimonial_readmore_btn > .read_more {
  cursor: pointer;
  color: #434db8;
  font-size: 14px;
  font-family: Poppins, Medium;
}

.testimonial_expand_description {
  height: 368px;
  width: 100%;
  overflow-y: scroll;
  border: none;
  background: #ffffff;
  resize: none;
  font-size: 18px;
  line-height: 23px;
}

.testimonial_close_btn {
  display: flex;
  justify-content: end;
  height: 66px;
}

.testimonial_close_btn > div {
  position: relative;
  top: 70%;
  right: 2%;
}

.testimonial_expand_description::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.testimonial_expand_description::-webkit-scrollbar-thumb {
  background: #aeb0b2;
  cursor: pointer;
  border-radius: 100px;
}

.testimonial_expand_description::-webkit-scrollbar-track {
  background: #dedede;
}

/*=============Testimonials end========== */

.signin_error_msg_alignment > .email {
  margin-left: 2px;
}

.signin_error_msg_alignment_password {
  margin-left: -14px;
  margin-top: 3px;
  position: absolute;
}

.create_account_hyperlink {
  height: 25px;
}

.forgot_error_msg {
  margin-left: 3px;
  margin-top: -5px;
}

#recaptch_error {
  color: #c50303;
  font-size: 8px;
}

.user_sussess_txt {
  text-align: center;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  font-size: 17px;
  letter-spacing: 0.47px;
  color: #79b62f;
  opacity: 1;
}

#menu-jobTitle > div > ul > li {
  font-size: 14px !important;
  font-family: Poppins, Regular !important;
}

.dropdown_placeholder {
  font-size: 12px;
  font-weight: normal;
  font-family: Poppins, Regular;
  letter-spacing: 0px;
  color: #c4c3c3;
}

.password_error_msg {
  margin-left: -13px;
  display: flex;
  justify-content: space-between;
}

.info_icon {
  width: 6px;
}

.email-erros_msg {
  display: flex;
  justify-content: space-between;
}

.secondary_phone_number_validation {
  width: 71%;
  margin-top: -3px;
}

.signin_error_msg {
  margin-top: -13px;
}

.MuiSelect-select {
  height: 18px !important;
}

.no_wrap {
  flex-basis: initial;
}

.nodatafoun-container {
  display: flex;
  justify-content: center;
}

.NoReleventdata_text {
  text-align: center;
  font-size: 20px;
  font-family: Poppins, SemiBold;
  color: #000000;
  font-weight: 500;
  margin-top: 3%;
}

/* -------------------------------------------------------------------------  /* ------------------------------------------------------------------------- */
*/
/* New Home Landing page CSS */


/* Footer Area */
.foot_support_call,
.foot_support_email {
  align-items: center;
}

/* ------------------------------------------------------------------------- */

/* Clients and brands Section */
/* .happy-clients {
  background-image: url("../img/home/happy-clients/happy-clients-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat, no-repeat;
} */
/* .clients_background {
  background: #ffffff;
  box-shadow: 0px 0px 36px #434db829;
  border-radius: 17px;
} */

.clients_title {
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.clients_second_title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.border_line {
  padding-top: 40px;
}

/* .client-scroll-content {
  display: flex;
  animation: scroll 100s linear infinite;
  transform: translateX(-100%);
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
} */

/* .client-scroll-content > div {
  margin-right: 20px;
} */

/* .client-scroll-container {
  display: flex;
  overflow: hidden;
}

.client-scroll-content {
  display: flex;
  animation: scroll 100s linear infinite;
  transform: translateX(0%);
}

@keyframes scroll {
  0% {
    transform: translateX(0%); 
  }

  100% {
    transform: translateX(-100%); 
  }
}

.client-scroll-content > div {
  margin-right: 20px; 
} */

.client-scroll-container {
  overflow: hidden;
}

.client-scroll-content {
  display: flex;
  animation: scrollAnimation 2500s linear infinite;
}

.logo-wrapper {
  margin-right: 20px;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marq {
  padding-top: 30px;
  padding-bottom: 30px;
}

.logos_img_padding {
  padding-left: 20px;
  padding-right: 20px;
}

.design_arrow {
  background: url("../img/home/happy-clients/designed-arrow.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ------------------------------------------------------------------------- */

/* Main Banner Section */

.home h1 {
  height: 25%;
  text-align: left;
  letter-spacing: 0px;
  line-height: normal;
  color: #434db8;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 600;
}

.home p {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: "Poppins", Regular;
  letter-spacing: 0px;
  color: #000000;
  line-height: normal;
}

.get_demo,
.context_read_more {
  background: #db7221;
  box-shadow: 0px 0px 24px #db722191;
  border: 2px solid #db7221;
  border-radius: 7px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 22px;
  padding-right: 17px;
}

.disabledBtn {
  opacity: 0.5;
}

.get_demo_nav_btn {
  background: #db7221;
  box-shadow: 0px 3px 24px #db722191;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  border: 2px solid #db7221;
  color: #ffffff;
  /* padding-left: 22px;
  padding-right: 17px; */
}
.get_demo_nav_btn:hover {
  background: #434db8;
  box-shadow: 0px 0px 16px #00000040;
  border: 2px solid #434db8;
}
.sign_in {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  border: 1px solid #434db8;
  border-radius: 4px;
  padding: 7px 12px 7px 12px;
}
.sign_in:hover {
  border: 1px solid #434db8;
  background: #434db8;
}
.sign_in_orange {
  color: #db7221;
  border: 1px solid #db7221;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  border-radius: 4px;
  padding: 7px 12px 7px 12px;
}
.sign_in_orange:hover {
  border: 1px solid #434db8;
  background: #434db8;
}
/* .carousel_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.carousel_bg {
  background-size: contain !important;
  height: 322px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding: 45px;
}

.carousel_bg h2 {
  /* text-align: left; */
  font-size: 21px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #ff8f00;
  opacity: 1;
  padding-left: 22px;
  width: fit-content;

  /* padding-top: 100px;
  padding-left: 60px; */
}

.carousel_bg li {
  /* text-align: left; */
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0.46px;
  color: #ffffff;
  opacity: 1;
  /* padding-left: 5px; */
}

.carousel_content {
  text-align: left;
  justify-content: start;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.carousel_content .carouselTitle {
  height: 70px;
}
.ai_automation_banner {
  background: url("../img/home/main-banner/ai-automation.png");
}
.carousel_use_nlp_banner {
  background: url("../img/home/main-banner/use-nlps.png");
}

.carousel_plug_and_play_banner {
  background: url("../img/home/main-banner/plug-and-play.png");
}

.carousel_dedicated_section_banner {
  background: url("../img/home/main-banner/dedicated-section.png");
}

.carousel_enhanced_test_automation_banner {
  background: url("../img/home/main-banner/enhanced-test-automation.png");
}

.carousel_enhanced_unified_test_management_banner {
  background: url("../img/home/main-banner/unified-test-management.png");
}

.carousel_ai_generated_banner {
  background: url("../img/home/main-banner/ai-generated.png");
}

.carousel_auto_healing_banner {
  background: url("../img/home/main-banner/auto-healing.png");
}

.carousel_automate_chain_banner {
  background: url("../img/home/main-banner/automate-chain.png");
}

ul.tick_icon {
  list-style-image: url("../img/home/main-banner/tick-icon.svg");
  /* margin-left: 40px; */
}

/* .custom-arrow .slick-prev {
  display:block;
  background: url("../img/home/main-banner/slider-left-arrow.svg");
  margin-left: 50px;
  z-index: index 1;
} */

.custom-arrow .slick-prev:before {
  content: "";
}

.custom-arrow .slick-prev {
  left: 220px;
  top: 344px;
  z-index: 1;
}

.custom-arrow .slick-next:before {
  content: "";
}

.custom-arrow .slick-next {
  right: 301px;
  top: 344px;
  z-index: 1;
}

.custom-arrow .slick-slider {
  left: 15px;
}

/* testimonial Section */

.textimonial_box {
  background: #ffffff;
  box-shadow: 0px 0px 10px #5a65d636;
  border-radius: 32px;
  opacity: 1;
  margin: 10px 15px 45px 15px;
  padding: 5px 10px 5px 36px;
  height: 475px;
}

.textimonial_box p {
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  font-family: Poppins, Medium Italic;
  letter-spacing: 0px;
  color: #323232;
  height: 221px;
}

.testimonial_message_content {
  height: 221px;
  width: 100%;
  overflow: hidden;
  border: none;
  background: #ffffff;
  resize: none;
  font-size: 17px;
  /* line-height: 23px; */
  text-align: left;
  font-size: 17px;
  font-weight: 400;
  font-family: "Poppins", Medium Italic;
  letter-spacing: 0px;
  color: #323232;
}

.textimonial_box h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", SemiBold Italic;
  letter-spacing: 0px;
  color: #434db8;
}

.textimonial_box h4 {
  font-size: 17px;
  /* font-weight: 600; */
  font-family: "Poppins", Medium Italic;
  /* font: italic normal medium 17px/23px Poppins; */
  letter-spacing: 0px;
  color: #434db8;
}

.testimonial_title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  opacity: 1;
}

.testimonial_second_title {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.SliderBanner .slick-dots li.slick-active button::before {
  width: 20px;
  height: 10px;
  background-color: #f57d20;
  border-radius: 10px;
  color: #f57d20;
  opacity: 1.75;
}
.SliderBanner .slick-dots li button:before {
  line-height: 13px;
}

.SliderBanner .slick-dots li {
  width: 7px;
}

.SliderBanner .slick-dots {
  bottom: -35px;
  margin-left: 40px;
}

.arrowCustomChanges .slick-prev {
  z-index: 1;
  display: flex;
  border-radius: 20px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  left: 555px;
  top: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.arrowCustomChanges .slick-next {
  display: flex;
  border-radius: 20px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  right: 606px;
  top: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.arrowCustomChanges .slick-prev:before,
.arrowCustomChanges .slick-next:before {
  content: "";
}
.arrowCustomChanges .slick-dots {
  width: 7%;
  right: 510px;
  bottom: -15px;
}

.testimonial_logo_width {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding-top: 50px;
}

.testimonial_read_more {
  cursor: pointer;
  color: #434db8;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold Italic;
  height: 30px;
  opacity: 1;
}

.testimonial_read_more_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 66px #5a65d636;
  border-radius: 32px;
  opacity: 1;
}

.testimonial_read_more_box h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", SemiBold Italic;
  letter-spacing: 0px;
  color: #434db8;
}

.testimonial_read_more_box h4 {
  font-size: 17px;
  /* font-weight: 600; */
  font-family: "Poppins", Medium Italic;
  /* font: italic normal medium 17px/23px Poppins; */
  letter-spacing: 0px;
  color: #434db8;
}

.testimonial_read_more_content {
  text-align: left;
  font-size: 15px;
  font-family: Poppins, Medium Italic;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  height: 250px;
  width: 100%;
  border: none;
  background-color: #ffffff;
  padding-top: 20px;
  padding-left: 42px;
  overflow-y: scroll;
}

.testimonial_read_more_logo {
  padding-left: 32px;
  padding-bottom: 15px;
}

.testimonial_cancel_icon {
  display: flex;
  justify-content: end;
  padding: 15px;
  cursor: pointer;
}

.text_area_scroll::-webkit-scrollbar {
  display: none;
}

/* .arrow_design_box_shadow {
  background: url("../img/home/customer-testimonials/arrow-design.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

/* .testimonial_close_btn > div {
  position: relative;
  top: 70%;
  right: 2%;
} */
/* .testimonial_logo_width img {
  width: 50%;
} */

/* begin your journey Section */

.begin_your_journey_bg_banner,
.training_bg_banner {
  background: url("../img/home/begin-your-journey/begin-your-journey-bg-banner.svg");
  background-position: center;
  background-size: cover;
  height: 183px;
  box-shadow: 0px 0px 66px #5a65d636;
  border-radius: 32px;
  opacity: 2;
}

.headLineDiv {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.orangeHeadLine {
  border: 3px solid #db7221;
  border-radius: 5px;
  opacity: 1;
  width: 50px;
  margin-bottom: 9px;
}

.begin_your_journey_bg_banner h3,
.training_bg_banner h3 {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", Bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* padding-top: 30px; */
}

/* Empower your testing Section */

.empower_your_testing_title {
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.video_position {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.video_width {
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 66px #5a65d636;
  border-radius: 10px;
  opacity: 1;
  width: 70%;
}

/* video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
  display: none;
} */

/* Fireflink 360 offering Section */

.orange_dots_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.orange_dots,
.orange_dots_Acc {
  width: 8px;
  height: 8px;
  margin-top: -5px;
  border-radius: 50%;
  background-color: #db7221;
}
.orange_dots_Acc {
  display: inline-block;
  margin-bottom: 5px;
}

.fireflink_offering_title {
  text-align: center;
  font-size: 27px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.offering_title_color {
  color: #db7221 !important;
}

.fireflink_offering_second_title {
  margin: 0px 40px 0px 40px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", SemiBold;
  letter-spacing: 0px;
  color: #323232;
}
.ff_offering_title {
  font-weight: bold;
  font-size: 12px;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #434db8;
  text-transform: uppercase;
  text-align: center;
}

.ff_offering_title_hover {
  font-weight: bold;
  font-size: 12px;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #db7221;
  text-transform: uppercase;
  text-align: center;
}
/* .audit {
  top: 90px;
  position: absolute;
  left: 420px;
  text-align: center;
  padding: 10px 5px;
  transition: all 0.3s linear;
}

.train {
  position: absolute;
  top: -20px;
  left: 235px;
  padding: 6px;
  transition: all 0.3s linear;
} */
.activeOffering h3 {
  text-decoration: underline;
}

/* .trational {
  position: absolute;
  top: 90px;
  left: 40px;
  text-align: center;
  padding: 14px;
}

.automation {
  margin-top: -15px;
  text-align: center;
  position: absolute;
  left: 325px;
  padding: 13px;
} */

/* .express {
  margin-right: 295px;
  margin-top: -20px;
  text-align: center;
  position: relative;
  padding: 10px;
} */

.audit_hover {
  width: 373px;
  height: 372px;
  background: url("../img/home/fireflink-offering/background-box.svg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0px 0px 36px #434db829;
}
.traditional_hover {
  width: 373px;
  height: 372px;
  background: url("../img/home/fireflink-offering/background-box.svg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0px 0px 36px #434db829;
}
.train_hover {
  width: 373px;
  height: 372px;
  background: url("../img/home/fireflink-offering/background-box.svg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0px 0px 36px #434db829;
}
.express_hover {
  width: 373px;
  height: 372px;
  background: url("../img/home/fireflink-offering/background-box.svg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0px 0px 36px #434db829;
}
.automation_hover {
  width: 373px;
  height: 372px;
  background: url("../img/home/fireflink-offering/background-box.svg");
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0px 0px 36px #434db829;
}

/* for title inside the hover popup */
.offering_hover_head {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #db7221;
  text-transform: capitalize;
  text-align: center;
  padding-top: 15px;
}

/* for features inside the hover popup */
.offering_hover_feat {
  line-height: 1.5;
  margin-bottom: 0px;
  padding-left: 32px;
}

.offering_hover_feat li {
  text-align: start;
  font-size: 11px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  letter-spacing: 0px;
  color: #434db8;
}
/* .know_more_hover {
  background: #db7221;
  box-shadow: 0px 0px 24px #00000029;
  border-radius: 7px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
} */

.know_more_hover_btn {
  background: #db7221;
  box-shadow: 0px 0px 24px #00000029;
  border: 2px solid #db7221;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.audit_banner_img {
  height: 180px;
  width: 340px;
}

.e2e_automation_banner_img {
  height: 180px;
  width: 330px;
}

.express_banner_img {
  height: 195px;
  width: 100%;
}

.traditional_tool_banner_img {
  height: 210px;
  width: 250px;
}

.offering_arrow_background {
  /* background: url("../img/home/fireflink-offering/offering-arrow-background.svg");
  background-repeat: no-repeat; */
}

ul.tickhovericon {
  list-style-image: url("../img/home/fireflink-offering/tick-icon.svg");
}

/* FireFlink Acc */
.home_acc_res {
  display: none;
}
.displayProps,
.displayPropsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainFireFlinkAcc_bg {
  background: url("../img/home/fireFlinkAcc/fireFlinkAccbg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding-right: 0px;
}
.mainFireFlinkAcc_bg .mainDiv {
  padding-right: 0px;
}
.content_acc h2 {
  text-align: left;
  font-size: 27px;
  font-weight: 600;
  font-family: Poppins, SemiBold;
  color: #ffffff;
  margin: 0px;
}

.accordinDiv .accordion .accordion-item .accordianNavBtn button span {
  color: #ffffff;
  font: normal normal 600 20px/43px Poppins;
}

.accordinDiv .accordion .accordion-item .accordianNavBtn button {
  background-color: #6e7af6;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.accActive button {
  border-radius: 6px 6px 0px 0px;
}
.accUnActive button {
  border-radius: 6px;
}
.accordinDiv .accordion .accordion-item .accordianNavBtn button .accFirstDiv {
  display: flex;
  gap: 20px;
}

.content_acc {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 45px 10px;
}

.secColumn {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.secColumn img {
  width: 90%;
}
.secColumn .secContent h2 {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  font-family: Poppins;
  font-size: 18px;
  line-height: normal;
}
.secContent {
  padding: 15px 60px 0px 27px;
}
.accordinDiv .accordion {
  gap: 9px;
  display: flex;
  flex-direction: column;
}
.accordinDiv .accordion .accordion-item .accordion-collapse .accordion-body {
  background-color: #262e85;
  color: white;
  padding: 15px 0px 1px 0px;
}
.accordinDiv .accordion .accordion-item .accordion-collapse .accordion-body p {
  padding: 0px 0px 0px 20px;
  margin-bottom: 0px;
}

.right_arrow {
  list-style-image: url("../img/home/fireFlinkAcc/Icons/iconRight.svg");
}

.integration {
  margin-left: 46px;
}
.integration .imgDiv {
  display: flex;
  justify-content: flex-end;
}
.integration .imgDiv img {
  width: 75%;
}
.accHieghtMangement {
  height: 190px;
}
/* .videoMargin1 {
  margin-top: 120px;
}
.videoMargin2 {
  margin-top: 145px;
} */
.videoSummary {
  width: 536px;
}
.videoSummary h2 {
  font-size: 18px;
  line-height: normal;
  text-align: left;
  color: #ffffff;
  margin: 15px 0px;
  font-weight: 300;
  font-family: Poppins;
}
.displayPropsFlexColumn {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.displayPropsFlexColumnCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.knowMore {
  background: #db7221;
  box-shadow: 0px 0px 24px #00000029;
  border: 2px solid #db7221;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 22px;
  padding-right: 17px;
}

.Acc_video_position {
  width: 100%;
}
.Acc_video_position .video_width {
  width: 549px !important;
  height: 262px !important;
}
.accordinDiv .accordion .accordion-item .accordianNavBtn {
  margin: 0px !important;
}

.acc-integration-img {
  width: 546px;
  height: 315px;
}
.videoPlayer .videoDiv iframe {
  width: 549px !important;
  height: 262px !important;
  border-radius: 5px;
}
.testimonial_read_more_box .testimonialContent {
  overflow-y: auto;
  height: 232px;
  padding: 0px 47px 0px 37px;
}

.testimonial_read_more_box .testimonialContent p {
  color: #323232;
  font-family: Poppins, Medium Italic;
  font-size: 15px;
  font-weight: 400;
}

/* documentation css by Nalina*/

.mt-3 .azure {
  margin-top: -0.5rem !important;
}

.github_img {
  margin-left: 7rem;
  width: 80%;
}
.azure_img {
  width: 80%;
}
.bitbucket_img {
  width: 80%;
  height: auto;
}
.azure_imgstep2 {
  width: 85% !important;
}
.azure_imgDev1 {
  width: 85% !important;
}
.azure_imgDev2 {
  width: 90% !important;
}
.azure_imgDev3 {
  width: 85% !important;
}
.azure_imgEdit {
  width: 85% !important;
}
/*=======Safari Page Start=============*/
.safari_browser_box {
  border: 1px solid #e2e2e2;
  border-radius: 9px;
  width: 100%;
  margin-bottom: 30px;
}
.safari_border_bottom {
  border-bottom: 1px solid #e2e2e2;
}
/*=======Safari Page End=============*/

/* Home page floating Popup  Start*/
.floatingDemo {
  /* width: 50px;
  height: 50px; */
  /*  */
  /* border: 1px solid red; */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
}

.floatingContent {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.floatingContent .closeIcon {
  background: none;
  border: none;
  padding: 0px;
}

.floatingButton {
  animation: 0.5s ease-in-out floatingTrans;
  cursor: pointer;
  padding: 8px 13px;
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
  font-family: Poppins;
  background: #db7221;
  box-shadow: 0px 0px 16px #db7221;
  border-radius: 4px;
  border: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.floatingButton:hover {
  background: transparent linear-gradient(180deg, #434db8 0%, #000966 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #434db894;
  color: #ffffff;
}
@keyframes floatingTrans {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* Home page floating Popup End*/

/* Blogs Demo banner background Start*/
.demo_bg1 {
  background-image: url("../img/blogs/blog-demo-bg/demo_bg1.svg");
}
.demo_bg2 {
  background-image: url("../img/blogs/blog-demo-bg/demo_bg2.svg");
}
.demo_bg3 {
  background-image: url("../img/blogs/blog-demo-bg/demo_bg3.svg");
}
.demo_bg4 {
  background-image: url("../img/blogs/blog-demo-bg/demo_bg4.svg");
}
.get_demo {
  background: #db7221;
  box-shadow: 0px 0px 24px #db722191;
  border: 2px solid #db7221;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 7px;
}
.get_demo:hover {
  background: #434db8;
  box-shadow: 0px 0px 16px #00000040;
  border: 2px solid #434db8;
}
.demo_bg {
  width: 722px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
}
.demo_bg h1 {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
}
.demo_bg .blue {
  color: #434db8;
}
.demo_bg .white {
  color: #fff;
}

/* Blogs Demo banner background End*/

.offeringMainDiv {
  background-image: url("../img/home/fireflink-offering/offering.svg");
  height: 243px;
  width: 243px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.training,
.audit,
.traditional,
.e2e,
.express,
.cursorPointer {
  cursor: pointer;
}
.training h3,
.audit h3,
.traditional h3,
.e2e h3,
.express h3 {
  font-family: Poppins;
  font-size: 12px;
  margin-top: 5px;
  font-weight: bold;
  font-family: "Poppins";
  color: #434db8;
}
.audit h3,
.traditional h3 {
  width: 105px;
}
.e2e h3,
.express h3 {
  width: 150px;
}
.training {
  position: absolute;
  top: -82px;
  left: 80px;
  text-align: center;
}
.audit {
  position: absolute;
  left: 245px;
  top: 20px;
  text-align: center;
}
.traditional {
  position: absolute;
  top: 20px;
  right: 250px;
  text-align: center;
}
.e2e {
  position: absolute;
  top: 220px;
  right: 155px;
  text-align: center;
}
.express {
  position: absolute;
  top: 215px;
  left: 150px;
  text-align: center;
}
.trainingH3Hover,
.auditH3Hover,
.expressH3Hover,
.e2eH3Hover,
.traditionalH3Hover {
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;
  font-family: Poppins, Bold;
  letter-spacing: 0px;
  color: #db7221 !important;
}
/* FireFlinkAcadamy  */
.academyMainBannerLottie {
  display: flex;
  justify-content: end;
}
.mainBannerLottie {
  width: 90%;
  height: 100%;
}
.fontTitle {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
}
.enhanceSkillsLottie {
  width: 85%;
  height: 85%;
}
.enhance_title,
.enhance_para,
.enhance_list,
.enhance_list.steps span {
  color: #ffffff;
}
.Acadamy_Title,
.accelarating_title,
.accelarating_para,
.WhyAcadamy_title {
  color: #434db8;
}
.accelaratingImgDiv {
  width: 100%;
  height: 100%;
}
.fontPara {
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins;
}
.Acadamy_para {
  color: #000000;
  line-height: 1.5;
}
.enhance_para {
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins;
  width: 460px;
}
.Acadamy_List {
  font-size: 15px;
  font-weight: normal;
  font-family: Poppins;
  color: #000000;
  padding-left: 18px;
}
.acadamyImgBanner {
  width: 100%;
}
.bg_Acadamy {
  background-image: url("../img/acadamy/academyLanding/EnhanceImageBg.svg");
  background-size: cover;
  height: 376px;
  border-radius: 25px;
}
.enhance_list {
  font-size: 15px;
  padding-left: 15px;
}
.skills li span {
  font-weight: 600;
  font-size: 15px;
}
.enhance_list li {
  font-size: 15px;
}
.enhanceAlign {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.accelarating_para,
.accelarating_title {
  margin-bottom: 0px;
  font-weight: 600;
}
.accelarating_list,
.accelarating_list.span li {
  color: #000000;
  font-size: 15px;
}
.accelarating_list li {
  line-height: 1.5;
}
.accelarating_list {
  padding-left: 0px;
}
.whyAcademyAnimation {
  width: 100%;
  height: 100%;
}
.WhyAcadamy_title {
  text-align: center;
}
.whyAcadamyImg {
  position: relative;
  width: 605px;
  height: 496px;
}
.whyAcadamyImg p {
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  width: 219px;
  text-align: center;
  color: #434db8;
  line-height: 1.5;
  margin-bottom: 0px;
}
.whyAcadamyDiv1,
.whyAcadamyDiv2,
.whyAcadamyDiv3,
.whyAcadamyDiv4,
.whyAcadamyDiv5,
.whyAcadamyDiv6 {
  height: 101px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 19px #a3b2ff5e;
}
.whyAcadamyDiv1 {
  position: absolute;
  left: 570px;
  top: 5px;
}
.whyAcadamyDiv2 {
  left: 625px;
  position: absolute;
  top: 160px;
}
.whyAcadamyDiv3 {
  position: absolute;
  left: 565px;
  top: 335px;
}
.whyAcadamyDiv4 {
  position: absolute;
  right: 565px;
  top: 5px;
}
.whyAcadamyDiv5 {
  position: absolute;
  top: 160px;
  right: 620px;
}
.whyAcadamyDiv6 {
  position: absolute;
  top: 335px;
  right: 565px;
}
.contextbg {
  background-image: url("../img/acadamy/academyLanding/contextBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 353px;
  border-radius: 25px;
}
.contextTitle {
  margin-top: 11px;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  color: #ffffff;
}
.contextPara {
  font-weight: normal;
  font-size: 15px;
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: 5px;
  height: 67px;
  line-height: 1.5;
}
.textCenterPadding {
  text-align: center !important;
  padding: 0px 47px;
  position: relative;
}
.whitePartition {
  width: 1px;
  height: 96px;
  background-color: #ffffff;
  position: absolute;
  right: 0px;
}

.context_read_more {
  border: 1px solid #fff;
  font-size: 13px;
}
.emptyDiv {
  height: 45px;
}
.trainingBtn {
  font-size: 22px;
}

.acadamySkillTitle {
  font-size: 27px;
  font-family: Poppins;
  font-weight: 600;
  color: #434db8;
}
.acadamySkillPara {
  font-size: 20px;
  font-weight: normal;
  font-family: Poppins;
  color: #000000;
  width: 496px;
  margin-bottom: 10px;
}
.skillsList {
  padding-left: 15px;
}
.skillsList li {
  font-size: 15px;
  font-family: Poppins;
  font-weight: normal;
  color: #000000;
}

.listDiv {
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
}
.listDiv .orangeLineList {
  height: 34px;
  width: 2px;
  background-color: #db7221;
}
.listTitle {
  margin-top: 15px;
  font-size: 20px;
  color: #434db8;
  font-weight: 600;
}
.listUlList {
  padding-left: 35px;
}
.listUlList li {
  font-family: Poppins;
  font-weight: normal;
  font-size: 13px;
  color: #434db8;
  line-height: 1.8;
  width: 303px;
}
.listCard {
  box-shadow: 0px 0px 16px #a3b2ff3d;
  height: 288px;
  /* width: 330px; */
  padding: 0px;
}
.listTitle {
  padding-left: 20px;
}
.listMainDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px;
}

.acadamyCountDiv {
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  background-image: url("../img/acadamy/academyLanding/countBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 184px;
  /* padding: 35px 50px ; */
}
.countCard {
  /* padding-left: 40px; */
  position: relative;
}
.countCard1,
.countCard2 {
  width: 250px;
}
.countCard3 {
  width: 290px;
}
.lineCount {
  position: absolute;
  right: 0px;
  height: 81px;
  width: 1px;
  top: 20px;
  background-color: #434db896;
}
.countTitle {
  font-size: 60px;
  font-weight: 600;
  color: #db7221;
  font-family: Poppins;
}
.countPara {
  font-family: Poppins;
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
}

ul.tickIconM {
  list-style-image: url("../img/acadamy/academyLanding/tickIconM.svg");
  /* margin-left: 40px; */
}

.traningbtnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo_modal .modal-dialog {
  max-width: 576px !important;
}
.enrollModal {
  margin-top: 50px;
}
.demo_inputs {
  width: 260px;
  border: 1px solid #0000002e;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  height: 42px;
}
/* .demo_inputs::placeholder
{
  margin-left: 15px !important;
} */
.demo_select {
  width: 260px;
}
.country_phone_input_width_demo {
  width: 260px !important;
}
.mt_19 {
  margin-top: 19px;
}
.demo_btn {
  width: 260px;
}
.get_demo_modal {
  background: #db7221;
  box-shadow: 0px 0px 24px #db722191;
  border: 2px solid #db7221;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", Medium;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 22px;
  padding-right: 17px;
  width: 260px;
}
.enrollModal .modal-content {
  border-radius: 10px;
}
.modalHeader_demo {
  padding: 0px;
  border-bottom: none;
}
.modalHeader_demo .modal_title {
  width: 100% !important;
  padding-top: 10px;
}
.modalHeader_demo .modal_title h5 {
  text-align: center !important;
}

.modalHeader_demo button {
  display: none;
}

.modalHeader_demo svg {
  margin: 5px;
  color: #b0b1b7;
  cursor: pointer;
}

.modal_title h5 {
  text-align: center !important;
  color: #434db8;
  font-size: 23px;
  font-family: Poppins;
  font-weight: 600;
  padding: 15px 22px 15px 20px;
  margin: 0px;
}

.widthHeight button {
  width: 260px;
  height: 42px;
}

/* Skilled Trainer Page Start*/
.skilled_trainer {
  background: #d9dcff33;
}
.trainers_carries {
  background: url("../img/acadamy/skilledTrainers/skilled-trainer.svg");
  background-repeat: no-repeat;
  height: 490px;
  width: 470px;
  background-position: center;
  margin-top: 60px;
  margin-bottom: 60px;
  background-size: contain;
}
.evaluation {
  position: absolute;
  left: 255px;
  top: 45px;
}
.identification {
  position: absolute;
  right: 1px;
  top: 110px;
  text-align: right;
  width: 140px;
}
.versatility {
  position: absolute;
  right: -20px;
  top: 230px;
}
.conveyance {
  position: absolute;
  right: -45px;
  top: 350px;
}
.focus {
  position: absolute;
  right: 85px;
  top: 455px;
}
.tailored {
  position: absolute;
  left: 210px;
  top: 550px;
}
.ability {
  position: absolute;
  top: 440px;
  left: -10px;
  width: 143px;
}
.multiple {
  position: absolute;
  top: 350px;
  width: 110px;
  left: -45px;
}
.communication {
  position: absolute;
  top: 230px;
  left: -80px;
}
.time {
  position: absolute;
  top: 115px;
  left: 15px;
  text-align: right;
  width: 118px;
}

.skilledData {
  box-shadow: 0px 0px 16px #a3b2ff3d;
  height: 360px;
  /* width: 330px; */
  padding: 0px;
}

.skilledData h1 {
  padding-left: 20px;
  padding-right: 7px;
  line-height: 1.4;
}
.skilledData p {
  font-family: "Poppins", Regular;
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  padding-left: 20px;
  padding-right: 7px;
}

/* Skilled Trainer Page End*/
/* Training Plan Page Start */
.traningbtnDiv button:hover {
  color: white;
}
.training_duration_box {
  background: #ffffff;
  box-shadow: 0px 0px 16px #a3b2ff3d;
  border-radius: 32px;
  height: 100%;
  padding: 40px;
}
.duration_content {
  display: flex;
  gap: 10px;
  align-items: center;
}
.duration_center_line {
  border-left: 1px solid #eee;
  height: 170px;
  position: absolute;
  left: 50%;
  margin-top: -20px;
  transform: translate(-50%, -50%);
}
.duration_content h4 {
  font-size: 31px;
  font-weight: bold;
  font-family: Poppins, Bold;
  letter-spacing: -0.53px;
  color: #ff9900;
  text-transform: capitalize;
}
.training_duraction_back {
  background: #ffffff;
  box-shadow: 0px 0px 16px #434db83d;
  border: 1px solid #d9dcff;
  border-radius: 30px;
  width: 276px;
  margin: auto;
  margin-top: -65px;
  margin-bottom: 20px;
}
.training_key_points {
  background: #d9dcff33;
}

.email_contactUs {
  color: #fff;
  text-decoration: none;
}
.email_contactUs:hover {
  color: #fff;
}

ul.circle_arrow_right {
  list-style-image: url("../img/acadamy/arrow-circle-right.svg");
}
.full_stack_bar {
  border-left: 1px solid #eee;
  margin-left: -2px;
}
/* -------- */
.text-danger-newsletter {
  font-size: 10px;
  color: red;
  position: absolute;
  top: 32px;
}
.news-letter-input-field {
  height: 55px;
  position: relative;
}
.email_contactUs {
  color: #fff;
  text-decoration: none;
}
.email_contactUs:hover {
  color: #fff;
}
/* webinar banner  */
.line-with-circle,
.line-with-circle-start {
  position: relative;
  width: 60px; /* Adjust width as needed */
  height: 1px; /* Height of the line */
  background-color: #db7221; /* Color of the line */
}

.line-with-circle::after {
  content: "";
  position: absolute;
  width: 5px; /* Diameter of the circle */
  height: 5px; /* Diameter of the circle */
  border: 1px solid #db7221; /* Border to create a hollow circle */
  border-radius: 50%; /* To make it a circle */
  top: -2px; /* Adjust vertical position to center it with the line */
  right: -4px; /* Attach to the end of the line */
}
.line-with-circle-start::before {
  content: "";
  position: absolute;
  width: 5px; /* Diameter of the circle */
  height: 5px; /* Diameter of the circle */
  border: 1px solid #db7221; /* Border to create a hollow circle */
  border-radius: 50%; /* To make it a circle */
  top: -2px; /* Adjust vertical position to center it with the line */
  left: -4px; /* Attach to the start of the line */
}

.webinarBannerCol7 {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.upcomWebinar {
  position: relative;
  display: flex;
  align-items: end;
}
.upcomWebinar .line-with-circle {
  top: -10px;
  left: 10px;
}
.calendarBtn {
  padding: 0px 13px;
  box-shadow: 0px 3px 16px #d9dcff9e;
  border: 1px solid #d9dcff;
  height: 36px;
  border-radius: 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.tqCalendarBtn {
  padding: 15px 50px;
  height: 56px;
  font-size: 18px;
  background-color: #db7221;
  border-radius: 7px;
  border: none;
  color: #fff;
}
.tqCalendarBtn + .dropdown-menu {
  top: 92%;
  left: 415px !important;
}
.tqCalendarBtn + .dropdown-menu a {
  font-size: 15px !important;
}
.calendarList {
  left: 95px;
}

.calendarList a {
  font-size: 12px;
}

.upcomingRegister img {
  box-shadow: 0px 0px 26px #5a65d636;
  border-radius: 20px;
}
.poppins {
  font-family: Poppins;
}
.reg {
  height: 80px;
  position: relative;
}

.regisForm {
  box-shadow: 0px 0px 26px #5a65d636;
  border-radius: 20px;
}

.text-danger-web {
  color: red;
  font-size: 10px;
  position: absolute;
  top: 62px;
}

.termsDiv {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.webinarModal .modal-xl {
  margin-top: 120px !important;
}

.webinarModal .modal-body {
  max-height: calc(100vh - 280px) !important;
}

.webinarBannerNav {
  background-color: #af182c;
  padding: 5px;
}

.webinarBannerNav a {
  color: #ffd500;
  text-decoration: none;
}

.closeBtn {
  background: none;
  border: none;
}

.thankYouBg {
  background-image: url("../img/webinar/TqWebinar.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 90px;
}

.tqTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tqContent {
  box-shadow: 0px 0px 41px #434db826;
  border: 1px solid #da722142;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 34px 72px;
  margin-bottom: 60px;
}

.tqP1 {
  color: #000966;
  font-size: 17px;
  font-family: Poppins;
  font-weight: 500;
}
.pr {
  position: relative;
}
.webLine {
  display: flex;
  position: absolute;
  left: 550px;
  top: 0px;
  z-index: 1;
}

.o_line,
.b_line {
  width: 35px;
  height: 5px;
}
.o_line {
  background-color: #db7221;
  border-radius: 2px;
}
.b_line {
  background-color: #000966;
}

/* Footer copyright and terms of use  */
.footer_bottom_line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  opacity: 0.27;
}
.footeBottomeContent .Tcontent {
  justify-content: space-between;
}
.Tcontent p {
  color: #b8b8b8;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
}

/* partner with fireflink start  */
.partnerbg {
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.partnerContent {
  width: 600px;
  font-family: Arial, Regular;
  background: #ffffff;
  border: 1px solid #d9dcff;
}

.whyPartner {
  background: #434db8;
  border: 2px dashed #db7221;
}

.whyPartner {
  padding: 25px 83px;
}

.diffLine {
  width: 100%;
  border-top: 1px solid #434db8;
}

.partnerBtn {
  padding: 14px 30px;
  background-color: #db7221;
  color: #ffff;
  font-weight: 500;
  font-size: 20px;
  border-radius: 6px;
  font-family: Poppins;
  cursor: pointer;
}

.partnerBtn:hover {
  color: #ffff !important;
}

.sepLine {
  border-top: 1px solid #babffa;
  width: 100%;
}

.sepLine1 {
  border-top: 2px solid #db7221;
  width: 100%;
}

.whyList {
  border: 1px dashed #db7221;
  padding: 15px 12px;
}

.partnerFooter {
  text-align: center;
}

.partnerHyper {
  color: black;
  text-decoration: underline !important;
}

.socialMedia {
  display: flex;
  justify-content: center;
  gap: 10px;
}
/* Paginataion for blog start  */
.pageNum {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000029;
  border-radius: 50px;
  padding: 8px 36px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.pagination .active {
  background-color: #db7221;
  border-radius: 50%;
  /* padding: 3px 14px; */
  color: #fff;
  width: 30px; /* Adjust this value to your preferred size */
  height: 30px; /* Adjust this value to your preferred size */
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button {
  background-color: white;
  color: #000000;
  border: none;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 600;
  width: 30px; /* Ensure all buttons are the same size */
  height: 30px; /* Ensure all buttons are the same size */
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .arrowbtn {
  width: 50px; /* Ensure all buttons are the same size */
  height: 50px; /* Ensure all buttons are the same size */
  border-radius: 50%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Self Learning Video Start */
.self_learning video {
  border-radius: 5px;
}
.self_learning_video {
  background: #eee;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
/* Container for the left menu and right content */

/* Left side menu: scrollable with scrollbar on the left */
.self-learning-left-menu {
  width: 290px;
  height: 100vh;
  overflow-y: auto;
  direction: ltr;
}

/* Scrollbar customization for Chrome, Safari, and Edge */
.self-learning-left-menu::-webkit-scrollbar {
  width: 80px;
  background-color: #e0e0e0;
}

/* Scrollbar handle */
.self-learning-left-menu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 1px solid #555;
}

/* Handle on hover */
.self-learning-left-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scrollbar customization for Firefox */
.self-learning-left-menu {
  scrollbar-width: thin;
  scrollbar-color: #888 #e0e0e0;
}

/* Right side content: fixed */
.self-learning-right-video {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
  height: 100vh;
}
.self-learning-doc-download {
  background: rgba(67, 77, 184, 1);
  border-radius: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #ffffff;
  float: right;
  padding: 2px 15px 2px 15px;
}
.self-learning-doc-download:hover {
  color: #ffffff;
}

/* Self Learning Video End*/
/* AI Automation Testing Start */
.ai_automation {
  background: url("../img/ai-automation-testing/ai-wave.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}
.ai_automation .ai_img img {
  width: 100px;
}
.ai_feat_box {
  margin: 0 auto;
  box-shadow: 0px 0px 24px -6px #00000040;
  width: 80px;
  height: 80px;
  border-radius: 16px;
  background: #ffffff;
  padding: 16px;
}
.ai_automation_features ul li {
  list-style-image: url("../img//ai-automation-testing/star.png");
}
.ai_automation_features p {
  font-family: Poppins;
  font-style: italic;
}
.ai_automation_features button {
  background: #ffffff;
  border: 1px solid rgba(204, 51, 255, 1);
  border-radius: 24px;
  padding: 5px 12px 5px 18px;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(
    90deg,
    rgba(204, 51, 255, 1),
    rgba(67, 77, 184, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ai_expand_content {
  background: rgba(194, 199, 255, 0.12);
  padding: 40px;
  border-radius: 24px;
}
.self-healing-video {
  outline: none;
  pointer-events: none; /* Prevents any interaction or hints from showing up */
}
.ai_img_menu {
  width: 26px;
}
/* AI Automation Testing End */
/* FireFlink Cloud Page New Design Start */
.cloud .row {
  scroll-margin-top: 100px;
}
.cloud-feature {
  background: #f7f9fc;
}
.tailored-cloud {
  text-align: center;
  padding-bottom: 140px;

  h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    padding-top: 50px;
  }
}
.tailored-plan-box {
  box-shadow: 0px 0px 24px -12px #00000040;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 60px;
  h3 {
    color: #0a2540;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 40px;
  }
  img {
    position: relative;
    top: 10px;
  }
}
.value-addition-box {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  margin-top: 50px;

  ul li {
    position: relative;
    padding-left: 25px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 15px;
    color: #848f9c;
    list-style: none;
  }

  ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url("../img/cloud/cloud-tick.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.cloud_feat_box {
  background: #ffffff;
  border-radius: 24px;
  padding: 25px;
  height: 416px;
  h3 {
    line-height: 48px;
    margin: 20px 90px 0px 0px;
  }
  p {
    line-height: 24px;
    margin: 20px 60px 0px 0px;
  }
}
.cloud_secondary_text {
  color: #848f9c;
}
/* FireFlink Cloud Page New Design End */
